import moment from "moment"
import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Image, ImageStyle, StyleSheet } from "react-native"
import { spacing, color, styles } from "../../../../theme"
import { ViewCartToastProps } from "./view-cart-toast.props"

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ViewCartToast: Component<ViewCartToastProps> = props => {
    const {
      count,
      amount,
      date,
      onButtonPress,
    } = props
  
  
    return (
      <TouchableOpacity onPress={() => onButtonPress()} style={toastContainerStyles.container}>
        <View style={{flexDirection:'column'}}>
          <Text style={toastContainerStyles.text}>{count} Item | Total Amount of ₹ {amount}</Text>
          <Text style={toastContainerStyles.highlightText}>Booking Date: {moment(date).format('MMM DD, YYYY')}</Text>
        </View>
        <Text style={{...toastContainerStyles.text, flexGrow:1, textAlign:'right'}}>Proceed  </Text>
      </TouchableOpacity>
      
    )
  }

  const toastContainerStyles = {
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 20,
        backgroundColor: color.black,
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
    } as ViewStyle ,
    text: {
        color: color.white,
    } as TextStyle,
    highlightText: {
      color: color.ananthamOrange,
      paddingVertical: 1
    } as TextStyle
  }