import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, Platform, Modal, ViewStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Text, Button } from "../../components"
import { TouchableOpacity, ScrollView } from "react-native-gesture-handler";
import { useStores } from "../../models/root-store/root-store-context";
import { ApiUser } from "../../services/api/api-methods/api-user"
import { color } from "../../theme";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import { MapPicker } from "../../components/map-picker/map-picker";
import { useNavigation } from "@react-navigation/native";
import * as Location from 'expo-location';


export const AddressMapScreen: Component = observer(function AddAddressScreen() {

    const navigation = useNavigation()

    const { addressStore, cartStore, userStore } = useStores()
    const [mapPicker, showMapPicker] = useState(false)
    const [locationModalVisible, setLocationModalVisible] = useState(false);


    const navAddAddress = () => navigation.navigate("logged_in_path", { screen: "address" })


    useEffect(() => {
        getUserAddress()
    }, [])


    const getUserAddress = () => {
        const api = new ApiUser()
        api.getAllAddress().then(res => {
            if (res['kind'] == "ok") {
                addressStore.create(res['data'])
            }
        })
    }

    const getIcon = (addressType) => {
        if (addressType == "Home") {
            return "ios-home"
        } else if (addressType == "Work") {
            return "ios-briefcase"
        } else if (addressType == "RentalHome") {
            return "ios-pin"
        } else {
            return "ios-pin"
        }
    }

    const setDeliveryLocation = (address) => {
        cartStore.updateAddressId(address.addressId)
        addressStore.updateDeliveryAddress(address)
        navigation.navigate("home")
    }


    const generateFullAddress = (addres) => {
        var completeAddresses = addres.address1 || '';

        // if (addres.address2) {
        //     completeAddresses += (completeAddresses ? ', ' : '') + addres.address2;
        // }

        if (addres.landmark) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.landmark;
        }

        if (addres.locality) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.locality;
        }

        if (addres.city) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.city;
        }

        if (addres.state) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.state;
        }

        if (addres.country) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.country;
        }

        if (addres.zipCode) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.zipCode;
        }
        return completeAddresses

    }



    const checkLocationServicesEnabled = async () => {
        const servicesEnabled = await Location.hasServicesEnabledAsync();
        if (!servicesEnabled) {
            setLocationModalVisible(true);
            return false;
        } else {
            setLocationModalVisible(false);
            addressStore.resetSelectedAddress();
            showMapPicker(true)
        }
        return true;
    };



    return (
        <Screen preset="scroll" headerTitle="Set Delivery Location" showheaderTitle={true} showBackButton={true}>


            {mapPicker ?
                <MapPicker
                    modalVisible={mapPicker}
                    defaultAddressId={addressStore.selectedAddres.addressId}
                    defaultLatitude={addressStore.selectedAddres.latitude}
                    defaultLongitude={addressStore.selectedAddres.longitude}
                    defaultAddressType={addressStore.selectedAddres.addressType}
                    availableAddressTypes={addressStore.getAddressTypes()}
                    defaultHouseInfo={addressStore.selectedAddres.address1}
                    defaultCompleteAddress={addressStore.selectedAddres.address2}
                    defaultLandmark={addressStore.selectedAddres.landmark}
                    getAddressDetails={(address) => setDeliveryLocation(address)}
                    setModalVisibility={(value) => showMapPicker(value)}
                >
                </MapPicker> : null
            }

            <Modal
                visible={locationModalVisible}
                animationType="slide"
                transparent={true}
                onRequestClose={() => setLocationModalVisible(false)}
            >
                <View style={centeredView}>
                    <View style={modalView}>
                        <Text style={modalText}>Please enable location services and then press OK to continue.</Text>
                        <Button
                            preset="secondary"
                            text="OK"
                            onPress={checkLocationServicesEnabled}
                        />
                    </View>
                </View>
            </Modal>

            {Platform.OS != "web" ?
                <TouchableOpacity style={{flexDirection: 'row', alignItems :'center', margin: 20}}
                    onPress={() => { checkLocationServicesEnabled() }}>
                    <View style={{marginRight: 10}}>
                        <MaterialIcons name="my-location" size={20} color={color.ananthamOrange} />
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={{fontWeight: 'bold', color: color.ananthamOrange}}>Use Current Location</Text>
                    </View>
                </TouchableOpacity> : null
            }

            <View style={{ borderBottomColor: color.line, borderBottomWidth: 1, marginHorizontal: 15 }}></View>

            {addressStore.addressList.length > 0 ?
                <View style={{ margin: 20 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 13 }}>SAVED ADDRESSES</Text>
                </View> : null
            }



            {addressStore.addressList.length > 0 ?

                <ScrollView style={{ width: '100%' }}>
                    {addressStore.addressList.map((address, index) => {
                        return (
                            <View style={{ marginHorizontal: 5 }}>
                                <TouchableOpacity onPress={() => setDeliveryLocation(address)} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                                    <Ionicons
                                        name={getIcon(address.addressType)}
                                        style={{ padding: 15, fontSize: 18, textAlign: 'center' }}
                                        color={color.primary}
                                    />
                                    <View style={{ marginRight: 15, flex: 1 }}>
                                        <Text preset="bold" style={{ marginBottom: 3 }}>{address.addressType.replace(/([A-Z])/g, ' $1').trim()}</Text>
                                        <Text preset="fieldLabel">{generateFullAddress(address)}</Text>
                                    </View>
                                </TouchableOpacity>
                                <View style={{ borderBottomColor: color.line, borderBottomWidth: 1, marginHorizontal: 15, marginBottom: 15 }}></View>
                            </View>
                        )
                    })
                    }
                </ScrollView> :
                <View>
                </View>

            }
            <View style={{ flexDirection: 'row', margin: 20, justifyContent: 'space-between' }}>
                <Button
                    preset="primary"
                    style={{ width: "100%" }}
                    text='ADD NEW ADDRESS'
                    onPress={() => [navAddAddress()]}
                />
            </View>
        </Screen>
    )
})

const centeredView: ViewStyle = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
}
const modalView: ViewStyle = {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
}
const modalText: TextStyle = {
    marginBottom: 15,
    textAlign: 'center',
    color: color.ananthamOrange,
}






