import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, StyleSheet } from "react-native"
import { DropdownProps } from "./dropdown.props"
// import { Icon } from "../icon/icon"
import { spacing, color, styles } from "../../theme"
import { Layout, Select, SelectItem, IndexPath } from "@ui-kitten/components";
import { Text } from "../text/text"


// static styles
const ROOT: ViewStyle = {
  paddingHorizontal: spacing[2],
  paddingBottom: 20,
}


/**
 * Dropdown that appears on many screens. Will hold navigation buttons and screen title.
 */
export const Dropdown: Component<DropdownProps> = props => {
  const {
    dropdownList,
    placeholder,
    title,
    titleStyle,
    selectedState,
    onValueChange,
    getSelectedItem,
    defaultValue
  } = props


  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const displayValue = dropdownList[selectedIndex.row]['name'];

  const [list, setList] = React.useState([]);



  useEffect(()=>{
    setList(dropdownList)
    getSelectedItem(dropdownList[0])
  }, [])

  useEffect(()=>{
    if(defaultValue){
      list.map((item, index) =>{
        if(defaultValue == item.id){
          setSelectedIndex(new IndexPath(index))
        }
      })
    }
  }, [defaultValue])

  return (
    <View style={{...ROOT}}>
      
          {/* <Text preset="fieldLabel"  text={title}  style={{...styles.label_lg, ...titleStyle}} /> */}
          <Layout level='1' style={{}}>
            <Select
              status='basic'
              selectedIndex={selectedIndex}
              placeholder={placeholder}
              value={displayValue}
              onSelect={index => {setSelectedIndex(index);getSelectedItem(dropdownList[index['row']])}}>
              {list.map((r, index) => 
                <SelectItem key={index} title={r.name}/>
              )}    
            </Select>
          </Layout>
        
    </View>
  )
}





        {/* <DropDownPicker
            items={dropdownList}
            defaultValue={state.country}
            containerStyle={{height: 40}}
            style={{backgroundColor: '#fafafa', borderBottomColor: color.primary, borderBottomWidth: 3, 
            borderTopColor:'#fff', borderLeftColor:'#fff', 
            borderRightColor:'#fff', borderRadius: 0, }}
            arrowStyle={{marginRight: 10, marginLeft: 10}}
            dropDownStyle={{backgroundColor: '#fafafa'}}
            onChangeItem={item => (state.country = item.value)}
          /> */}
