import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ScrollView, Platform, Modal, ViewStyle, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen } from "../../../components"
import { color } from "../../../theme"
import { useStores } from "../../../models/root-store/root-store-context";
import { ApiUser } from "../../../services/api/api-methods/api-user"
import { AddressCard } from "../../../components/address-card/address-card"
import { AddressMapPicker } from "./components/address-map-picker/address-map-picker";
import * as Location from 'expo-location';


export const AddressScreen: Component = observer(function AddressScreen() {
  
  const { userStore, addressStore } = useStores()

  const navigation = useNavigation()
  
  const navAddressDetails = (operation) => navigation.navigate('logged_in_path', 
                                                {screen: "address_details", params: {action: operation}})

  const [mapPicker, showMapPicker] = useState(false)
  const [action, setAction] = useState("")
  const [locationModalVisible, setLocationModalVisible] = useState(false);

  useEffect(()=>{
    getUserAddress()
  }, [userStore.defaultAddress])

  

  const getUserAddress = () => {
    const api = new ApiUser ()
    api.getAllAddress().then(res => { 
      if(res['kind'] == "ok"){
        addressStore.create(res['data'])
      }
    })
  }

  const deleteAddress = (addressId) => {
    const api = new ApiUser ()
    api.addressDelete(addressId).then(res => { 
      if(res.kind == "ok"){
        addressStore.delete(addressId)
      }
    })
  }

  const addressOperation = (address) => {
    addressStore.updateSelectedAddress(address)
    if(address["action"] == "add"){
      addAddrees()
    } else {
      updateAddrees()
    }
  }

  const addAddrees = () => {
    const api = new ApiUser ()
    api.addressAdd(addressStore.selectedAddres).then(res => { 
      if(res['kind'] == "ok"){
        getUserAddress()
      }    
    })
  }

  const updateAddrees = () => {
    const api = new ApiUser ()
    api.addressUpdate(addressStore.selectedAddres, addressStore.selectedAddres.addressId).then(res => { 
      if(res['kind'] == "ok"){
        getUserAddress()
      }    
    })
  }

  const editAddressPress = (address) => {
    setAction('update')
    addressStore.updateSelectedAddress(address) 
    if(Platform.OS == "web" || !address.latitude || !address.longitude){
      navAddressDetails('update')
    } else {
      showMapPicker(true)
      // navAddressDetails('update')
    }
  }

  const addAddressPress = () => {
    setAction('add'); 
    addressStore.resetSelectedAddress(); 
    if(Platform.OS == "web"){
      navAddressDetails('add')
    } else {
      checkLocationServicesEnabled()
    }
  }

  const checkLocationServicesEnabled = async () => {
    const servicesEnabled = await Location.hasServicesEnabledAsync();
    if (!servicesEnabled) {
      setLocationModalVisible(true);
      return false;
    } else {
      setLocationModalVisible(false);
      showMapPicker(true)
      // navAddressDetails('add')
    }
    return true;
  };


  return (

    <Screen preset="fixed" headerTitle="Manage Addresses" showheaderTitle={true} showBackButton={true}>
      <Modal
        visible={locationModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setLocationModalVisible(false)}
      >
        <View style={centeredView}>
          <View style={modalView}>
            <Text style={modalText}>Please enable location services and then press OK to continue.</Text>
            <Button
              preset="secondary"
              text="OK"
              onPress={checkLocationServicesEnabled}
            />
          </View>
        </View>
      </Modal>
      { !mapPicker ?
        <View style={{flex:1}}>
          
            { addressStore.addressList.length > 0 ?

              <ScrollView style={{width:'100%', paddingHorizontal: 15, marginTop: 10}}>
                { 
                    addressStore.addressList.map((address, index) => {
                      return (
                        <AddressCard
                          key={address.addressType}
                            addressType = {address.addressType}
                            address={address} 
                            editPress={(address)=>editAddressPress(address)} 
                            deletePress={(addressId) => deleteAddress(addressId)}>
                        </AddressCard>
                        )
                    })
                }
              </ScrollView> :
              <View style={{flex: 1, justifyContent: 'center', alignSelf: 'center',  alignContent: 'center'}}>
                <Text style={{fontWeight:'bold', color:color.dim}}>
                  No Address Added  
                </Text>
              </View>
            }
            
            { addressStore.addressList.length < 4 ?
                <View style={{width: "100%", padding: 15}}>
                  <Button
                    preset="primary"
                    text="ADD NEW ADDRESS"
                    onPress={()=> addAddressPress()}></Button>
                </View> : null
            }

        </View> 
        :
        <AddressMapPicker 
            action={action}
            modalVisible={mapPicker}
            defaultAddressId={addressStore.selectedAddres.addressId} 
            defaultLatitude={addressStore.selectedAddres.latitude} 
            defaultLongitude={addressStore.selectedAddres.longitude} 
            defaultAddressType={addressStore.selectedAddres.addressType} 
            availableAddressTypes={addressStore.getAddressTypes()} 
            defaultHouseInfo={addressStore.selectedAddres.address1} 
            defaultCompleteAddress={addressStore.selectedAddres.address2}
            defaultLandmark={addressStore.selectedAddres.landmark}
            getAddressDetails={(address)=> addressOperation(address)}
            setModalVisibility={(value)=> showMapPicker(value)}
          >
        </AddressMapPicker>
      }
    </Screen>
  )
})


const centeredView: ViewStyle = {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 22,
}
const modalView: ViewStyle = {
  margin: 20,
  backgroundColor: 'white',
  borderRadius: 20,
  padding: 35,
  alignItems: 'center',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  elevation: 5,
}
const modalText: TextStyle = {
  marginBottom: 15,
  textAlign: 'center',
  color: color.ananthamOrange,
}
