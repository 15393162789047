import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Image, ImageStyle, StyleSheet } from "react-native"
import { spacing, color, styles } from "../../../../theme"
import { WarningPopupProps } from "./warning-popup.props";
import { Button } from "../../../../components";
import { useStores } from "../../../../models";


export const WarningPopup: Component<WarningPopupProps> = props => {
    const {
      modelWaringType,
      serviceDate,
      setModalVisibility,
      resetThenAddInventory
    } = props
  

    const { cartStore, domainStore } = useStores()

  
    return (
        <View style={inventoryStyles.mainContainer}>
            <View style={[inventoryStyles.popupCard]}>

                <View style={{padding:20, marginTop: 10, }}>
                    <Text>Hi, </Text>
                    {
                        modelWaringType =='businessWarning'?
                            <Text>
                                It seems like you have already added {domainStore.inventoryName[4].toLowerCase()} from another {domainStore.businessName.toLowerCase()}. Do you want to clear that and add new {domainStore.inventoryName[4].toLowerCase()} from this {domainStore.businessName.toLowerCase()}?
                            </Text>
                            : 
                            <View> 
                                { modelWaringType == 'dateWarning' ?
                                    <Text>
                                        It seems like you have already added {domainStore.inventoryName[4].toLowerCase()} from {cartStore.orderDate}. Do you want to clear that and add new {domainStore.inventoryName[4].toLowerCase()} from {serviceDate.toString()}?
                                    </Text>
                                    :
                                    <Text>
                                        You cannot order Ready-to-Buy items with items that require supplier approval. Do you want to clear that and add new {domainStore.inventoryName[4].toLowerCase()} from this {domainStore.businessName.toLowerCase()}?
                                    </Text>
                                }
                            </View>
                    }

                </View>

                <View style={{flexDirection:'row', paddingTop:20, paddingHorizontal:20, justifyContent: 'center'}}>

                    <Button
                        preset="secondary"
                        style={{ flex:1, marginRight: 15 }}
                        text='Cancel'
                        onPress={() => setModalVisibility(false)}
                    />
                    <Button
                        preset="primary"
                        style={{ flex: 1, marginLeft: 15 }}
                        text='Proceed'
                        onPress={() => resetThenAddInventory()}
                    />

                </View>
            </View>
        </View>
    )
}





  const inventoryStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginHorizontal: 15,
        marginVertical: 5
    },
    mainContainer: {
        flex:1,
        width:'100%',
        height:'100%',
        position:'absolute',
        backgroundColor: '#0D1F3C80',
        justifyContent:'flex-end',
        margin: 0,
        padding: 0,
    } as ViewStyle,
    popupCard:{
        width:'100%',
        backgroundColor: color.white,
        padding: 20,
        paddingTop: 10,
        paddingVertical: 20,
        borderTopLeftRadius :10,
        borderTopRightRadius :10
    } as ViewStyle
});