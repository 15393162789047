import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { Image, ImageStyle, View, ViewStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen } from "../../components"
import { color, spacing, styles } from "../../theme"
import { SettingsCard } from "./components/settings-card/settings-card";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { useStores } from "../../models/root-store/root-store-context";
import { ApiUser } from "../../services/api/api-methods/api-user"
import * as ImagePicker from 'expo-image-picker';
import { Ionicons } from "@expo/vector-icons";
import { clear } from "../../utils/storage";
import DeleteAccountPopUp from "../../components/delete-account-popup/delete-account-popup"


export const ProfileScreen: Component = observer(function ProfileScreen() {
  
  const { userStore, addressStore } = useStores()
  
  const cross_arrow_white = require('../../../assets/edit.png')
  const userDefaultImage = require('../../../assets/user.jpeg')
  const loader = require('../../../assets/preloader1.gif')

  const [imageUpload,setImageUpload] = useState(false)

  const navigation = useNavigation()

  const [deletePopUp, setDeletePopup] = React.useState(false)

  const editName = () => navigation.navigate('logged_in_path', {screen: "edit_name"})
  const editEmail = () => navigation.navigate('logged_in_path', {screen: "edit_email"})
  const editPhone = () => navigation.navigate('logged_in_path', {screen: "edit_phone"})
  const navAddress = () => navigation.navigate('logged_in_path', {screen: "address"})
  
  useEffect(()=>{
    userInfoApi()
  }, [userStore.email, userStore.phoneNumber])

  const userInfoApi = () => {
    const api = new ApiUser ()
    api.userInfo().then(res => { 
      responseWork(res)
    })
  }
  
  const responseWork = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data){
        userStore.updateFirstName(data['firstName'])
        userStore.updateLastName(data['lastName'])
        userStore.updatePhoneNumber(data['mobileNumber'])
        userStore.updateEmailVerified(data['emailVerified'])
        if(data['middleName']){
          userStore.updateMiddleName(data['middleName'])
        }
        else{
          userStore.updateMiddleName(data[''])
        }

        if(data['email']){
          userStore.updateEmail(data['email'])
        } else{
          userStore.updateEmail(data[''])
        }

        if(data['userAvatarUri']){
          userStore.updateUserAvatarUri(data['userAvatarUri'])
        } else{
          userStore.updateUserAvatarUri("")
        }

      } else{
        // error message
      }
    }

  }

  const responseWorkImage = (res) => {

    setImageUpload(false)
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data){
        userStore.updateUserAvatarUri(data['url'])
      } 
    }

  }

  const signOut = async () => {
    if(userStore.isWebUrl){
      navigation.navigate("account_from_service")
    } else {
      navigation.navigate("account")
    }
    userStore.reset()
    addressStore.reset()
    await clear()
  }


  const userImageApi = (uri) => {
    setImageUpload(true)
    const api = new ApiUser ()
    api.userImage(uri).then(res => { 
      responseWorkImage(res)
    })
  }

  
  // Image picker
  const [selectedImage, setSelectedImage] = React.useState(null);

  let openImagePickerAsync = async () => {
      let permissionResult = await ImagePicker.requestCameraPermissionsAsync();

      if (permissionResult.granted === false) {
          // alert('Permission to access camera roll is required!');
          return;
      }

      let pickerResult = await ImagePicker.launchImageLibraryAsync();

      if (pickerResult.canceled === true) {
          return;
      }

      setSelectedImage({ localUri: pickerResult.assets[0].uri });
      userImageApi(pickerResult.assets[0].uri)
  };


  const showDeletePopup = () => {
    setDeletePopup(true);
  };

  return (

    <Screen preset="fixed" headerTitle="Profile" showheaderTitle={true} showBackButton={true}>
      
      <View style={CONTAINER}>
    
        {deletePopUp ?
          <DeleteAccountPopUp setDeletePopup={setDeletePopup} deletePopUp={deletePopUp} /> : null
        }
        <View style={WIDTH_100}>

            <ScrollView style={{width: "100%", paddingHorizontal: 20}} contentContainerStyle={{alignItems: 'center'}}>

                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width:'100%', 
                              paddingVertical:40, paddingHorizontal:5}}>

                    <TouchableOpacity onPress={openImagePickerAsync}>
                      
                        { userStore.userAvatarUri?
                          <Image style={PROFILE_PIC} source={{uri:userStore.userAvatarUri}} />
                          :
                          <Image style={PROFILE_PIC} source={userDefaultImage}/>
                        }
                        { imageUpload?
                            <Image style={{...PROFILE_PIC,position:'absolute',scaleX:0.6,scaleY:0.6}} source={loader}/>
                          : null
                        }
                        <TouchableOpacity style={EDIT_BTN_CONTAINER}>
                          {
                              userStore.userAvatarUri?
                            <Image style={EDIT_BTN} source={cross_arrow_white}/>
                            :
                            <Ionicons name='md-add' style={{color:color.white}} size={20} ></Ionicons>
                          }
                        </TouchableOpacity>

                    </TouchableOpacity>
                      
                    <View style={{flexGrow:1}}>
                      <TouchableOpacity style={{alignItems:'center',flexDirection:'row',flexGrow:1, justifyContent:'space-between'}} onPress={editName}>
                        <View>
                          <Text style={{...styles.title_primary,textAlign:'left', paddingHorizontal:20}} >
                            {userStore.firstName}{" "+userStore.middleName}{' '}{userStore.lastName}
                          </Text>
                        </View>
                        <Ionicons
                          name={'ios-arrow-forward'}
                          size={spacing[4]}
                          color={color.text}
                          />
                      </TouchableOpacity>
                    </View>

                </View>

                <SettingsCard cardText="Email" cardValue={userStore.email} icon='mail' onCardPress={editEmail}></SettingsCard>
                <SettingsCard cardText="Phone Number" cardValue={userStore['phoneNumber']} icon='phone-portrait' onCardPress={editPhone}></SettingsCard>
                <SettingsCard cardText="Manage Addresses" cardValue={''} icon='pin' onCardPress={navAddress}></SettingsCard>
            <SettingsCard cardText="Delete Account" cardValue={''} icon='person-remove' onCardPress={showDeletePopup}></SettingsCard>

            </ScrollView>
        </View>
      </View>

      <View style={{margin: 30, alignSelf: "center"}}>
          <Button
            style={{minWidth: "100%"}}
            text="SIGN OUT"
            onPress={signOut}></Button>
      </View>

    </Screen>
  )
})

const CONTAINER: ViewStyle = {
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center"
}


const PROFILE_PIC: ImageStyle = {
  width: 80,
  height: 80,
  borderRadius: 50,
}


const WIDTH_100: ViewStyle = {
  width: "100%",
  alignItems: "center",
}

const EDIT_BTN_CONTAINER: ViewStyle = {
  width: 25,
  height: 25,
  borderRadius: 15,
  backgroundColor: color.primary,
  marginLeft: 55,
  marginTop:-24,
  justifyContent: "center",
  alignItems: "center"
}

const EDIT_BTN: ImageStyle = {
  width: 17,
  height: 17,
}


  
  
  