import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, TouchableOpacity, Modal, Image, ViewStyle, ImageStyle, ScrollView, Keyboard } from 'react-native';
import MapView, { Marker } from 'react-native-maps';
import * as Location from 'expo-location';
import { observer } from "mobx-react-lite";
import { AddressMapPickerProps } from "./address-map-picker.props";
import { ApiGoogleServices } from "../../../../../services/api/api-methods/google-services";
import { Text, Button, TextField } from "../../../../../components";
import { color } from "../../../../../theme";
import { Ionicons } from "@expo/vector-icons";

export const AddressMapPicker: Component<AddressMapPickerProps> = observer(props =>  {
    
    const {
        action,
        defaultAddressId,
        modalVisible = false,
        defaultLatitude,
        defaultLongitude,
        defaultCompleteAddress,
        defaultAddressType,
        defaultHouseInfo,
        defaultLandmark,
        availableAddressTypes = [],
        getAddressDetails,
        setModalVisibility
    } = props


    const marker = require('../../../../../../assets/pin.png')
    const loaderImage = require("../../../../../../assets/location-loader.gif")

    const [isKeyboardView, setIsKeyboardView] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loader, setLoader] = useState(null);

    const [completeAddress, setCompleteAddress] = useState(null);
    const [addressComponents, setAddressComponents] = useState({
        "pincode": null, 
        "locality": null,
        "city": null,
        "district": null,
        "state": null,
        "country": null
    });


    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [landmark, setLandmark] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null);
    const [addressType, setAddressType] = useState(null);


    useEffect(() => {
        (async () => {
            let { status } = await Location.requestPermissionsAsync();
            if (status !== 'granted') {
                setErrorMsg('Permission to access location was denied');
            } 

            if (defaultLatitude && defaultLongitude){
                setLatitude(defaultLatitude)
                setLongitude(defaultLongitude)
                setAddressType(defaultAddressType)
                setCompleteAddress(defaultCompleteAddress)
                setHouseInfo(defaultHouseInfo)
                setLandmark(defaultLandmark)
                setLoader(true)

            } else {
                let location = await Location.getLastKnownPositionAsync({});
                if (location == null) {
                    location = await Location.getCurrentPositionAsync({});
                }
                setLatitude(location.coords.latitude.toString())
                setLongitude(location.coords.longitude.toString())
                setReverseAddress(location.coords.latitude, location.coords.longitude, true)
                setLoader(true)
            }
            setModalVisibility(true)
        })();
    }, []);


    const selectLocationHandler = event => {
        setReverseAddress(event.latitude, event.longitude)
        setLatitude(event.latitude)
        setLongitude(event.longitude)
        setErrorMsg(null)
    };


    const saveAndProceed = ()=> {

        if(latitude && longitude && landmark && houseInfo && addressType){
            setErrorMsg(null)
            setModalVisibility(false)
            getAddressDetails({
                "action": action,
                "addressId": defaultAddressId,
                "country": addressComponents.country, 
                "city": addressComponents.city, 
                "state": addressComponents.state,
                "district": addressComponents.district,
                "locality": addressComponents.locality,
                "zipCode": addressComponents.pincode,
                "landmark": landmark,
                "address1": houseInfo,
                "address2": completeAddress,
                "latitude": latitude,
                "longitude": longitude,
                "addressType": addressType
            })

        } else if(!addressType){
            setErrorMsg("Please pick an address type")
        } else if (!latitude && !longitude) {
            setErrorMsg("Please pick a location")
        } else if (!houseInfo){
            setErrorMsg("Please enter House Name/ Flat/ Block No.")
        } else if (!landmark){
            setErrorMsg("Please enter a landmark")
        } else {
            setErrorMsg("Some error occured")
        }
    }

    const setReverseAddress = (latitude, longitude, updateStore = false) => {
        const api = new ApiGoogleServices()
        api.getReverseLocation(latitude, longitude).then(res => {
            if(res.kind == "ok"){
                var addressComponents = getAddressComponents(res.data.results[0].address_components)
                setAddressComponents(addressComponents)
                var address = res.data.results.length && res.data.results[0].formatted_address ? res.data.results[0].formatted_address : ""
                setCompleteAddress(address)
            } 
        })
    }


    const getAddressComponents = (address_components) => {
        
        var pincode = null
        var locality = null
        var city = null
        var district = null
        var state = null
        var country = null
                
        var is_locality_identified = false

        address_components.forEach((element, index) => {


            if(element["long_name"] != "Unnamed Road" && !is_locality_identified && isNaN(element["long_name"])){
                locality = element["long_name"]
                is_locality_identified = true
            }

            if(element["types"].includes("postal_code")){
                pincode = element["long_name"]  
            } else if (element["types"].includes("sublocality") || element["types"].includes("sublocality_level_1")){
                city = element["long_name"]
            } else if (element["types"].includes("administrative_area_level_1")){
                state = element["long_name"]
            } else if (element["types"].includes("administrative_area_level_2")){
                district = element["long_name"]
            } else if (element["types"].includes("country")){
                country = element["long_name"]
            }

        });

        return {
            "locality": locality,
            "city": city,
            "district": district,
            "state": state,
            "country": country,
            "pincode": pincode, 
        }
    }

    useEffect(() => {
        Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
        Keyboard.addListener("keyboardDidHide", _keyboardDidHide);
    
        // cleanup function
        return () => {
          Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
          Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
        };
      }, []);
    
    const _keyboardDidShow = () => {
    setIsKeyboardView(true)
    };

    const _keyboardDidHide = () => {
        setIsKeyboardView(false)
    };

    const getAddressTypeText = (type) => {
        var addressType = type.replace(/([A-Z])/g, ' $1').trim()
        addressType = addressType.split(" ")[0]
        return addressType
    }
        
    return (
    
        <View style={{marginVertical: 10}}> 

            <Modal
                visible={modalVisible}
                animationType={'slide'}
                transparent={false}
                onRequestClose={() => setModalVisibility(false)}>
                    
                <View style={{flex: 1}}>
                    
                    { loader ?
                        <View style={{height: isKeyboardView? "10%" : "40%", width: "100%"}}>
                            <MapView
                                style={{height: "100%", width: "100%"}}
                                initialRegion={{
                                    latitude: Number(latitude),
                                    longitude: Number(longitude),
                                    latitudeDelta: 0.01,
                                    longitudeDelta: 0.01
                                }}
                                maxZoomLevel={20}
                                zoomControlEnabled={true}
                                showsUserLocation={true}
                                onRegionChangeComplete={region => selectLocationHandler(region)}
                            />
                             { !isKeyboardView ?
                                <View style={MARKER_POSITION}>
                                    <Image style={MARKER} source={marker} />
                                </View> : null
                            }
                        </View> : 
                        <View style={{height: "73%", width: "100%", justifyContent: "center", alignContent: "center"}}>
                                <Image style={{width: 150, height: 150, alignSelf: "center"}} source={loaderImage}/>
                        </View>
                    }

                    <View style={{flexGrow:1, marginBottom: 20}}>

                        <View style={{borderTopColor: "#f8f9fa", borderTopWidth: 1}}></View>
                        

                        <ScrollView style={{padding: 15, flex:1}}>

                            <Text style={{color: color.grey ,marginHorizontal:5, marginBottom: 10, fontWeight: 'bold', fontSize: 10}}>
                                SELECT DELIVERY LOCATION
                            </Text>

                            { completeAddress ?
                                <View>

                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Ionicons name="ios-location" size={20} color={color.black} />
                                        <Text style={{margin: 5, fontSize: 20, fontWeight: 'bold'}}>{addressComponents.locality}</Text>
                                    </View>
                                    
                                    <Text style={{marginHorizontal: 5, marginBottom: 20, fontSize: 13}}>{completeAddress}</Text>

                                    <TextField defaultValue={houseInfo} required={true} showError={errorMsg ? true: false} label="House Name/ Flat/ Block No." 
                                                rootStyle={{marginBottom: 10}} getText={text => setHouseInfo(text)}>
                                    </TextField>

                                    <TextField defaultValue={landmark} required={true} showError={errorMsg ? true: false} label="Landmark" 
                                                getText={text => setLandmark(text)}>
                                    </TextField>

                                    <View style={{flexDirection: 'row', margin: 20, justifyContent: 'center', flexWrap: 'wrap'}}>
                                        {
                                            availableAddressTypes.map((type)=>{
                                                return (
                                                    <TouchableOpacity onPress={()=> setAddressType(type)}
                                                        style={{backgroundColor: addressType == type ? "#e8f8ff" : color.line, 
                                                            borderColor: addressType == type ? color.primary : color.line, justifyContent: 'center', 
                                                            borderWidth: 1, borderRadius: 10, margin: 5, minWidth: 65, height: 35}}>
                                                        <Text style={{textAlign: 'center', fontSize: 12, paddingHorizontal: 10}}>{getAddressTypeText(type)}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })
                                        }
                                    </View>
                                </View> : 
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <Ionicons name="ios-location" size={20} color={color.black} />
                                    <Text style={{margin: 5, fontSize: 20, fontWeight: 'bold'}}>Please wait ...</Text>
                                </View>
                            }
                        </ScrollView>
                        
                        
                        { errorMsg ? 
                            <Text style={{fontSize: 11, color: color.ananthamOrange, textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                                {errorMsg}
                            </Text> : null
                        }
                        <Button
                            style={{backgroundColor: color.primary, height:45, marginHorizontal: 20}}
                            textStyle={{color: color.white, fontSize: 15}}
                            text="SAVE AND PROCEED"
                            onPress={saveAndProceed}>
                        </Button>

                    </View>

                </View>
            
            </Modal>

        </View>
    
    );
});



const MARKER_POSITION: ViewStyle = {
    left: '50%',
    marginLeft: -20,
    marginTop: -40,
    position: 'absolute',
    top: '50%'
}

const MARKER: ImageStyle = {
    height: 40,
    width: 40
}
