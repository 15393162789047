import { types } from "mobx-state-tree";

const AddressModel = types
.model()
.props({
    addressType: types.maybeNull(types.optional(types.string, "")),
    addressId: types.maybeNull(types.optional(types.number, 0)),
    address1: types.maybeNull(types.optional(types.string, "")),
    address2: types.maybeNull(types.optional(types.string, "")),
    locality: types.maybeNull(types.optional(types.string, "")),
    landmark: types.maybeNull(types.optional(types.string, "")),
    city: types.maybeNull(types.optional(types.string, "")),
    district: types.maybeNull(types.optional(types.string, "")),
    state: types.maybeNull(types.optional(types.string, "")),
    country: types.maybeNull(types.optional(types.string, "")),
    latitude: types.maybeNull(types.maybeNull(types.optional(types.string, ""))),
    longitude: types.maybeNull(types.maybeNull(types.optional(types.string, ""))),
    zipCode: types.maybeNull(types.optional(types.string, ""))
})


export const AddressListModel = types
.model()
.props({
    addressList: types.maybeNull(types.optional(types.array(AddressModel),[])),
    selectedAddres: types.maybeNull(types.optional(AddressModel, {})),
    deliveryAddres: types.maybeNull(types.optional(AddressModel, {})),
}).actions(self=>({
    create(addressList){
        self.addressList = addressList
    },
    reset(){
        self.addressList.clear()
        this.resetDeliveryAddress()
    },
    resetSelectedAddress(){
        self.selectedAddres.addressType = null
        self.selectedAddres.addressId = 0
        self.selectedAddres.address1 = null
        self.selectedAddres.address2 = null
        self.selectedAddres.locality = null
        self.selectedAddres.landmark = null
        self.selectedAddres.city = null
        self.selectedAddres.district = null
        self.selectedAddres.state = null
        self.selectedAddres.country = null
        self.selectedAddres.latitude = null
        self.selectedAddres.longitude = null
        self.selectedAddres.zipCode = null
    },
    updateSelectedAddress(address){
        self.selectedAddres.addressType = address.addressType
        self.selectedAddres.addressId = address.addressId
        self.selectedAddres.address1 = address.address1
        self.selectedAddres.address2 = address.address2
        self.selectedAddres.locality = address.locality
        self.selectedAddres.landmark = address.landmark
        self.selectedAddres.city = address.city
        self.selectedAddres.district = address.district
        self.selectedAddres.state = address.state
        self.selectedAddres.country = address.country
        self.selectedAddres.latitude = address.latitude ? address.latitude.toString() : null
        self.selectedAddres.longitude = address.longitude ? address.longitude.toString() : null
        self.selectedAddres.zipCode = address.zipCode
    },
    updateDeliveryAddress(address){
        self.deliveryAddres.addressType = address.addressType
        self.deliveryAddres.addressId = address.addressId
        self.deliveryAddres.address1 = address.address1
        self.deliveryAddres.address2 = address.address2
        self.deliveryAddres.locality = address.locality
        self.deliveryAddres.landmark = address.landmark
        self.deliveryAddres.city = address.city
        self.deliveryAddres.district = address.district
        self.selectedAddres.state = address.state
        self.selectedAddres.country = address.country
        self.deliveryAddres.latitude = address.latitude ? address.latitude.toString() : null
        self.deliveryAddres.longitude = address.longitude ? address.longitude.toString() : null
        self.deliveryAddres.zipCode = address.zipCode
    },
    updateSelectedAddressAddress1(address1){
        self.selectedAddres.address1 = address1
    },
    updateSelectedAddressAddress2(address2){
        self.selectedAddres.address2 = address2
    },
    updateSelectedAddressLandmark(landmark){
        self.selectedAddres.landmark = landmark
    },
    updateSelectedAddressZipCode(zipCode){
        self.selectedAddres.zipCode = zipCode
    },
    updateSelectedAddressType(addressType){
        self.selectedAddres.addressType = addressType
    },
    resetDeliveryAddress(){
        self.deliveryAddres.addressType = null
        self.deliveryAddres.addressId = null
        self.deliveryAddres.address1 = null
        self.deliveryAddres.address2 = null
        self.deliveryAddres.locality = null
        self.deliveryAddres.landmark = null
        self.deliveryAddres.city = null
        self.deliveryAddres.district = null
        self.deliveryAddres.state = null
        self.deliveryAddres.country = null
        self.deliveryAddres.latitude = null
        self.deliveryAddres.longitude = null
        self.deliveryAddres.zipCode = null
    },
    checkMandatory(){

        if(self.selectedAddres.address1 && self.selectedAddres.address1.trim().length && 
                self.selectedAddres.zipCode && self.selectedAddres.zipCode.trim().length && 
                self.selectedAddres.landmark && self.selectedAddres.landmark.trim().length && 
                self.selectedAddres.addressType && self.selectedAddres.addressType.trim().length
        ){
            return true
        } else {
            return false
        }
    },
    delete(addressId){
        self.addressList.map((address, index) => {
            if(addressId == address.addressId){
                self.addressList.splice(index, 1)
            }
        })

        if(self.deliveryAddres.addressId == addressId){
            this.resetDeliveryAddress()
        }
    },
    getAddressTypes(){
        var allAddressTypes = ["Home",  "Work", "RentalHome", "Others"]
        var availableTypes = []
        
        self.addressList.map((element, index) => {
            availableTypes.push(element.addressType)
        })   

        var availableAddressTypes = []

        allAddressTypes.map((type)=>{
            if(!availableTypes.includes(type)){
                availableAddressTypes.push(type)
            } 
        })

        if (self.selectedAddres.addressType && !availableAddressTypes.includes(self.selectedAddres.addressType)){
            availableAddressTypes.push(self.selectedAddres.addressType)
        }
        return availableAddressTypes
    },
    getDeliveryAddress(addressId){
        var deliveryAddress = null
        self.addressList.map((address) => {
            if(addressId == address.addressId){
                deliveryAddress =  address
            }
        })
        return deliveryAddress
    },
    getAddressWithPincode(pincode){
        var addressListPincodes = []
        self.addressList.map((address, index) => {
            if(pincode == address.zipCode){
                addressListPincodes.push(address)
            }
        })
        return addressListPincodes
    },
    getOtherDeliveryAddress(){
        var otherAddress = []
        self.addressList.map((address, index) => {
            if(self.deliveryAddres.addressId != address.addressId){
                otherAddress.push(address)
            }
        })
        return otherAddress
    }
}))

