import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { Image, ImageStyle, View, ViewStyle, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField } from "../../../components"
import { color, spacing, styles } from "../../../theme"
import { SettingsCard } from "../components/settings-card/settings-card";
import { ScrollView } from "react-native-gesture-handler";
import { clear } from "../../../utils/storage";
import { useStores } from "../../../models/root-store/root-store-context";
import { ApiUser } from "../../../services/api/api-methods/api-user"



export const EditEmailScreen: Component = observer(function EditEmailScreen() {
  
  const { userStore } = useStores()
  const navigation = useNavigation()
  
  const [errormsg,setErrormsg] = useState("")
  const [errormsgEmail,setErrormsgEmail] = useState("")
  const [newEmail,setNewEmail] = useState("")
  let [showOtpScreen,setShowOtpScreen] = useState(false)
  let [resendOtp,setRresendOtp] = useState(false)
  let [resendOtpTime,setRresendOtpTime] = useState(30)

  const navProfileInfo = () => navigation.goBack()


  useEffect(()=>{
    setNewEmail(userStore.email)
    if(!userStore.emailVerified){
      setErrormsgEmail('Pending Verification')
    }

  }, [userStore.email])


  function startResendOtpTimer() {
    // userStore.updateOtp("")
    setRresendOtp(false)
    let interval = setInterval(() => {
      if(resendOtpTime>0){
        setRresendOtpTime(--resendOtpTime)
      }
      else{
        setRresendOtp(true)
        clearInterval(interval)
        setRresendOtpTime(30)
      }
     }, 1000);
  }

  
  function verifyInputs() {
    userStore.updateOtp("")
    if(newEmail){
      setErrormsgEmail('')
      if(newEmail == userStore.email){
        emailOtpApi()
      }
      else{
        emailStatusAPi()
      }
    }
    else{ setErrormsgEmail('Enter Email Id') }
  }


  const responseWorkEmail = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["isUserExists"]){
        setErrormsgEmail('Email Id already exist')
      }
      else{
        emailOtpApi()
      }
    }
    else{
      setErrormsgEmail('Invalid Email Id')
    }
  }

  

  const responseWorkOtp = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["otpReferenceId"]){
        userStore.updateOtpReferenceId(data['otpReferenceId'])
        setShowOtpScreen(true)
        startResendOtpTimer()
      }
      else{
        // updateApi()
      }
    }
    else{
      // setErrormsgEmail('Invalid Email Id')
    }
  }

  

    // API
    function emailStatusAPi() {
      const api = new ApiUser ()
      api.emailStatus(newEmail).then(res => { 
        responseWorkEmail(res)
      })
    }
  
    function emailOtpApi() {
      const api = new ApiUser ()
      api.emailOtp(newEmail).then(res => { 
        responseWorkOtp(res)
      })
    }



  function verifyOtpInput() {
    if(userStore.otp.length == 4){
      setErrormsg('')
      if(newEmail == userStore.email){
        emailVerifyApi()
      }
      else{
        updateApi()
      }
    }
    else{ setErrormsg('Invalid OTP') }
  }

  function emailVerifyApi(){
    const param = {
      "email":userStore.email,
      "otp": userStore.otp,
      "otpReferenceId": userStore.otpReferenceId,
    }
    const api = new ApiUser ()
    api.emailVerify(param).then(res => { 
      responseWorkVerify(res)
    })

  }

  function updateApi(){
    let param = {
      "firstName": userStore.firstName,
      "middleName": userStore.middleName,
      "lastName": userStore.lastName,
      "emailDetails": {
        "otp": userStore.otp,
        "otpReferenceId": userStore.otpReferenceId,
        "email": newEmail
      },
      "phoneNumberDetails": null
    }


    const api = new ApiUser ()
    api.userInfoUpdate(param).then(res => { 
      responseWork(res)
    })
  }

  
  const responseWork = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data['value']){
        userStore.updateEmail(newEmail)
        userStore.updateEmailVerified(true)
        navProfileInfo()
      }
      else{
        // error message
        setErrormsg('Invalid OTP')
      }
    }
    else{
      setErrormsg('Invalid OTP')
    }

  }

  const responseWorkVerify = (res) => {

    if(res['kind'] == "ok"){
      let data = res['data']
      if(data['status']){
        userStore.updateEmailVerified(true)
        navProfileInfo()
      }
      else{
        // error message
        setErrormsg('Invalid OTP')
      }
    }
    else{
      setErrormsg('Invalid OTP')
    }

  }

  



  return (

    <Screen preset="fixed" headerTitle="Email" showheaderTitle={true} showBackButton={true}>

      <View style={CONTAINER}>
            <View>
            <TextField errorMessage={errormsgEmail} disabled={showOtpScreen} label='Email' icon='mail' defaultValue={newEmail} getText={text => setNewEmail(text)}></TextField>
            
            {
                showOtpScreen?  
                  <View>
                        <View style={{marginBottom:20}}>
                          <TextField errorMessage={errormsg} label='Enter Your OTP' icon='phone' keyboardType='numeric'  getText={text => userStore.updateOtp(text)}></TextField>
                        </View>
                        <Text style={{...SUB_TITLE}}>
                          Didn’t receive the OTP?
                          {resendOtp?
                              <Text style={{color: color.primary}} onPress={() => [verifyInputs(), startResendOtpTimer()]}>
                                {' '} Resend OTP
                              </Text>
                              :
                              <Text style={{color:color.dim}}>
                                {' '} Resend OTP (00:{resendOtpTime})
                              </Text>
                          }

                        </Text>
                        <Text style={{textAlign:'center',color:color.dim,padding:10,marginBottom: 10}}>Please check Spam or Promotions Folders also.</Text>
                      </View>
                  :
                  <View>
                    <Text style={{textAlign:'center',color:color.dim,padding:10}}>We will send verification to your Email</Text>
                  </View>
                        }
            </View>

              {
                showOtpScreen?


                  <View style={{marginBottom: 40, alignSelf: "center"}}>
                    <Button
                      style={{minWidth:'100%'}}
                      text="Submit"
                      onPress={verifyOtpInput}
                    />
                  </View>
                :
                
                <View style={{ alignSelf: "center"}}>
                  <Button
                    style={{minWidth:'100%'}}
                    text="Send OTP"
                    onPress={verifyInputs}></Button>
                </View>

              }


      </View>

    </Screen>
)
})

const CONTAINER: ViewStyle = {
  justifyContent: "space-between",
  alignItems: "center",
  height:'100%',
  padding: 20,
}


const  SUB_TITLE: TextStyle = {
  width: "100%",
  color: color.text,
  textAlign: "center"
}


