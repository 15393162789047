import { StyleSheet, StatusBar, Platform, ViewStyle, TextStyle } from 'react-native';
import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

import { color, spacing } from '../theme';
import { Directions } from 'react-native-gesture-handler';
import { FormRow } from '../components';

export const styles = StyleSheet.create({
//   safeArea: {
//     flex: 1,
//     backgroundColor: color.warningText,
//     paddingTop: Platform.select({ ios: 0, android: StatusBar.currentHeight }),
//   },
  title_container:{
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.black,
    paddingTop: 40,
    paddingVertical: 30,  
    width: "100%",
    // color: color.White,
    // backgroundColor: color.black,
    // fontSize: 26,
    // textAlign: "center",
    // fontWeight: "bold" 
  },
  title_primary: {
    width: "100%",
    color: color.black,
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold" 
  },
  title_secondary: {
    width: "100%",
    color: color.dim,
    // fontSize: 15,
    textAlign: "center",
    // fontWeight: "bold" 
  } as TextStyle,
  sub_title: {
    width: "100%",
    color: color.text,
    textAlign: "center"
  },
  blue_text_button: {
    color: color.ananthamBlue,
  },
  bottom_card: {
    width: width,
    borderRadius: 30,
    fontSize: 15,
    paddingVertical: 30,
    backgroundColor: color.white,
    justifyContent: "space-around",
    alignItems: "center",
    bottom: 0
  },
  button: {
    minWidth:'100%',
    height: 46,
    textAlign: "center",
    textAlignVertical: "center",
    paddingVertical: 20
    },

    buttonSm: {
      minWidth: 100,
      width: '40%',
      height: 46,
      borderRadius: 23,
      textAlign: "center",
      textAlignVertical: "center"
    },
    type1: {
      color: color.white,
      fontSize: 17,
    },
    type1b: {
        backgroundColor: color.primary,
    },
    type2: {
        color: color.primary,
        fontSize: 17,
    } as TextStyle,
    type2b: {
        backgroundColor: color.transparent,
        borderColor:color.primary,
        borderWidth:1
    } as ViewStyle,
    type3: {
      color: color.white,
      fontSize: 17,
    } as TextStyle,
    type3b: {
      backgroundColor: color.dim,
    } as ViewStyle,
    TextInput_title: {

    },
    TextInput: {
      height: 40, 
      fontSize: 24,
      borderColor: '#CFD2D8', 
      borderBottomWidth: 1,
      width: "90%"
    },
    label_lg: {
      fontSize: 14,
      color: color.dim,
      marginBottom: spacing[2]
    },
    label_sm: {
      fontSize: spacing[3],
      color: color.dim,
      marginBottom: spacing[2]
    },
    primaryColor: {
      color: color.primary
    },
    dangerColor: {
      color: color.error
    },

    img_styles : {
      width: 65,
      height: 65,
      // position: "absolute",
      borderRadius: 50,
    },

})
