import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, Text, TouchableOpacity } from "react-native"
import { Ionicons } from "@expo/vector-icons";
import { spacing, color } from "../../../../theme";
import { AddressCardProps } from "./address-card.props";


// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[1],
    padding: spacing[2],
    width: "100%"
}

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const AddressCard: Component<AddressCardProps> = props => {
  const {
    address,
    addressType,
    onPress,
    style,
    deliverablePincodes
  } = props
  

    
  const getIcon = (addressType) => {
    if(addressType == "Home"){
        return "ios-home"
    } else if (addressType == "Work") {
        return "ios-briefcase"
    } else if (addressType == "RentalHome") {
        return "ios-pin"
    } else {
        return "ios-pin"
    }
} 


  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity  onPress={() => onPress()} style={{flexDirection:'row', borderWidth:1, borderColor:color.nuetralBlue, width:"100%", padding:10, borderRadius:5}}>
            <View style={{padding:5, justifyContent:'center'}}>
                <Ionicons
                    name={getIcon(addressType)}
                    style={{width: 50,fontSize:30,textAlign:'center'}}
                    color={color.primary}
                    />
            </View>
            <View style={{borderColor:color.nuetralBlue, borderRightWidth:1, height:'100%', borderStyle:'dotted', marginHorizontal:10}}>
            </View>
            <View style={{paddingHorizontal:10}}>
                <View>
                
                    <View>
                        <Text style={{fontWeight:'bold',paddingVertical:5}}>{addressType ? addressType.replace(/([A-Z])/g, ' $1').trim(): null}  </Text>
                        { address['address1'] ? <Text>{address['address1']}</Text> : null }
              {address['address2'] ? <Text>{address['address2']}</Text> : null}
                        { address['landmark'] ? <Text>{address['landmark']}</Text> : null } 
                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {address['locality'] ? <Text>{address['locality']} </Text> : null}
                          { address['district'] ? <Text>{address['district']} </Text> : null }
                          { address['zipCode'] ? <Text>{address['zipCode']}</Text> : null }
                        </View>
                    </View>

                    {/* { deliverablePincodes && !deliverablePincodes.includes(Number(address['zipCode'])) ?
                      <Text style={{fontSize: 10, color: color.ananthamOrange, marginTop: 10}}>No delivery to this location</Text> : null
                    }  */}
                </View>
            </View>

        </TouchableOpacity>
    </View>
    
  )
}
