/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your PrimaryNavigator) which the user
 * will use once logged in.
 */
import React from "react"
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { PrimaryNavigator } from "./primary-navigator"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * We recommend using MobX-State-Tree store(s) to handle state rather than navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type RootParamList = {
  primaryStack: undefined
}

const Stack = createStackNavigator<RootParamList>()

const RootStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false
      }}
    >  
      <Stack.Screen
        name="primaryStack"
        component={PrimaryNavigator}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  )
}

const linking = {
  config: {
    primaryStack: {
      path: "",
      screens: {
        home: {
          path: "",
          screens: {
            dashboard: {
              path: "",
              screens: {
                service_list: "service-list",
              }
            },
            cart: "cart",
            account: "account"
          }
        },
        logged_in_path: {
          path: "account-details",
          screens: {
            profile: "profile",
            edit_name: "edit-name",
            edit_email: "edit-email",
            edit_phone: "edit-phone",
            edit_address: "edit-address",
            address: "address",
            address_details: "address-details" ,   
            order_list: "order-list",
            order_details: "order-details",
            help_and_support: "help-and-support",
          }
        },
        logged_out_path: {
          path: "account-register",
          screens: {
            sign_in: "sign-in",
            register: "register",
            verify_otp: "verify-otp",
          }
        },
        service_details: "service-details",
        service: ":domain/:service",
        cart_from_service: "service/cart",
        account_from_service: "user/account",
        service_info: "service-info",
        order_summary: "order-summary",
        payment_status: "payment_status",
        payment: "payment",
        terms_and_conditions: "terms-and-conditions",
      }
    },
    NotFound: "404",
  },
};

export const RootNavigator = React.forwardRef<
  NavigationContainerRef,
  Partial<React.ComponentProps<typeof NavigationContainer>>
>((props, ref) => {
  return (
    <NavigationContainer {...props} ref={ref} linking={linking}>
      <RootStack />
    </NavigationContainer>
  )
})

RootNavigator.displayName = "RootNavigator"
