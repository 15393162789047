import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Image, ImageStyle } from "react-native"
import { ServiceCardProps } from "./service-card.props"
import { spacing, color } from "../../../../theme"
import { Icon } from "../../../../components";
import { useStores } from "../../../../models";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[4],
    alignItems: "center",
    paddingTop: spacing[3],
    paddingBottom: spacing[3],
}

const CARD_TITLE: TextStyle = { 
    color: color.text,
    flexGrow:1,
}

const MAIN_TITLE: TextStyle = { 
    fontWeight: 'bold',
    textAlignVertical:'center',
}

const SUB_TITLE: TextStyle = { 
    color: color.dim,
}

const IMG_STYLES: ImageStyle = {
    width: 65,
    height: 65,
    borderRadius: 50,
}


export const ServicerCard: Component<ServiceCardProps> = props => {
  const {
    title,
    imageSource,
    onCardPress,
    location,
    style,
    titleStyle,
  } = props

  const { domainStore } = useStores()

  return (
    <TouchableOpacity style={{ ...ROOT, ...style }} onPress={onCardPress}>
        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width:'100%'}}>
            <View style={{width:80}}>
                {   imageSource != null?  
                    <Image source={{uri:imageSource}} style={IMG_STYLES}></Image> : 
                    <Icon icon={domainStore.imagePrefix + "_logo"} style={{ marginRight:20, ...IMG_STYLES }}></Icon>
                }
            </View>
            <View style={{flexGrow: 1, flex: 1}}>
                <Text style= {{...CARD_TITLE, ...titleStyle, ...MAIN_TITLE}}>{title} </Text>
                <Text style= {{...CARD_TITLE, ...titleStyle, ...SUB_TITLE}}>{location} </Text>
            </View>
        </View>
    </TouchableOpacity>
    
  )
}

