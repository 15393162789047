import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, Modal, Image, ViewStyle, ImageStyle, ScrollView } from 'react-native';
import MapView, { Marker } from 'react-native-maps';
import * as Location from 'expo-location';
import { observer } from "mobx-react-lite";
import { ApiGoogleServices } from "../../services/api/api-methods/google-services";
import { Text, Button } from "../../components";
import { color } from "../../theme";
import { Ionicons } from "@expo/vector-icons";
import { MapPickerProps } from "./map-picker.props";

export const MapPicker: Component<MapPickerProps> = observer(props =>  {
    
    const {
        defaultAddressId,
        modalVisible = false,
        defaultLatitude,
        defaultLongitude,
        defaultCompleteAddress,
        getAddressDetails,
        setModalVisibility
    } = props


    const marker = require('../../../assets/pin.png')

    const [errorMsg, setErrorMsg] = useState(null);
    const [loader, setLoader] = useState(null);

    const loaderImage = require("../../../assets/location-loader.gif")

    const [completeAddress, setCompleteAddress] = useState(null);
    const [addressComponents, setAddressComponents] = useState({
        "pincode": null, 
        "locality": null,
        "district": null,
        "state": null,
        "country": null
    });


    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);


    useEffect(() => {
        (async () => {
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                setErrorMsg('Permission to access location was denied');
            } 

            if (defaultLatitude && defaultLongitude){
                setLatitude(defaultLatitude)
                setLongitude(defaultLongitude)
                setCompleteAddress(defaultCompleteAddress)
                setLoader(true)

            } else {
                let location = await Location.getLastKnownPositionAsync({});
                if (location == null) {
                    location = await Location.getCurrentPositionAsync({});
                }
                setLatitude(location.coords.latitude.toString())
                setLongitude(location.coords.longitude.toString())
                setReverseAddress(location.coords.latitude, location.coords.longitude, true)
                setLoader(true)
            }
            setModalVisibility(true)
        })();
    }, []);


    const selectLocationHandler = event => {
        setReverseAddress(event.latitude, event.longitude)
        setLatitude(event.latitude)
        setLongitude(event.longitude)
        setErrorMsg(null)
    };


    const saveAndProceed = ()=> {

        if(latitude && longitude){
            setErrorMsg(null)
            setModalVisibility(false)
            getAddressDetails({
                "addressId": defaultAddressId,
                "country": addressComponents.country, 
                "state": addressComponents.state,
                "district": addressComponents.district,
                "locality": addressComponents.locality,
                "zipCode": addressComponents.pincode,
                "address1": addressComponents.locality,
                "address2": completeAddress,
                "latitude": latitude,
                "longitude": longitude,
            })

        } else if (!latitude && !longitude) {
            setErrorMsg("Please pick a location")
        } else {
            setErrorMsg("Some error occured")
        }
    }

    const setReverseAddress = (latitude, longitude, updateStore = false) => {
        const api = new ApiGoogleServices()
        api.getReverseLocation(latitude, longitude).then(res => {
            if(res.kind == "ok"){
                var addressComponents = getAddressComponents(res.data.results[0].address_components)
                setAddressComponents(addressComponents)
                var address = res.data.results.length && res.data.results[0].formatted_address ? res.data.results[0].formatted_address : ""
                setCompleteAddress(address)
            } 
        })
    }


    const getAddressComponents = (address_components) => {
        
        var pincode = null
        var locality = null
        var district = null
        var state = null
        var country = null
        
        var is_locality_identified = false
        address_components.forEach((element) => {
            
            if(element["long_name"] != "Unnamed Road" && !is_locality_identified && !element["types"].includes("street_number")){
                locality = element["long_name"]
                is_locality_identified = true
            }

            if(element["types"].includes("postal_code")){
                pincode = element["long_name"]
            } else if (element["types"].includes("locality")){
                locality = element["long_name"]
            } else if (element["types"].includes("administrative_area_level_1")){
                state = element["long_name"]
            } else if (element["types"].includes("administrative_area_level_2")){
                district = element["long_name"]
            } else if (element["types"].includes("country")){
                country = element["long_name"]
            }

        });

        return {
            "pincode": pincode, 
            "locality": locality,
            "district": district,
            "state": state,
            "country": country
        }
    }

   
        
    return (
    
        <View> 

            <Modal
                visible={modalVisible}
                animationType={'slide'}
                transparent={false}
                onRequestClose={() => setModalVisibility(false)}>
                    
                <View style={{flex: 1}}>
                    
                    { loader ?
                        <View style={{height: "73%", width: "100%"}}>
                            <MapView
                                style={{height: "100%", width: "100%"}}
                                initialRegion={{
                                    latitude: Number(latitude),
                                    longitude: Number(longitude),
                                    latitudeDelta: 0.01,
                                    longitudeDelta: 0.01
                                }}
                                maxZoomLevel={20}
                                zoomControlEnabled={true}
                                showsUserLocation={true}
                                onRegionChangeComplete={region => selectLocationHandler(region)}
                            />
                            <View style={MARKER_POSITION}>
                                <Image style={MARKER} source={marker} />
                            </View> 
                        </View> : 
                        <View style={{height: "73%", width: "100%", justifyContent: "center", alignContent: "center"}}>
                                <Image style={{width: 150, height: 150, alignSelf: "center"}} source={loaderImage}/>
                        </View>
                    }
                
                    <View style={{flexGrow:1, marginBottom: 20}}>

                        <View style={{borderTopColor: "#f8f9fa", borderTopWidth: 1}}></View>

                        <ScrollView style={{padding: 15, flex:1}}>

                            <Text style={{color: color.grey ,marginHorizontal:5, marginBottom: 10, fontWeight: 'bold', fontSize: 10}}>
                                        SELECT DELIVERY LOCATION
                            </Text>

                            { completeAddress ?
                                <View>
                                
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Ionicons name="ios-location" size={20} color={color.black} />
                                        <Text style={{margin: 5, fontSize: 20, fontWeight: 'bold'}}>{addressComponents.locality}</Text>
                                    </View>
                                    
                                    <Text style={{marginHorizontal: 5, marginBottom: 20, fontSize: 13}}>{completeAddress}</Text>

                                </View> : 
                                <View>
                                
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Ionicons name="ios-location" size={20} color={color.black} />
                                        <Text style={{margin: 5, fontSize: 20, fontWeight: 'bold'}}>Please wait ...</Text>
                                    </View>
                                    
                                </View>
                            }
                        </ScrollView>
                        
                        { errorMsg ? 
                            <View style={{justifyContent: "center", alignItems: 'center', marginBottom: 15}}>
                                <Text preset="error">{errorMsg}</Text>
                            </View> : null 
                        }

                        <Button
                            style={{backgroundColor: color.primary, height:45, marginHorizontal: 15}}
                            textStyle={{color: color.white, fontSize: 15}}
                            text="SAVE AND PROCEED"
                            onPress={saveAndProceed}>
                        </Button>

                    </View>

                </View>
            
            </Modal>

        </View>
    
    );
});



const MARKER_POSITION: ViewStyle = {
    left: '50%',
    marginLeft: -20,
    marginTop: -40,
    position: 'absolute',
    top: '50%'
}

const MARKER: ImageStyle = {
    height: 40,
    width: 40
}
