/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';


import { 
  ProfileScreen,
  SigninScreen,
  RegisterScreen,
  VerifyotpScreen,
  AccountScreen,
  EditNameScreen,
  EditEmailScreen,
  EditPhoneScreen,
  AddressScreen,
  OrderListScreen,
  OrderSummaryScreen,
  CartScreen,
  ServiceDetailsScreen,
  ServiceInfoScreen,
  OrderDetailsScreen,
  HelpAndSupportScreen,
  } from "../screens"

import { DashboardNavigator } from "./dashboard-navigator";
import { PaymentScreen } from "../screens/payment-screen/payment-screen";
import { color } from "../theme";
import { TabBarIcon } from "../components";
import { TermsAndConditionsScreen } from "../screens/terms-and-conditions-screen/terms-and-conditions-screen";
import { AddressDetailsScreen } from "../screens/profile-screen/address-screen/address-details-screen/address-details-screen";
import { AddressMapScreen } from "../screens/address-map-screen/address-map-screen";
import { useStores } from "../models";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type PrimaryParamList = {

  home: undefined

  profile: undefined
  sign_in: undefined
  register: undefined
  verify_otp: undefined

  logged_in_path: undefined
  logged_out_path: undefined

  edit_name: undefined
  edit_email: undefined
  edit_phone: undefined
  edit_address: undefined
  address: undefined
  address_details: undefined

  service_list: undefined
  service_details: undefined
  service_info: undefined
  inventory_details: undefined
  cart_from_service: undefined

  order_list: undefined
  order_details: undefined
  order_summary: undefined
  payment: undefined
  payment_status: undefined

  help_and_support: undefined
  service: undefined

  terms_and_conditions: undefined

  account_from_service: undefined
  address_picker: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<PrimaryParamList>()
const Tab = createBottomTabNavigator();

// const RootStack = observer(() => {
import { observer } from "mobx-react-lite"


export const PrimaryNavigator = observer(() => {
  
  const { userStore } = useStores()

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        gestureDirection: 'horizontal',
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <Stack.Screen name="home" component={Home} options={{title: "Home"}}/>
     
      { userStore.accessToken ? 
        <Stack.Screen name="logged_in_path" component={LoggedInScreen} options={{title: "Profile"}}/> : 
        <Stack.Screen name="logged_out_path" component={NotLoggedInScreen} options={{title: "SignIn"}}/>
      }

      <Stack.Screen name="address_picker" component={AddressMapScreen} options={{title: "Address Picker"}}/>  
      <Stack.Screen name="service_details" component={ServiceDetailsScreen} options={{title: "Service Details"}}/>
      <Stack.Screen name="service" component={ServiceDetailsScreen} options={{title: "Service"}}/>
      <Stack.Screen name="service_info" component={ServiceInfoScreen} options={{title: "Service Info"}}/>
      <Stack.Screen name="cart_from_service" component={CartScreen} options={{title: "Cart"}}/>
      <Stack.Screen name="account_from_service" component={AccountScreen} options={{title: "Account"}}/>


      <Stack.Screen name="order_summary" component={OrderSummaryScreen} options={{title: "Pooja Summary"}}/>

      <Stack.Screen name="payment_status" component={PaymentScreen} options={{title: "Payment Status"}}/>
      <Stack.Screen name="payment" component={PaymentScreen} options={{title: "Payment"}}/>
      
      <Stack.Screen name="terms_and_conditions" component={TermsAndConditionsScreen} options={{title: "Terms & Conditions"}}/>


    </Stack.Navigator>
  )
});



function LoggedInScreen(){
  return(    
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        gestureDirection: 'horizontal',
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}>
        
      <Stack.Screen name="profile" component={ProfileScreen} options={{title: "Profile"}}/>
      <Stack.Screen name="edit_name" component={EditNameScreen} options={{title: "Edit Name"}}/>
      <Stack.Screen name="edit_email" component={EditEmailScreen} options={{title: "Edit Email"}}/>
      <Stack.Screen name="edit_phone" component={EditPhoneScreen} options={{title: "Edit Phone"}}/>
      <Stack.Screen name="address" component={AddressScreen} options={{title: "Address"}}/>
      <Stack.Screen name="address_details" component={AddressDetailsScreen} options={{title: "Address Details"}}/>
      <Stack.Screen name="order_list" component={OrderListScreen} options={{title: "Order List"}}/>
      <Stack.Screen name="order_details" component={OrderDetailsScreen} options={{title: "Order Details"}}/>
      <Stack.Screen name="help_and_support" component={HelpAndSupportScreen} options={{title: "Help & Support"}}/>

    </Stack.Navigator>
  )
}

function NotLoggedInScreen(){
  return(    
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        gestureDirection: 'horizontal',
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}>
        
      <Stack.Screen name="sign_in" component={SigninScreen} options={{title: "Sign In"}}/>
      <Stack.Screen name='register' component={RegisterScreen} options={{title: "Register"}}/>
      <Stack.Screen name="verify_otp" component={VerifyotpScreen} options={{title: "Verify OTP"}}/> 
    </Stack.Navigator>
  )
}


function Home() {
  return (
      <Tab.Navigator 
        tabBarOptions={{
            style: {
              borderTopWidth: 0,
              paddingVertical: 3,
              height: 60,
              shadowColor: '#000',
              backgroundColor: '#fff',
              shadowOffset: { width: 0, height: 0 }
            },
            keyboardHidesTabBar: true,
            activeTintColor:color.primary,
          }}
          initialRouteName='dashboard'
          >

        <Tab.Screen 
          name="cart" 
          component={CartScreen} 
          options={{
            title: 'Cart',
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="cart"/>,
            tabBarVisible: true
          }}
        />
        <Tab.Screen
          name="dashboard"
          component={DashboardNavigator}
          options={{
            title: 'Ellam',
            tabBarVisible: true,
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="dashboard"/>,
          }}
        />

        <Tab.Screen 
          name="account" 
          component={AccountScreen}   
          options={{
            title: 'Account',
            tabBarVisible: true,
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="account"
             />
          }}
        />

      </Tab.Navigator>
  );
}


/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["welcome","dashboard"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
