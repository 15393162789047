import { types } from "mobx-state-tree";
import { Platform } from "react-native";

const customFieldsModel = types
  .model()
  .props({
    fieldName: types.maybeNull(types.optional(types.string, "")),
    fieldValue: types.maybeNull(types.optional(types.string, "")),
})


const invModel = types
.model()
.props({
    inventoryId: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    quantity: types.optional(types.number, 0),
    amount: types.optional(types.number, 0),
    bookingDate: types.optional(types.string, "2020-08-01T02:56:49.246Z"),
    isDellveryFeeAplicable: types.optional(types.boolean, false),
    deliveryType: types.optional(types.string, "NoDelivery"),
    customFields: types.maybeNull(types.optional(types.array(customFieldsModel),[])),
    discountCode: types.maybeNull(types.optional(types.string, "")),
    isDiscountApplied: types.maybeNull(types.optional(types.boolean, false)),
    isEnquireAndPay: types.maybeNull(types.optional(types.boolean, false)),
    enquiryStatus: types.maybeNull(types.optional(types.string, "Pending"))
})
.views(self=>({
    getAddModel(){
        return{
            inventoryId: self.inventoryId,
            quantity: self.quantity,
            amount: self.amount,
            bookingDate: "2020-08-01T02:56:49.246Z",
            customFields: self.customFields
        }
    }
}))

export const CartModel = types
.model()
.props({
    // service details
    businessTypeId: types.optional(types.number, 0),
    businessId: types.optional(types.number, 0),
    serviceIconUri: types.optional(types.string, ""),
    serviceName: types.optional(types.string, ""),
    serviceLocation: types.optional(types.string, ""),
    // cart items array
    inventoryItems: types.optional(types.array(invModel),[]),

    // cart details
    totalAmount: types.optional(types.number, 0),
    itemsTotal: types.optional(types.number, 0),
    orderDate: types.optional(types.string, ""),
    totalItems: types.optional(types.number, 0),
    note: types.optional(types.string, ""),
    inventoryCount: types.optional(types.array(customFieldsModel),[]),
    processingFee: types.optional(types.number, 0),
    deliveryCharge: types.optional(types.number, 0),

    deliveryType: types.optional(types.string, "NoDelivery"),
    addressId: types.optional(types.number, 0),

    addToCartDate: types.optional(types.string, ""),
    
    redirectUrl: types.maybeNull(types.optional(types.string, "")),
    deliverablePincodes: types.maybeNull(types.optional(types.array(types.number), [])),
    minimumQuantity: types.optional(types.number, 0),
    maximumQuantity: types.optional(types.number, 0),
    minimumAmountForDelivery: types.optional(types.number, 0),
    isEnquireAndPay: types.maybeNull(types.optional(types.boolean, false))

})
.actions(self=>({
    create(cartModel){
        self.serviceIconUri = cartModel.serviceIconUri
        self.serviceLocation = cartModel.serviceLocation
        self.serviceName = cartModel.serviceName
        self.inventoryItems = cartModel.inventoryItems
        self.totalAmount = cartModel.totalAmount
        self.totalItems = cartModel.totalItems
        self.businessId = cartModel.businessId
        self.note = cartModel.note
        self.inventoryCount = cartModel.inventoryCount
        self.orderDate = cartModel.orderDate
        self.processingFee = cartModel.processingFee
        self.deliveryType = cartModel.deliveryType
        self.addressId = cartModel.addressId
        self.addToCartDate = cartModel.addToCartDate
        self.deliveryCharge = cartModel.deliveryCharge
        self.deliverablePincodes = cartModel.deliverablePincodes
    },
    updateServiceIconUri(serviceIconUri){
        self.serviceIconUri = serviceIconUri
    },
    updateServiceLocation(serviceLocation){
        self.serviceLocation = serviceLocation
    },
    updateServiceName(serviceName){
        self.serviceName = serviceName
    },
    updatetoTalItems(totalItems){
        self.totalItems = totalItems
    },
    updateTotalAmount(totalAmount){
        self.totalAmount = totalAmount
    },
    updateItemsTotal(itemsTotal){
        self.itemsTotal = itemsTotal
    },
    updateBusinessId(businessId){
        self.businessId = businessId
    },
    updateNote(note){
        self.note = note
    },
    updateOrderDate(orderDate){
        self.orderDate = orderDate
    },
    updateAddToCartDate(date){
        self.addToCartDate = date
    },
    updateDeliveryType(deliveryType){
        self.deliveryType = deliveryType
    },
    updateBusinessTypeId(businessTypeId){
        self.businessTypeId = businessTypeId
    },
    updateInventoryItemsDeliveryType(deliveryType){
        self.deliveryType = deliveryType
        self.inventoryItems.forEach(element => {
            element['deliveryType'] = deliveryType
        });
    },
    updateIsDelivery(){

    },
    updateInventoryItemsDeliveryTypeIfApplicable(deliveryType){
        self.deliveryType = deliveryType
        self.inventoryItems.forEach(element => {
            if(element['isDellveryFeeAplicable']){
            element['deliveryType'] = deliveryType
            }
        });
    },
    updateOrderAmount(orderAmount){
        if('processingFee' in orderAmount){
            self.processingFee = orderAmount['processingFee']
        }
        if('deliveryCharge' in orderAmount ){
            self.deliveryCharge = orderAmount['deliveryCharge']
        }
        if('totalAmount' in orderAmount ){
            self.totalAmount = orderAmount['totalAmount']
        }
    },
    resetOrderAmount(){
        self.processingFee = 0
        self.deliveryCharge = 0
        self.totalAmount = 0
    },
    updateAddressId(addressId){
        self.addressId = addressId
    },
    updateProcessingFee(processingFee){
        self.processingFee = processingFee
    },
    updateDeliveryCharge(deliveryCharge){
        self.deliveryCharge = deliveryCharge
    },
    updateRedirectUrl(redirectUrl){
        self.redirectUrl = redirectUrl
    },
    addInventoryItems(inventoryItems){
        self.inventoryItems.push(inventoryItems)
        self.totalItems = self.totalItems + 1
        self.itemsTotal = self.itemsTotal + inventoryItems['amount']
    },
    getIsDelivery(){
        var isDelivery = false
        self.inventoryItems.map((prop, key) => {
            if(prop.isDellveryFeeAplicable){
                isDelivery = true
            }
        })
        return isDelivery
    },
    updateDeliverablePincodes(deliverablePincodes){
        self.deliverablePincodes = deliverablePincodes && deliverablePincodes.length ? JSON.parse (JSON.stringify(deliverablePincodes)) : null
    },
    updateMinimumQuantity(minimumQuantity){
        self.minimumQuantity = minimumQuantity
    },
    updateMaximumQuantity(maximumQuantity){
        self.maximumQuantity = maximumQuantity
    },    
    updateMinimumAmountForDelivery(minimumAmountForDelivery){
        self.minimumAmountForDelivery = minimumAmountForDelivery
    },
    updateIsEnquireAndPay(isEnquireAndPay){
        self.isEnquireAndPay = isEnquireAndPay
    },
    resetInventoryItems(){
        self.inventoryItems.clear()
        self.totalItems = 0
        self.totalAmount = 0
        self.itemsTotal = 0
        self.note = ""
        self.deliverablePincodes = null
    },
    removeInventoryItems( value = -1, type='id'){

        if(type == 'id'){
            var removeIndex = -1
            self.inventoryItems.forEach((element,index) => {
                if(element['id'] == value){
                    removeIndex = index
                }
            });
            if(removeIndex > -1){
                self.totalItems = self.totalItems - 1
                self.itemsTotal = self.itemsTotal - self.inventoryItems[removeIndex]['amount']
                self.totalAmount = self.itemsTotal
                self.inventoryItems.splice(removeIndex,1)
            }
        }
        else if(type == 'index'){
            if(value > -1){
                self.totalItems = self.totalItems - 1
                self.itemsTotal = self.itemsTotal -self.inventoryItems[value]['amount']
                self.totalAmount = self.itemsTotal
                self.inventoryItems.splice(value,1)
            } 
        }
        if(self.inventoryItems.length == 0){
            self.note = ""
        }
    },
    validateCartItems(){
        if(self.deliveryType == "RequireDelivery"){
            if(self.minimumAmountForDelivery > self.itemsTotal){
                return {
                    "status": "error",
                    "msg" : "Minimun cart value for delivery is ₹" + self.minimumAmountForDelivery
                }
            } 
        } 
        if(self.totalItems < self.minimumQuantity){
            return {
                "status": "error",
                "msg" : "Please add minimum " + self.minimumQuantity + " items to place the order"
            }
        } else if(self.totalItems > self.maximumQuantity){
            return {
                "status": "error",
                "msg" : "You have exceeded the maximum item count of " + self.maximumQuantity + " (items)"
            }
        } else {
            return {
                "status": "success",
                "msg": null
            }
        }
    }
}))
.views(self=>({
    getServiceOrderModel(){
        return {
            businessId: self.businessId,
            remarks: self.note,
            items: self.inventoryItems,
            addressId: self.addressId,
            channel: Platform.OS.toUpperCase(),
            description: "OrderDescription",
            redirectUrl: self.redirectUrl
        }
    }
}))





export const serviceListModel = types
.model()
.props({
    inventoryItems: types.optional(types.number, 0),
    totalAmount: types.optional(types.number, 0),
    totalItems: types.optional(types.number, 0),
})
.actions(self=>({

}))
