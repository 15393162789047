import React, { FunctionComponent as Component, useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { Text, Screen, } from "../../../components"
import { Image, View, StyleSheet, Alert, Linking, Platform, TextStyle, ViewStyle } from "react-native"
import { color } from "../../../theme"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { useStores } from "../../../models"
import { ApiServicesList } from "../../../services/api"


export const ServiceInfoScreen: Component = observer(function ServiceInfoScreen() {

    const { serviceListStore, cartStore, selectedInventoryStore, domainStore } = useStores()

    const [serviceInfo, setServiceInfo] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const loaderImage = require('../../../../assets/preloader1.gif')

    const OpenURLButton = ({ url, children }) => {
        const handlePress = useCallback(async () => {
            // Checking if the link is supported for links with custom URL scheme.
            // const supported = await Linking.canOpenURL(url);

            // if (supported) {
                // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                // by some browser in the mobile
                await Linking.openURL(url);
            // } else {
            //     Alert.alert(`Invalid Reqest`);
            // }
        }, [url]);

        return (
            <TouchableOpacity onPress={handlePress}>
                <Text style={{ color: color.ananthamBlue }}>{children}</Text>
            </TouchableOpacity>);
    };


    useEffect(() => {
        getServicesInfoApi()
    }, [cartStore.inventoryItems])


    function getServicesInfoApi() {
        setIsLoading(true)
        const api = new ApiServicesList()
        api.getServicesInfo(selectedInventoryStore.id, domainStore.domainName[1]).then(res => {
            if (res['kind'] == 'ok') {
                setServiceInfo(res['data'])
            }
            setIsLoading(false)
        })
    }
    function openLoc(lat, lng) {
        var scheme = Platform.OS === 'android' ? 'geo:' : 'https://www.google.com/maps/search/?api=1&query=';
        var url = Platform.OS === 'web' ? scheme + lat + ',' + lng : scheme + lat + ',' + lng + '?q=' + lat + ',' + lng;

        Linking.openURL(url);
    }


    return (
        <Screen preset="scroll" headerTitle={domainStore.businessName + "  Details"} showheaderTitle={true} showBackButton={true}>
            <View>
                {templeHeaderLayout()}
            </View>

            <ScrollView>
                {
                    isLoading ?
                        <View style={{ paddingVertical: 100, justifyContent: 'center', alignItems: 'center', }}>
                            <Image style={{ width: 40, height: 40 }} source={loaderImage} />
                        </View>
                        :
                        <View>
                            { serviceInfo['businessDescription'] || serviceInfo['website'] ?
                                <View style={root_container}>
                                    <View style={{flexDirection:'row', alignItems:'center'}}>
                                        <Text style={title_primary}>About Us</Text>
                                        <View style={{borderColor:color.nuetralBlue,borderBottomWidth:1,flexGrow:1, marginLeft:10}}></View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginVertical: 5, justifyContent: 'space-between' }}>
                                        <Text style={title_terinary}>{serviceInfo['businessDescription']}   </Text>
                                    </View>
                                    { serviceInfo['website'] ?
                                            <View style={{ flexDirection: 'row', marginVertical: 5, justifyContent: 'space-between' }}>
                                                <OpenURLButton url={serviceInfo['website']}>{serviceInfo['website']}</OpenURLButton>
                                            </View>
                                        : null
                                    }
                                </View> : null
                            }

                            <View style={root_container}>
                                <View style={{flexDirection:'row', alignItems:'center'}}>
                                    <Text style={title_primary}>Location</Text>
                                    <View style={{borderColor:color.nuetralBlue,borderBottomWidth:1,flexGrow:1, marginLeft:10}}></View>
                                </View>
                                <View style={{ flexDirection: 'row', marginVertical: 5, justifyContent: 'space-between' }}>
                                    <Text style={[{ width: '70%'}, title_terinary]}>{serviceInfo['addressLaneOne']}  </Text>
                                    { serviceInfo['latitude'] && serviceInfo['longitude'] ?
                                            <Text onPress={() => openLoc(serviceInfo['latitude'], serviceInfo['longitude'])} style={{ color: color.ananthamBlue }}>Navigate</Text>
                                        : null    
                                    }
                                </View>
                            </View>

                            <View style={root_container}>
                                <View style={{flexDirection:'row', alignItems:'center'}}>
                                    <Text style={title_primary}>Contact</Text>
                                    <View style={{borderColor:color.nuetralBlue,borderBottomWidth:1,flexGrow:1, marginLeft:10}}></View>
                                </View>
                                { serviceInfo['businessMobile']?
                                        <View style={{ flexDirection: 'row', marginVertical: 5, justifyContent: 'space-between' }}>
                                            <Text style={title_terinary}>{serviceInfo['businessMobile']}  </Text>
                                            <OpenURLButton url={'tel:'+ serviceInfo['businessMobile']}>Call</OpenURLButton>
                                        </View>
                                    : null      
                                }
                                { serviceInfo['email']?
                                        <View style={{ flexDirection: 'row', marginVertical: 5,marginTop:15, justifyContent: 'space-between' }}>
                                            <Text style={title_terinary}>{serviceInfo['email']}   </Text>
                                            <OpenURLButton url={'mailto: '+serviceInfo['email']}>Email</OpenURLButton>
                                        </View>
                                    : null      
                                }


                            </View>
                        </View>
                }
            </ScrollView>

        </Screen>
    )




    function templeHeaderLayout() {
        const defaultimgurl = require('../../../components/icon/icons/open-kitchen/logo.png')

        return (
            <View style={headerStyles.container}>
  
                <View style={headerStyles.textContainer}>
                    {serviceInfo['businessAvatarUri'] ? <Image source={{ uri: serviceInfo['businessAvatarUri'] }} style={headerStyles.img_styles}></Image> : <Image source={defaultimgurl} style={{ marginRight: 15, ...headerStyles.img_styles }}></Image>}

                    <Text style={[title_primary, {fontSize: 18, textAlign: 'center', paddingTop: 15, marginBottom: 5 }]}>{serviceInfo['businessName']}  </Text>
                    <Text style={[title_secondary, {textAlign: 'center' }]}>{serviceInfo['businessLocation']}  </Text>
                </View>
            </View>
        )
    }


})




const headerStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 20,
    },
    thumbnail: {
        width: 64,
        height: 64,
        resizeMode: 'cover',
    },
    textContainer: {
        alignItems: 'center',
        flexGrow: 1,
    },
    img_styles: {
        width: "100%",
        height: 165,
        borderRadius: 2,
    },
});


const title_primary: TextStyle = {
    color: color.black,
    fontSize: 15,
    textAlign: "center",
    fontWeight: "bold"
}

const title_secondary: TextStyle = {
    color: color.dim,
    textAlign: "center",
}

const title_terinary: TextStyle = {
    color: color.dim,
}

const root_container: ViewStyle = {
    backgroundColor: color.white,
    padding: 10,
    paddingHorizontal: 30
};