import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity } from "react-native"
import { ItemCardProps } from "./item-card.props"
// import { Icon } from "../icon/icon"
import { spacing, color } from "../../theme"
import { translate } from "../../i18n/"
import { Icon } from "../icon/icon";
import { wrap } from "module";
import { Ionicons } from "@expo/vector-icons";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[1],
    paddingTop: spacing[2],
    paddingBottom: spacing[2],
}

const CARD_STYLE: ViewStyle = { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: color.white,    
    borderRadius: 20,
    margin: spacing[1],
    padding: spacing[4],
}

const CARD_TITLE: TextStyle = { 
    color: color.text,
    flexWrap: 'wrap',
    fontSize: spacing[4],
    marginLeft: spacing[2],
    marginRight: spacing[4]
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ItemCard: Component<ItemCardProps> = props => {
  const {
    cardText,
    onCardPress,
    style,
    titleStyle,
    isPublished
  } = props
  
  const cardtext = cardText || (cardText && translate(cardText)) || ""

  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity onPress={onCardPress}>
            <View style={{...CARD_STYLE}}>

                <Text style= {{...CARD_TITLE, ...titleStyle}}>{cardtext}</Text>
                
                <View style={{flexDirection: 'row', flexWrap:'wrap'}}>
                    { isPublished ?
                        <Text style= {{color: color.green}}>{"Published"}</Text> :
                        <Text style= {{color: color.red}}>{"Not Published"}</Text>
                    }

                    <Ionicons
                        name={'ios-arrow-forward'}
                        size={spacing[4]}
                        style={{marginLeft: spacing[3]}}
                        color={color.text}
                        />
                </View>

            </View>
        </TouchableOpacity>
    </View>
    
  )
}
