import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle} from "react-native"
import { spacing, styles } from "../../theme"
import { translate } from "../../i18n"
import { RadioGroup, Radio } from "@ui-kitten/components";
import { Text } from "../text/text"
import { RadioButtonProps } from "./radio-button.props";

 

// static styles
const ROOT: ViewStyle = {
    padding: spacing[2],
}
/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const RadioButton: Component<RadioButtonProps> = props => {
  const {
    items,
    title,
    getSelectedItem,
    style,
    titleStyle,
    defaultValue    
  } = props
  
  const titleText = title || (title && translate(title)) || ""

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(()=>{
    if(defaultValue){
      items.map((item, index) =>{
        if(defaultValue.option == item.option){
          setSelectedIndex(index)
        }
      })
    }
  }, [defaultValue])


  return (
    <View style={{ ...ROOT, ...style }}>

        <Text preset="fieldLabel"  text={titleText}  style={{...styles.label_lg, ...titleStyle}} />

        <React.Fragment>

                <RadioGroup
                selectedIndex={selectedIndex}
                onChange={index => {setSelectedIndex(index);getSelectedItem(items[index])}}>
                    {              
                        items.map((i, index) => <Radio key={index}>{i.option}</Radio>)  
                    }
                </RadioGroup>

        </React.Fragment>
    </View>
    
  )
}
