import * as React from "react"
import { KeyboardAvoidingView, Platform, ScrollView, StatusBar, View, Image, TextStyle, ViewStyle, TouchableHighlight, StyleSheet, Dimensions } from "react-native"
import { useSafeArea, SafeAreaView } from "react-native-safe-area-context"
import { ScreenProps } from "./screen.props"
import { isNonScrolling, offsets, presets } from "./screen.presets"
import { Text } from "../text/text";
import { color, spacing } from "../../theme";
import { Icon } from "../icon/icon";
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from "react-native-gesture-handler";
import { useEffect, useState } from "react"

const isIos = Platform.OS === "ios"
const preloader = require('../../../assets/preloader.gif')


const headerContainerStyle: ViewStyle = { backgroundColor: color.white, flexDirection: 'row', alignItems: 'center', height: 80, minHeight: 50, borderBottomWidth:1 , borderBottomColor: color.nuetralBlue}
const headerBackStyle: TextStyle = { color: color.white, paddingLeft:10,paddingRight: 10,height:'100%',justifyContent:'center',width:'100%', fontSize: spacing[5], fontWeight: 'bold'}
const headerTitleStyle: TextStyle = { color: color.black, paddingLeft: 10, fontSize: 17, fontWeight: 'bold'}


function ScreenWithoutScrolling(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background}
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }
  // const headerContainerStyle: ViewStyle = { backgroundColor: color.white, flexDirection: 'row', alignItems: 'center', borderBottomWidth:0 , borderBottomColor: color.green}
  // const headerTitleStyle: TextStyle = { color: color.black, padding: 20, fontSize: spacing[5], fontWeight: 'bold'}
  const navigation = useNavigation()
  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

      {/* <SafeAreaView style={{height: Dimensions.get('window').height}}> */}
  
        {/* { isIos ? <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor={color.white}/> :
          <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
        } */}

        <View>
          { isIos ?
            <View style={{paddingTop: 20, backgroundColor: color.white}}>
                <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
            </View> :
            <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
          } 
        </View>

        { props.showheaderTitle?
            <View style={headerContainerStyle}>
            { props.showBackButton && Platform.OS != "web" ? 
                <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                  <Icon icon="left"></Icon>
                </TouchableOpacity> 
              : <View></View>
            }
            <Text style={headerTitleStyle}>
                {props.headerTitle}
            </Text>
          </View> 
          :
          <View></View>
        }




          <View style={[preset.outer, style, insetStyle]}>{props.children}</View>
      {/* // </SafeAreaView> */}
    </KeyboardAvoidingView>
  )
}



function ScreenWithoutScrollingFixed(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background}
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }
  const navigation = useNavigation()

  const navAccount = () => navigation.navigate("account_from_service")


  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

      <View style={{height: Dimensions.get('window').height}}>

          <View>
            { isIos ?
              <View style={{paddingTop: 20, backgroundColor: color.white}}>
                  <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
              </View> :
              <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
            } 
          </View>

          { props.showheaderTitle?
          
            <View style={[headerContainerStyle, {justifyContent: 'space-between'}]}>
              <View>
                { props.showBackButton  && Platform.OS != "web"  ?  
                    <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                      <Icon icon="left"></Icon>
                    </TouchableOpacity> 
                  : <View></View>
                }
                <Text style={headerTitleStyle}>
                    {props.headerTitle}
                </Text>
              </View>

              { props.showAccountBtn ?
              <TouchableOpacity style={{marginHorizontal: 10}} onPress={() => navAccount()}>
                <Icon icon="account_selected"/>
              </TouchableOpacity> : null
              }
            </View> 
            :
            <View></View>
          }

          <View style={[preset.outer, style, insetStyle]}>{props.children}</View>

      </View>

    </KeyboardAvoidingView>
  )
}


function ScreenWithScrolling(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.scroll
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background}
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }
  // const headerContainerStyle: ViewStyle = { backgroundColor: color.white, flexDirection: 'row', alignItems: 'center', borderBottomWidth:1 , borderBottomColor:color.nuetralBlue}
  // const headerTitleStyle: TextStyle = { color: color.black, padding: 20, fontSize: spacing[5], fontWeight: 'bold'}
  const navigation = useNavigation()

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

    {/* <SafeAreaView style={{height: "100%"}}> */}

  
      {/* { isIos ? <StatusBar barStyle={props.statusBar || "dark-content"} /> :
          <StatusBar barStyle={props.statusBar || "light-content"} /> 
        }
      */}

      <View>
        { isIos ?
          <View style={{paddingTop: 20, backgroundColor: color.white}}>
              <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
          </View> :
          <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
        } 
      </View>


        {props.showheaderTitle?

          <View style={headerContainerStyle}>
            { props.showBackButton  && Platform.OS != "web"  ? 
                <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                    <Icon icon="left"></Icon>
                </TouchableOpacity>
              : <View></View>
            }
            <Text style={headerTitleStyle}>
                {props.headerTitle}
            </Text>
          </View> 
          :
          <View></View>
        }


        <View style={[preset.outer, backgroundStyle, insetStyle]} >
          <ScrollView
            style={[preset.outer, backgroundStyle]}
            contentContainerStyle={[preset.inner, style]}
          >
            {props.children}

          </ScrollView>
        </View>

      {/* </SafeAreaView> */}

    </KeyboardAvoidingView>
  )
}


function ScreenWithoutScrollingMobile(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background}
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }
  const navigation = useNavigation()
  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

        <View>
          { isIos ?
            <View style={{paddingTop: 20, backgroundColor: color.white}}>
                <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
            </View> :
            <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor="#F8F8F8"/> 
          } 
        </View>

          { props.showheaderTitle?
              <View style={headerContainerStyle}>
              { props.showBackButton  && Platform.OS != "web"  ?  
                  <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                    <Icon icon="left"></Icon>
                  </TouchableOpacity> 
                : <View></View>
              }
              <Text style={headerTitleStyle}>
                  {props.headerTitle}
              </Text>
            </View> 
            :
            <View></View>
          }

          <View style={[preset.outer, style, insetStyle]}>{props.children}</View>

    </KeyboardAvoidingView>
  )
}



// function ScreenWithBoth(props: ScreenProps) {
//   if (isNonScrolling(props.preset)) {
//     return <ScreenWithoutScrolling {...props} />
//   } else if(props.preset == "fixedHeight"){
//     return <ScreenWithoutScrollingFixed {...props} />
//   } 
//   else {
//     return <ScreenWithScrolling {...props} />
//   }
// }



function ScreenWithBoth(props: ScreenProps) {
  if (isNonScrolling(props.preset)) {
    if(Platform.OS != "web"){
      return <ScreenWithoutScrollingMobile {...props} />
    } else{
      return <ScreenWithoutScrolling {...props} />
    }
  } else if(props.preset == "fixedHeight"){
    if(Platform.OS != "web"){
      return <ScreenWithoutScrollingMobile {...props} />
    } else{
      return <ScreenWithoutScrollingFixed {...props} />
    }
  } 
  else {
    return <ScreenWithScrolling {...props} />
  }
}


function LoaderScreen(loader) {
  // const isShowingLoader = app_store.getState()

  useEffect(()=>{
    setIsShowingLoader(loader)
  }, [loader])

  const [isShowingLoader, setIsShowingLoader] = useState(false);

  if (isShowingLoader) {
    return(
    <View style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: '#0D1F3C80'}}>
      <Image style={styles.loaderIcon} source={preloader}/>
    </View>
    );
  } else {
    return null;
  }
}

/**
 * The starting component on every screen in the app.
 *
 * @param props The screen props
 */


export function Screen(props: ScreenProps) {
  return( 
    <View style={{height: "100%"}}>

      <ScreenWithBoth {...props} />
      { props.loader ?
      <View style={{position: "absolute", height: "100%", width: "100%"}}>{LoaderScreen(props.loader)}</View> : 
      <View></View>
      }
      {/* <View style={{position: "absolute", height: 80, width: "100%", bottom: 0}}>{ToastScreen(props.toast)}</View> */}
      
    </View>
  )  
}


const styles = StyleSheet.create({
  loaderIcon: {
    width: 50,
    height: 50,
  },
});