import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, StyleSheet } from "react-native"
import { Picker } from '@react-native-picker/picker';
import { color, spacing } from "../../../../theme"
import { AddItemPopupProps } from "./add-item-popup.props";
import { Button, Text, TextField, TimePickerWidget } from "../../../../components"
import { CodeScanner } from "../../../../components/code-scanner/code-scanner";
import { ApiServicesList } from "../../../../services/api";

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const AddItemPopup: Component<AddItemPopupProps> = props => {
    const {
        item,
        addInventory,
        closePopup
    } = props
  
    const [statusMsg, setStatusMsg] = React.useState({msg: '', status: 'error'});
    const [pickerValue, setPickerValue] = React.useState('')
    const [showCodeScanner, setShowCodeScanner] = React.useState(false)
    const [requiredFieldCount, setRequiredFieldCount] = React.useState(0)
    const [customDetails, setCustomDetails] = React.useState([])

    const starDDList = [
        {'name':'N/A (Not applicable)'},
        {'name':'അശ്വതി (Ashwathi)'},
        {'name':'ഭരണി (Bharani)'},
        {'name':'കാർത്തിക (Karthika)'},
        {'name':'രോഹിണി (Rohini)'},
        {'name':'മകയിര്യം (Makayeeram)'},
        {'name':'തിരുവാതിര (Thiruvathira)'},
        {'name':'പുണർതം (Punartham)'},
        {'name':'പൂയം (Pooyam)'},
        {'name':'ആയില്യം (Aayilyam)'},
        {'name':'മകം (Makam)'},
        {'name':'പൂരം (Pooram)'},
        {'name':'ഉത്രം (Uthram)'},
        {'name':'അത്തം (Attham)'},
        {'name':'ചിത്തിര (Chithira)'},
        {'name':'ചോതി (Chothi)'},
        {'name':'വിശാഖം (Visakham)'},
        {'name':'അനിഴം (Anizham)'},
        {'name':'തൃക്കേട്ട (Thrikketta)'},
        {'name':'മൂലം (Moolam)'},
        {'name':'പൂരാടം (Pooradam)'},
        {'name':'ഉത്രാടം (Uthradam)'},
        {'name':'തിരുവോണം (Thiruvonam)'},
        {'name':'അവിട്ടം (Avittam)'},
        {'name':'ചതയം (Chathayam)'},
        {'name':'പൂരുരുട്ടാതി (Pooruruttathi)'},
        {'name':'ഉതൃട്ടാതി (Uthrittathi)'},
        {'name':'രേവതി (Revathi)'},
    ]


    const [customFields, setCustomFields] = React.useState({})

    useEffect(()=>{
        var requiredCount = 0
        var customDetails = []

        item['customDetails'].forEach(element => {
            if("DefaultValue" in element && element["DefaultValue"] != ""){
                onChangeText(element["DefaultValue"], element["FieldName"])
            }
            if(("IsMandatory" in element && element["IsMandatory"] == true) || 
                        ("DefaultValue" in element && element["DefaultValue"] != "")){
                requiredCount+=1
            }

            if(element['FieldName'] != 'Discount') {
                customDetails.push(element)
            } else {
                if(item['discountAmount'] && item['discountAmount'] > 0) {
                    customDetails.push(element)
                }
            }
        })
        setRequiredFieldCount(requiredCount)
        setCustomDetails(customDetails)
    }, [])

    const onChangeText = (text, key: string = '') => {
        var locItem = customFields
        customFields[key] = text
        setCustomFields(locItem)
    }


    const verifyInputs = (item2, itemCustomFields) => {

        if(Object.keys(customFields).length >= requiredFieldCount){
            itemCustomFields.forEach(element => {
                if(element["FieldName"] in customFields && customFields[element["FieldName"]] != ""){   
                } else {
                    setStatusMsg({msg: 'Enter ' + element["FieldName"], status: 'error'})
                    return
                }
            });
        } else {
            setStatusMsg({msg:'Enter Missing Details', status: 'error'})
            return
        }
        addInventory(item, customFields)
    }

    const validateDiscountCode = (code) => {
        const api = new ApiServicesList()
        api.validateDiscountCode(code).then(res => {
            if(res['data']['status']) {
                onChangeText(code, 'Discount Code')
                setStatusMsg({msg:'QR-Code is valid', status: 'success'})
            } else {
                setStatusMsg({msg:'Invalid QR-Code', status: 'error'})
            }
        })
    }


    
    return (
        
        <View style={inventoryStyles.mainContainer}>
            <View style={[inventoryStyles.popupCard]}>
                <View>
                    <View style={{...inventoryStyles.container, paddingVertical:15}}>
                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{item["name"]} ( {item["categoryName"]} )   </Text>
                    </View>
                    <View>
                        {customDetails.map((prop2, key2) => {
                            return (
                                <View>
                                    <Text style={{fontSize: 12, fontWeight: 'bold', color: color.dim, marginHorizontal: 10, paddingVertical: 5}}>
                                        {prop2.FieldName}
                                    </Text>
                                    <View key={key2} style={{marginBottom: 3}}>
                                        {
                                            prop2.FieldName == 'Star' ?
                                                <View style={{paddingHorizontal: 8}}>
                                                    <View style={{ marginBottom:20, height: 50, width: "100%", borderWidth:1, borderRadius:5, borderColor:color.nuetralBlue, paddingHorizontal:10, justifyContent: 'center'}}>
                                                        <Picker
                                                            selectedValue={pickerValue}
                                                            style={{color:pickerValue==''?color.grey:color.black, borderWidth:0 }}
                                                            onValueChange={(itemValue, itemIndex) => [ setPickerValue(itemValue), onChangeText(itemValue, prop2.FieldName)]}
                                                            >
                                                            <Picker.Item label={"Enter "+prop2.FieldName} value="" />
                                                            {
                                                                starDDList.map((starValue, starKey) => {
                                                                    return (
                                                                        <Picker.Item key={starKey} label={starValue.name} value={starValue.name} />
                                                                        );
                                                                    })
                                                            }
                                                        </Picker>
                                                    </View>
                                                </View>
                                            : prop2.FieldName == 'Time' ?
                                                <TimePickerWidget 
                                                    title={prop2.FieldName } 
                                                    showIcon={false}
                                                    // initialTime={newInventory[prop2.FieldName] ? newInventory[prop2.FieldName] : null}  
                                                    getTime={time=> onChangeText(time, prop2.FieldName)}
                                                ></TimePickerWidget>
                                            : prop2.FieldName == 'Discount' ?
                                                <Button
                                                    preset="active"
                                                    style={{ marginBottom: 20, marginHorizontal: 10, flexGrow:1 }}
                                                    text='Open QR-Code Scanner'
                                                    onPress={() => setShowCodeScanner(true)}
                                                />
                                            :
                                            <TextField
                                                defaultValue={prop2.DefaultValue ? prop2.DefaultValue: ''}
                                                disabled={prop2.IsDisabled}
                                                placeholder={prop2.FieldName } 
                                                getText={text => onChangeText(text, prop2.FieldName)}>
                                            </TextField>
                                        }
                                    </View>
                                </View>
                            );
                        })}


                    </View>

                    <View style={{paddingHorizontal:6}}>
                        { statusMsg.msg ?
                            <Text style={{color: statusMsg.status == 'error'? color.ananthamOrange:color.green, 
                                paddingBottom: 15, textAlign:'center', fontSize: 13}}>
                                {statusMsg.msg}
                            </Text>
                            : null
                        }

                        <View style={{flexDirection:'row', paddingHorizontal:2}}>
                            <Button
                                preset="secondary"
                                style={{ marginBottom: 20, flexGrow:1 }}
                                text='Cancel'
                                onPress={() => closePopup()}
                            />
                            <View style={{paddingHorizontal:10}}></View>
                            <Button
                                preset="primary"
                                style={{ marginBottom: 20, flexGrow:1 }}
                                text='Add Now'
                                onPress={() => verifyInputs(item, item["customDetails"])}
                            />
                        </View>

                    </View>

                </View>
            </View>


            { showCodeScanner ? 
                <CodeScanner
                    display={showCodeScanner} 
                    close={()=> setShowCodeScanner(false)} 
                    update={(scanData)=> {
                        if(scanData.data) {
                            validateDiscountCode(scanData.data)
                        }
                    }}
                ></CodeScanner> : null
            }
        </View>
            
    )
}



const inventoryStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginHorizontal: 15,
        marginVertical: 5
    },
    mainContainer: {
        flex:1,
        width:'100%',
        height:'100%',
        position:'absolute',
        backgroundColor: '#0D1F3C80',
        justifyContent:'flex-end',
        margin: 0,
        padding: 0,
    },
    popupCard:{
        width:'100%',
        backgroundColor: color.white,
        padding: 20,
        paddingTop: 10,
        paddingVertical: 20,
        borderTopLeftRadius :10,
        borderTopRightRadius :10
    },
    scannerCard: {
        width: 100,
        height: 100,
    }
});


  

