import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, Modal, ViewStyle, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { color, styles } from "../../theme";
import { Button, Icon } from "..";
import { CodeScannerProps } from "./code-scanner.props";
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { Html5QrcodeScanner } from "html5-qrcode"


const FULL: ViewStyle = {
    backgroundColor: color.white,
    borderRadius: 10,
    height: 380,
    width: '85%',
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
  
const FULL_OUTER: ViewStyle = {
    flex: 1,
    backgroundColor: "#0D1F3C80",
    justifyContent: 'center',
    alignItems: 'center'
}


const CENTER: ViewStyle = {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
}
  

export const CodeScanner: Component<CodeScannerProps> = props => {
  const {
    close,
    display,
    update,
  } = props

  let html5QrcodeScanner: any;

  const [modalVisible, setModalVisibility] = React.useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  
  useEffect(() => {
    setModalVisibility(display)

    if( Platform.OS === "web" ){
      setTimeout(() => {
        html5QrcodeScanner = new Html5QrcodeScanner(
          "reader",
          { fps: 10, qrbox: {width: 250, height: 250} },
          /* verbose= */ false);
        html5QrcodeScanner.render(onScanSuccess);
      }, 500);

      function onScanSuccess(decodedText, decodedResult) {
        // html5QrcodeScanner.stop() // optional
        html5QrcodeScanner.clear()
        handleBarCodeScanned({type: decodedResult['result']['format']['formatName'], data: decodedText})
        setScanned(true);
      }
    } else {
      const getBarCodeScannerPermissions = async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      };
      getBarCodeScannerPermissions();
    }

  }, [display]);


  // Unsubscribe Camera
  useEffect(() => {
    return () => {
      if(html5QrcodeScanner){
        html5QrcodeScanner.clear()
      }
    }
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    update({ type, data })
    close()
  };

  return (
    <View> 
      <Modal
        visible={modalVisible}
        animationType={'none'}
        transparent={true}
        // onRequestClose={() => {close()}}
        >
          
          <View style={FULL_OUTER}>
            <View style={FULL}>

                <TouchableOpacity style={{flexDirection: 'row', alignSelf: 'flex-end', marginTop: 15, marginRight: 15}} 
                    onPress={close}>
                    <Icon icon={"close"} style={{width: 20, height: 20}}></Icon> 
                </TouchableOpacity>

                <View style={CENTER}>
                  <View style={{ flexDirection: 'column' }}>
                    {
                      Platform.OS != 'web' ?
                        <View>
                          <View style={{ width: 250, height: 250, alignSelf: 'center', marginVertical: 15 }}>
                            <Camera
                              style={StyleSheet.absoluteFillObject}
                              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                              barCodeScannerSettings={{
                                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                              }}
                            />
                          </View>
                          <View style={{ flexDirection: 'row', alignSelf: 'center', marginBottom: 15 }}>
                            <Button
                              style={{ backgroundColor: color.red, borderRadius: 20, width: 125, marginRight: 5 }}
                              textStyle={styles.type1}
                              text="Scan Again"
                              onPress={() => setScanned(false)}
                            />
                            <Button
                              style={{ backgroundColor: color.primary, borderRadius: 20, width: 125, marginLeft: 5 }}
                              textStyle={styles.type1}
                              text="Verify"
                              onPress={() => close()}
                            />
                          </View>
                        </View>
                        :
                        <View style={{ width: 400, height: 400 }}>
                          <div id="reader" style={{ "width": "400px" }}></div>
                        </View>
                    }

                  </View>

                </View>

            </View>

          </View>

      </Modal>

    </View>
  );
};


