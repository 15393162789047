export const url = {


  // Signin
  generateOtp : "/otp/login", // User signin // POST
  login : "/auth/login", // User signin // POST
  token : "/auth/token", // User signin // POST
  getBusinessServiceTypes: "/business/lookup/business-types",

  // DeleteAccount
  deleteAccount: "/users",

  // Service APIs
  services: "/consumers/{domain}", // POST
  servicesDetails: "/consumers/{domain}/{businessInfoId}", // GET
  servicesInfo: "/{domain}/details/{businessInfoId}", // GET
  servicesListDetails: "/consumers/{domain}/items/{businessInfoId}", // GET
  servicesDetailsFromUrl: "/consumers/{domain}/domain/{service}", // GET
  
  //User
  phoneNumberStatus:"/users/phone", // POST
  emailStatus:"/users/email", // POST
  registerUser: "/consumers", // POST
  signIn: "/auth/login", // POST
  resendOtp: "/otp/resend", // POST
  userInfo: "users/info", // GET
  userInfoUpdate: "users/configure", // PUT
  
  // User
  emailOtp : "/otp/email", // POST
  emailVerify : "/otp/consumer/email/verify", // POST
  createOtp : "/users/create/otp", // User signup // POST
  userImage : "/users/image", //POST

  addressAdd : "/user/address", //POST
  addressUpdate : "/user/address", //PUT
  addressgGet : "/user/address", //GET
  addressDelete : "/user/address", //DELETE
  
  // Orders
  orderAll : "/orders/all", // GET
  orderStatus: "/orders/status/{orderId}", //GET
  orderExport: "/orders/status/export", //GET
  orderStatusCashfree: "/order/payment/status/{orderId}", //GET

  //Checkout
  orderCalculate : "/orders/calculate", // POST
  checkout : "/orders/new", // POST
  payment : "/orders", // PUT
  validateDiscount: "/orders/validate/discount/{code}", // POST
  paymentStatus : "/order/payment/status/{orderId}", // GET
  initiatePayment : "/orders/payment", // POST

}

