import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, Image, TextStyle, ImageStyle, Linking, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import { Screen, Icon, Button, Text} from "../../components";
import { TouchableOpacity } from "react-native-gesture-handler"
import { useStores } from "../../models"
import moment from "moment";  
import { initiateFirebaseDatabase } from "../../utils/firebase"
import { getNetworkState } from "../../utils/network"
import { UpdatePopup } from "../../components/update-popup/update-popup";
import Constants from 'expo-constants';
import { ApiBusiness } from "../../services/api/api-methods/api-business";
import { IconTypes } from "../../components/icon/icons";
import * as Notifications from 'expo-notifications';
import { PermissionPopupMessage } from "../../components/permission-popup-message/permission-popup-message"
import { ApiPayment } from "../../services/api/api-methods/api-payment"

export const DashboardScreen: Component = observer(function DashboardScreen() {

  const { userStore, cartStore, networkStore, businessTypesStore, domainStore, addressStore } = useStores()
  const navigation = useNavigation()
  
  const navServiceListScreen = (type) => { 
    domainStore.create(type.id)
    navigation.navigate("service_list")
  }


  const navigateToAddress = () => navigation.navigate("address_picker")

  const [showNotificationMessage, setShowNotificationMessage] =  React.useState(false)
  const [showMessage, setShowMessage] =  React.useState(false)
  const [loading, setLoading] =  React.useState(true)

  useEffect(()=>{
    cartStore.updateRedirectUrl(null)
    userStore.toggleIsWebUrl(false)
    checkNetworkState()
    checkAppVersion()
    cleanupCart()
    businessServicesApi()
    notificationPermissionCheck()
  }, [])


  async function checkNetworkState(){
    var netState = await getNetworkState()
    networkStore.create(netState)
  }

  const RenderLoadingView = () => {
    return (
        <View style={{flex:1, alignItems: "center", alignSelf: 'center', justifyContent: 'center'}}>
            <Image style={{width:40,height:40}} source={preloader}/>
         </View>        
    );
    }

  const preloader = require('../../../assets/preloader1.gif')

  const checkAppVersion = ()=>{

    if(Platform.OS != 'web'){
      var dbConnection = initiateFirebaseDatabase()
      dbConnection.ref('consumer/').on('value', (snapshot) => {
        var appVersion = snapshot.val().version
        var forceUpdate = snapshot.val().force_update
  
        if(Constants.manifest.version == appVersion){
          setShowMessage(false)
        } else {
            forceUpdate ? setShowMessage(true): setShowMessage(false)
        }
        setLoading(false)
      });
    } else {
      setShowMessage(false) 
      setLoading(false)
    }
  }

  const getOrderStatus = ()=> {
    const url = new URL(window.location.href);
    const orderID = url.searchParams.get('order_id')
    if(orderID){
      const api = new ApiPayment()
      api.getOrderStatusCashFree(orderID).then(res => { 
          if(res["data"]['status'] === "Completed"){
              cartStore.resetInventoryItems()
          }
      })
    }
  }

  const cleanupCart = () => {
    if(cartStore.totalItems>0){
      let today = moment().format('YYYY-MM-DD')
      if(today != cartStore.addToCartDate){
        cartStore.resetInventoryItems()
      }
      if(Platform.OS == 'web'){
        getOrderStatus()
      }
    }
  }


  const businessServicesApi = () => {
      const api = new ApiBusiness()
      api.getBusinessTypes().then(res => {
          if (res["kind"] == "ok") {
              businessTypesStore.create(res['data'])
          }        
      })
  }

  const signOut = () => {
    userStore.reset()
    navigation.navigate("account")
  }

  const getIcon = (name) => {
    var serviceName: IconTypes;
    name = name.replace(/\s+/g, '_').replace("&_", '').toLowerCase() + "_logo";
    serviceName = name
    return serviceName
  }

  const notificationPermissionCheck = async () => {
    if (Platform.OS === 'android') {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      if (existingStatus !== 'granted') {
        setShowNotificationMessage(true)
      }
    }
  };

  return (
    <Screen preset="fixed" headerTitle="Dashboard" showBackButton={false}>
      
      <TouchableOpacity style={{margin: 20, flexDirection: 'row', alignItems: 'center'}} onPress={()=>navigateToAddress()}>
          <Icon icon='location_picker' style={{width: 32, height: 32}}></Icon>
          <View style={{marginLeft: 5}}>
            <Text preset="fieldLabel" sliceString={40} text={addressStore.deliveryAddres.address1 ? addressStore.deliveryAddres.address1 : "SET LOCATION"} 
                        style={{fontSize: 16, marginVertical: 0, marginLeft:5, color: color.black, fontWeight: 'bold'}} />
            <Text preset="fieldLabel" sliceString={45} text={addressStore.deliveryAddres.address2 ? addressStore.deliveryAddres.address2 : "Pick a location to deliver your services"} 
                        style={{fontSize: 14, marginVertical: 0, marginLeft:5}} />
          </View>
      </TouchableOpacity>

      <PermissionPopupMessage 
                display={showNotificationMessage} 
                close={()=>setShowNotificationMessage(false)} 
                action={()=>Linking.openSettings()} 
                actionText="Settings"
                message="Enable Notification"
                subMessage="Go to Settings and activate notifications to start receiving notifications." 
                >
      </PermissionPopupMessage> 
      
      { networkStore.isConnected ?
        <View style={{flex: 1}}>
          { !loading ?
            <View>

                { showMessage ? 
                    <UpdatePopup
                      display={showMessage} 
                      close={()=>setShowMessage(false)} 
                      message={"There is a new version available. Please update the application to proceed."}
                      update={()=> { signOut(); Linking.openURL('https://play.google.com/store/apps/details?id=com.ellam.consumer')}} >
                    </UpdatePopup> : null
                }
                
                <View style={{ alignItems: "flex-start" }}>

                  <View style={{ paddingHorizontal: 30, alignItems: "flex-start" }}>
                    <Text style={[TITLE_PRIMARY, { padding: 20, paddingLeft: 0, width: 200}]}>
                      Services
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      { businessTypesStore.businessTypes.map((type, index) =>
                        <View key={index}>
                          { type.status == "Visible" ?
                            <TouchableOpacity onPress={()=> navServiceListScreen(type)}>
                              <View style={{ width:80, marginRight:30, alignItems: "center" }}>
                                <Icon icon={getIcon(type.name)} style={{width: 80, height: 80}}></Icon>
                                <Text style={{...TITLE_SECONDARY, marginTop: 10}}> {type.name}</Text>
                              </View>
                            </TouchableOpacity> : null
                          }
                        </View>
                      )}
                    </View>
                  </View>

                  <View style={{ padding: 30, alignItems: "flex-start" }}>
                    <Text style={[TITLE_PRIMARY, { padding: 20, paddingLeft:0, width:200 }]}>
                      Coming Soon
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      { businessTypesStore.businessTypes.map((type, index) =>
                        <View key={index}>
                          { type.status == "ComingSoon" ?
                              <View style={{ width:80, marginRight:30, alignItems: "center" }}>
                                <Icon icon={getIcon(type.name)} style={{width: 80, height: 80}}></Icon>
                                <Text style={{...TITLE_SECONDARY, marginTop: 10}}> {type.name}</Text>
                              </View> : null
                          }
                        </View>
                      )}
                    </View>
                  </View>

                </View>
            </View> : <RenderLoadingView></RenderLoadingView>
          }
        </View> :

        <View style={{flex:1, alignItems: "center", alignSelf: 'center', justifyContent: 'center'}}>
          
          <Icon icon={"wifi_icon"} style={{width:80, height:80, alignSelf: 'center'}}></Icon>
          <Text style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center', marginVertical: 10}}>     No Internet Connection     </Text>
          <Text style={{textAlign: 'center', marginHorizontal: 20}}>Something went wrong. Please check you network connection.</Text>
          <Button onPress={()=> checkNetworkState()} 
            text={"RETRY"} style={{width: 100, backgroundColor: color.primary, marginTop: 30, height: 38}} 
            textStyle={{fontSize: 14, letterSpacing: 0.5}}>
          </Button>

        </View>
      }

    </Screen>
  )
})


const TITLE_PRIMARY: TextStyle = {
  color: color.black,
  fontSize: 18,
  fontWeight: "bold"
}

const TITLE_SECONDARY: TextStyle = {
  color: color.dim,
  textAlign:'center'
}



