import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, Text, TouchableOpacity, ImageStyle, Image, Platform } from "react-native"
import { color } from "../../theme"
import { ServiceHeaderProps } from "./service.header.props";
import { useStores } from "../../models";
import { Icon } from "..";

 

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ServiceHeader: Component<ServiceHeaderProps> = props => {
  const {
    title,
    subTitle,
    imageUrl,
    onCardPress,
  } = props
  
//   const cardtext = cardText || (cardText && translate(cardText)) || ""
  const { domainStore } = useStores()


  return (

    <TouchableOpacity onPress={onCardPress}  style={CONTAINER}>
        <View>
            {  imageUrl?  <Image source={{uri:imageUrl}} style={IMAGE_VIEW}></Image> : 
              <Icon icon={domainStore.imagePrefix + "_logo"} style={IMAGE_VIEW}></Icon> 
            }
        </View>
        <View style={Platform.OS == "web" ? TEXT_CONTAINER_WEB : TEXT_CONTAINER_MOBILE}>
            <Text style={{fontWeight: 'bold', fontSize:15 }}>{title} </Text>
            <Text style={{color: color.dim }}>{subTitle} </Text>
        </View>
    </TouchableOpacity>
  )
}



const CONTAINER: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15
}


const TEXT_CONTAINER_MOBILE: ViewStyle = {
    flexGrow: 1,
    paddingLeft: 15,
    paddingRight: 55,
}


const TEXT_CONTAINER_WEB: ViewStyle = {
    paddingHorizontal: 15,
    flex: 1,
    flexWrap: 'wrap'
}


const IMAGE_VIEW : ImageStyle = {
    width: 65,
    height: 65,
    borderRadius: 50,
}