import { types } from "mobx-state-tree";

export const OrderPaymentModel = types
.model()
.props({
    paymentNumber: types.optional(types.string, ""),
    gatewayRefenceId: types.optional(types.string, ""),
    isSuccessful: types.optional(types.boolean, false),
    paymentSignature: types.optional(types.string, ""),
    id: types.optional(types.number, 0),


})
.actions(self=>({
    create(data){
        self.isSuccessful = data.isSuccessful
        self.paymentNumber = data.orderId
        self.gatewayRefenceId = data.referenceId
        self.paymentSignature = data.signature

    }
}))
.views(self=>({
    getOrderPaymentModel(){
        return{
            paymentNumber: self.paymentNumber,
            gatewayRefenceId: self.gatewayRefenceId,
            isSuccessful: self.isSuccessful,
            paymentSignature: self.paymentSignature,
            id: self.id
        }
    }
}))




