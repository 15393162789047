import { createStore } from 'redux'

export const app_variables = {
    BusinessList : ['Ellam Stores','Meat & Fish','Saloon & Spa','Delivery Services'],
    ActiveBusinessList : ['Ellam Stores'],
    businessId: 5,
    businessNumber: "",
    authToken: ""
  }
export const init_variables ={
    SignIn : false
}


function todos(state, action) {

  // state = action.text
  switch (action.type) {
    case 'toast':
      state['toast'] = action.text 
      makeFalse()
      break;
    case 'loading':
      state['loading'] = action.text
      break;
  }
  return state

}

function makeFalse(){
  setTimeout(() => {
    app_store.dispatch({
      type: 'toast',
      text: false
    })
  }, 3000);
  
}

export const app_store = createStore(todos, {'toast':false,'loading':false})

// app_store.dispatch({
//   type: 'set',
//   text: 'Read the docs'
// })

