import React, { FunctionComponent as Component, useEffect } from "react"
import { View, Modal, ViewStyle, TextStyle, Image } from 'react-native';
import { color, styles } from "../../theme";
import { PopupMessageProps } from "./popup-message.props";
import { useNavigation } from "@react-navigation/native";
import { Button, Icon, Text } from "..";
import { useStores } from "../../models";


const FULL: ViewStyle = {
  flex: 1,
  backgroundColor: color.white,
  alignItems: "center",
  justifyContent: "space-between",
  padding: 20,
}

const CENTER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}


const LIGHTTEXT: TextStyle = {
  color: color.dim,
  fontSize: 13,
  marginHorizontal: 7,
  marginVertical: 1
}

export const PopupMessage: Component<PopupMessageProps> = props => {
  const {
    type,
    message,
    navigationMessage,
    nextNavigationPath,
    closeNavigationPath,
    display,
    close,
  } = props


  const [modalVisible, setModalVisibility] = React.useState(false);
  const { cartStore } = useStores()

  useEffect(() => {
    setModalVisibility(display)
  }, [display]);


  // navigation
  const navigation = useNavigation()
  const preloader = require('../../../assets/preloader1.gif')

  
  function nextNavigate() {
    if(type == 'SUCCESS' || type == 'success'){
      cartStore.resetInventoryItems()
    }
    navigation.navigate(nextNavigationPath.stack, {screen: nextNavigationPath.screen})
    close()
  }




  return (
    <View style={{margin: 10}}> 
      { type != '' ?
      <Modal
        visible={modalVisible}
        animationType={'slide'}
        transparent={true}
        onRequestClose={() => {nextNavigate()}}>
          
          <View style={FULL}>
              <View style={CENTER}>
                { type == 'SUCCESS' || type == 'Success' || type == 'success' ? <Icon icon={"success_icon"} style={{width:80, height:80}}></Icon> : 
                  type == 'FAILED' || type == 'Failed' || type == 'Failed' ? <Icon icon={"error_icon"} style={{width:80, height:80}}></Icon> : 
                  <Icon icon={"warning_icon"} style={{width:80, height:80}}></Icon>
                }


                <Text style={[styles.title_primary, {margin: 15}]}>
                  {type[0].toUpperCase() + type.slice(1, type.length)}
                </Text>

                <Text style={[LIGHTTEXT, {textAlign: 'center'}]}>{message}</Text>
              
              </View>

              <Text style={[LIGHTTEXT, {textAlign: 'center'}]}>{navigationMessage}</Text>

              <View style={[LIGHTTEXT, {flexDirection: 'row', marginTop: 10}]}>
                {
                  nextNavigationPath ? <Button
                  style={[button, type1b, {margin: 10}]}
                  textStyle={type1}
                  text="Return"
                  onPress={nextNavigate}/> :
                  <View></View>
                }

                {/* <Button
                  style={[styles.buttonSm, styles.type2b, {margin: 10}]}
                  textStyle={styles.type2}
                  text="Close"
                  onPress={closeNavigate}/> */}
              </View>


          </View>

      </Modal>

      :
        <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center', flex: 1}}>
          <Text style={{padding: 30, textAlign: 'center'}}>Processing the request. Please don't close the window</Text>
          <Image style={{width:40,height:40}} source={preloader}/>
        </View>
      }
      
    </View>
  );
};



const button: ViewStyle = {
  minWidth:'100%',
  height: 46,
  paddingVertical: 5
}

const type1: TextStyle ={
    color: color.white,
    fontSize: 17,
}
const type1b: ViewStyle = {
    backgroundColor: color.primary,
}