import { Ionicons } from '@expo/vector-icons';
import { Icon } from '..';
import { Text } from '../text/text';
import { View } from 'react-native';
import { color } from '../../theme';
import { useStores } from '../../models';
import React, { FunctionComponent as Component, useEffect } from "react"
import { observer } from 'mobx-react-lite';
import { TabBarIconProps } from './tabbar-icon.props';

// export default function TabBarIcon(props)  {
export const TabBarIcon: Component<TabBarIconProps> = observer(props =>  {

  // export const PopupMessage: Component<PopupMessageProps> = props => {


  const { cartStore } = useStores()

  if(props.name == 'dashboard'){

      if(props.focused){
        return(<Icon icon="ellam_selected" style={{height:35,width:35}}/>)
      } else {
        return(<Icon icon="ellam_unselected" style={{height:35,width:35}}/>)
      }

  } else if(props.name == 'cart') {

    if(props.focused){
      return(
        <View>
          <Icon icon="cart_selected"/>
          {
            cartStore.totalItems ?
              <Text style={{position:'absolute', width:20,height:20, backgroundColor:color.ananthamOrange,borderRadius:10,textAlign:'center',fontSize:12,color:color.white,top:-8,left:13, paddingTop: 1}}>{cartStore.totalItems}</Text> 
            :
              null
          }
        </View>)  
    }
    else {
      return(
        <View>
          <Icon icon="cart_unselected"/>
          {
            cartStore.totalItems ?
              <Text style={{position:'absolute', width:20,height:20, backgroundColor:color.ananthamOrange,borderRadius:10,textAlign:'center',fontSize:12,color:color.white,top:-8,left:13, paddingTop: 1}}>{cartStore.totalItems}</Text> 
            :
              null
          }
          {/* <Text style={{position:'absolute', width:20,height:20, backgroundColor:color.ananthamOrange,borderRadius:10,textAlign:'center',fontSize:12,color:color.white,top:-8,left:13, paddingTop: 1}}>{cartStore.totalItems}</Text>  */}
        </View>) 
    }

  } else if(props.name == 'account') {

    if(props.focused){
      return(<Icon icon="account_selected"/>)  
    }
    else {
      return(<Icon icon="account_unselected"/>)  
    }

  } else {
    return(<Icon icon="account_unselected"/>)  
  }




})
