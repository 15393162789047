import React, { FunctionComponent as Component, useEffect } from "react"
import { View, TextStyle, ViewStyle } from "react-native"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { TextFieldProps } from "./text-field.props"
import { Input } from "@ui-kitten/components";
import { Icon } from "../icon/icon"

// the base styling for the container
const ROOT: ViewStyle = {
  paddingHorizontal: spacing[2],
  paddingBottom: spacing[2]
}


const inputStyle: ViewStyle = {
  backgroundColor: color.white,
  borderColor:color.white,
  width: '100%',
}


const erroMsgStyle: TextStyle = {
  color: color.error,
  fontSize: 12,
  marginTop: 3
}

const inputContainer: ViewStyle = {
  borderWidth: 1,
  borderRadius: 5,
  flexDirection: 'row',
  alignItems: 'center',
  overflow:'hidden'
}

const errorStyle: ViewStyle = {
  borderColor: color.error,
}
const defaultStyle: ViewStyle = {
  borderColor: color.nuetralBlue,
}

/**
 * A component which has a label and an input together.
 */
export const TextField: Component<TextFieldProps> = props => {
  const {
    placeholderTx,
    placeholder,
    labelTx,
    label,
    defaultValue,
    preset = "default",
    errorMessage,
    disabled,
    icon,
    style,
    required,
    showError,
    rootStyle,
    inputStyle: inputStyleOverride,
    forwardedRef,
    getText,
    keyboardType,
    titleStyle,
    multiline = false,
    ...rest
  } = props

  const [value, setValue] = React.useState('');

  useEffect(()=>{
    setValue(defaultValue)
  }, [defaultValue])


  return (
    <View style={{...ROOT, ...rootStyle}}>
  

      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

        <View style={{flexDirection: 'row'}}>
          { label ? <Text preset="fieldLabel" tx={labelTx} text={label}  style={{...label_lg, ...titleStyle}} /> : null }
          
          { required ?
            <View>
              <Text style={{color: color.ananthamOrange}}> *</Text>
            </View> : null
          }
        </View>

        { required && showError &&  (value == undefined || value == "0" || value.trim().length == 0)?
          <View>
            <Text style={{...label_error}}>Required</Text>
          </View> : null
        }
      </View>

      <View style={[inputContainer, {paddingHorizontal: icon ? 10: 0}, errorMessage ? errorStyle : defaultStyle]}>
        { icon ? <Icon icon={icon} style={{marginLeft:5}}></Icon> : null }
        <Input
          value={value}
          placeholder={placeholder}
          keyboardType={keyboardType}
          multiline={multiline}
          style={{...style, ...inputStyle}}
          onChangeText={nextValue => {setValue(nextValue);getText(nextValue)}}
          disabled={disabled}
        />
      </View>
      { errorMessage ? <View style={{alignItems:"flex-end"}}>
        <Text style={erroMsgStyle}>{errorMessage}</Text>
        </View> : null
       }

    </View>
  )
}


const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing[2]
}



const label_error: TextStyle = {
  fontSize: 12,
  color: color.ananthamOrange,
  marginBottom: spacing[2]  
} 
