import React, { FunctionComponent as Component } from "react"
import { View, StyleSheet, Image, TouchableOpacity, ViewStyle } from "react-native"
import { color } from "../../../../theme"
import { Button, Text } from "../../../../components"
import { InventoryListCardProps } from "./inventory-list-card.props";

 
export const InventoryListCard: Component<InventoryListCardProps> = props => {
  const {
    category,
    daysAvailability,
    inventoryCount,
    inventoryKey,
    checkCustomFeildsAndInventory
  } = props
  
   
  const [showDescription, setShowDescription] = React.useState("")

  const disaplyDescription = (val) => {
    if(showDescription == val){
        setShowDescription("")
    } else {
        setShowDescription(val)
    }
};
    
  return (
    
    <View>
        <View>
            { category["menuItems"] && category["menuItems"].map((inventory, key1) => {
                if (inventory && daysAvailability[category['name']][inventory['id']]) {
                    return (
                        <View key={key1}>
                            <View style={[inventoryStyles.container, {marginBottom: 10}]}>
                                <View style={{ flex: 1, justifyContent: 'center' }}>
                                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{inventory.name.trim()}</Text>
                                        <Text style={{ fontWeight: 'bold', color: color.dim}} >₹ {inventory.amount}</Text>
                                        { inventory.discountAmount ?
                                            <Text style={{ fontWeight: 'bold', color: color.ananthamOrange}}>
                                                Customer price ₹ {inventory.discountAmount}
                                            </Text> : null
                                        }
                                        { inventory.isEnquireAndPay ?
                                            <View style={{flexDirection: 'row'}}>
                                                <Text style={{backgroundColor: color.blueStatusBg, color: color.blueStatus, borderRadius: 10, 
                                                    fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8, marginVertical: 5,}}> 
                                                    Supplier Approval Required
                                                </Text>
                                            </View>
                                            : null

                                        }
                                        { inventory.description ?
                                            <TouchableOpacity onPress={()=> disaplyDescription(inventoryKey + "" + key1)}>
                                                <Text style={{color: color.primary, fontSize: 12, marginTop: 5}}>
                                                    {showDescription == inventoryKey + "" + key1 ? "Hide Details" : "View Details"}
                                                </Text>
                                            </TouchableOpacity> : null
                                        }
                                        { showDescription == inventoryKey + "" + key1 ?
                                            <Text style={{fontSize: 14, color: color.dim, marginRight: 10}}>{inventory.description}
                                            </Text> : null
                                        }

                                </View>
                                <View style={{alignItems:'center', justifyContent: 'center'}}>

                                        <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'center'}}>
                                            { inventoryCount[inventory['id']] && inventoryCount[inventory['id']] > 0 ?
                                                <Text style={{width:20,height:20,borderRadius:12,marginRight:10, textAlign:'center', 
                                                    backgroundColor:color.ananthamOrange,color:color.white, fontSize: 12, paddingTop:1}}>
                                                    {inventoryCount[inventory['id']]}
                                                </Text>
                                                : null
                                            }

                                            {/* <Text>{JSON.stringify(inventory)}</Text> */}

                                            { inventory.avatarUri ? 
                                                <View style={{alignContent: 'center', justifyContent:'center', flexDirection: 'column'}}>
                                                    <Image source={{uri:inventory.avatarUri}} 
                                                        style={{width:100, height:80, alignSelf:'center', borderRadius: 10}}
                                                    ></Image>
                                                    <Button
                                                        preset="inactive_sm"
                                                        text='Add'
                                                        style={{width:80, marginTop: -20, alignSelf: 'center'}}
                                                        onPress={() => [checkCustomFeildsAndInventory(category, inventory)]}
                                                    />
                                                </View>
                                            : 
                                                <Button
                                                    preset="inactive_sm"
                                                    text='Add'
                                                    onPress={() => [checkCustomFeildsAndInventory(category, inventory)]}
                                                />
                                            }
                                        </View>

                                </View>
                            </View>
                            <View
                                style={{
                                    borderBottomColor: color.line,
                                    borderBottomWidth: 1,
                                    marginBottom:8, marginHorizontal:15}} >
                            </View>
                        </View>
                    );
                } else {
                    return null
                }
            })}
        </View>
    </View>
        
  )
}


const inventoryStyles = StyleSheet.create({
    rootContainer: {
        marginHorizontal: 30,
        marginVertical: 10
    } as ViewStyle,
    container: {
        flexDirection: 'row',
        marginHorizontal: 15,
        marginVertical: 5
    } as ViewStyle,
    mainContainer: {
        flex:1,
        width:'100%',
        height:'100%',
        position:'absolute',
        backgroundColor: '#0D1F3C80',
        justifyContent:'flex-end',
        margin: 0,
        padding: 0,
    } as ViewStyle,
    popupCard:{
        width:'100%',
        backgroundColor: color.white,
        padding: 20,
        paddingTop: 10,
        paddingVertical: 20,
        borderTopLeftRadius :10,
        borderTopRightRadius :10
    } as ViewStyle
});