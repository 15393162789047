import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ViewStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Screen, TextField, Text } from "../../../../components"
import { TouchableOpacity } from "react-native-gesture-handler";
import { useStores } from "../../../../models/root-store/root-store-context";
import { ApiUser } from "../../../../services/api/api-methods/api-user"
import { color } from "../../../../theme";
import GMapComponent from "../../../../components/map-picker/gmap-picker"


export const AddressDetailsScreen: Component = observer(function AddAddressScreen(props) {
  
    const { addressStore } = useStores()
    const navigation = useNavigation()
    const backNav = () => navigation.navigate('logged_in_path', {screen: 'address'})

    
    const [errorMsg, setErrorMsg] = React.useState(null)
    const [addressType, setAddressType] = React.useState(null)

    var action = props['route'].params['action']


    useEffect(()=>{
        if(action == "update"){
            setAddressType(addressStore.selectedAddres.addressType)
        }
    }, [])

    const addressOperation = async () => {
        
        await addressStore.updateSelectedAddressType(addressType)
        if(addressStore.checkMandatory()){
            if(action == "add"){
                addAddrees()
            } else {
                updateAddrees()
            }

        } else if(!addressStore.selectedAddres.addressType){
            setErrorMsg("Please pick an address type")
        } else {
            setErrorMsg("Some error occured")
        }
    }

    const addAddrees = () => {
        const api = new ApiUser ()
        api.addressAdd(addressStore.selectedAddres).then(res => { 
            if(res['kind'] == "ok"){
                getUserAddress()
            }    
        })
    }


    const updateAddrees = () => {
        const api = new ApiUser ()
        api.addressUpdate(addressStore.selectedAddres, addressStore.selectedAddres.addressId).then(res => { 
            if(res['kind'] == "ok"){
                getUserAddress()
            }    
        })
    }


    const getUserAddress = () => {
        const api = new ApiUser ()
        api.getAllAddress().then(res => { 
            if(res['kind'] == "ok"){
                addressStore.create(res['data'])
                backNav()
            }
        })
    }

  
    const getAddressTypeText = (type) => {
        var addressType = type.replace(/([A-Z])/g, ' $1').trim()
        addressType = addressType.split(" ")[0]
        return addressType
    }

    const handleAddressChange = (address, addressDetails, latitude, longitude) => {
        const selectedAddressDetails = {
            "action": action,
            "addressId": addressStore.selectedAddres.addressId ?? 0,
            "country": addressDetails.country,
            "city": addressDetails.city,
            "state": addressDetails.state,
            "district": addressDetails.district,
            "locality": addressDetails.locality,
            "zipCode": addressDetails.pincode,
            "address2": address,
            "latitude": latitude,
            "longitude": longitude,
            "addressType": addressType
        };

        if (addressStore.selectedAddres.latitude != latitude || addressStore.selectedAddres.longitude != longitude) {
            addressStore.updateSelectedAddress(selectedAddressDetails)
        }
    }

    return (
        <Screen preset="scroll" headerTitle="Manage Addresses" showheaderTitle={true} showBackButton={true}>

            <View style={CONTAINER}>
                <View style={{ width: "100%" }}>
                    <GMapComponent
                        onAddressChange={handleAddressChange}
                        selectedAddress={addressStore.selectedAddres} />
                </View>
                <View style={{flexDirection: 'row', margin: 15, flexWrap: 'wrap'}}>
                    {
                        addressStore.getAddressTypes().map((type)=>{
                            return (
                                <TouchableOpacity onPress={() => setAddressType(type)}
                                    key={type}
                                    style={{backgroundColor: addressType == type ? "#e8f8ff" : color.line, 
                                        borderColor: addressType == type ? color.primary : color.line, justifyContent: 'center', 
                                        borderWidth: 1, borderRadius: 10, margin: 5, minWidth: "20%", height: 40}}>
                                    <Text style={{textAlign: 'center', fontSize: 12, paddingHorizontal: 10}}>{getAddressTypeText(type)}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>

                <View style={{width: "100%"}}>

                    <TextField label='House/ Street/ Block No.' defaultValue={addressStore.selectedAddres.address1} getText={text => addressStore.updateSelectedAddressAddress1(text)} 
                                                            rootStyle={{marginBottom: 10}} required={true} showError={errorMsg ? true: false}></TextField>
                    <TextField label='Locality/ City'  defaultValue={addressStore.selectedAddres.address2} getText={text => addressStore.updateSelectedAddressAddress2(text)} 
                                                            rootStyle={{marginBottom: 10}}></TextField>
                    <TextField label='Landmark'  defaultValue={addressStore.selectedAddres.landmark} getText={text => addressStore.updateSelectedAddressLandmark(text)} 
                                                            rootStyle={{marginBottom: 10}} required={true} showError={errorMsg ? true: false}></TextField>
                    <TextField label='ZIP Code'  defaultValue={addressStore.selectedAddres.zipCode} getText={text => addressStore.updateSelectedAddressZipCode(text)} 
                                                            rootStyle={{marginBottom: 10}} required={true} showError={errorMsg ? true: false} keyboardType='numeric'></TextField>
                </View>


                { errorMsg ? 
                    <Text style={{fontSize: 11, color: color.ananthamOrange, textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                        {errorMsg}
                    </Text> : null
                }

                <View style={{flexDirection:'row', paddingHorizontal:10, marginTop: 15}}>
                    <Button
                        preset="secondary"
                        style={{ flexGrow:1, marginRight: 10, minWidth: 100 }}
                        text='Cancel'
                        onPress={() => backNav()} />
                    <Button
                        preset="primary"
                        style={{ flexGrow:1, marginLeft: 10, minWidth: 100 }}
                        text='Save'
                        onPress={() => addressOperation()} />
                </View>
                
            </View>

        </Screen>
    )
})


const CONTAINER: ViewStyle = {
    flex: 1,
  justifyContent: "space-between",
    height: '100%',
    alignItems: "center",
  padding: 20,
}

  

  


