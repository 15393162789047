import React, { FunctionComponent as Component, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { View, Platform, Image } from "react-native";
import { PopupMessage } from "./components/popup-message/popup-message";
import { ApiPayment } from "../../services/api/api-methods/api-payment";
import {
    CFErrorResponse,
    CFPaymentGatewayService,
} from 'react-native-cashfree-pg-sdk';
import {
    CFDropCheckoutPayment,
    CFEnvironment,
    CFPaymentComponentBuilder,
    CFPaymentModes,
    CFSession,
    CFThemeBuilder,
} from 'cashfree-pg-api-contract';
declare var Cashfree: any;

const { CASHFREE_URL } = require("../../config/env")
const { IS_DEV } = require("../../config/env")

export const PaymentScreen: Component = observer(function PaymentScreen() {

    const { checkoutStore, cartStore } = useStores()
    const preloader = require('../../../assets/preloader1.gif')
    const [showMessage, setShowMessage] =  React.useState(false)
    

    useEffect(() => {   

        initiatePayment()

        if(Platform.OS == 'web'){
            Cashfree = ""
            const head = document.querySelector("head");
            const script = document.createElement("script");
            script.setAttribute("src", CASHFREE_URL);
            head.appendChild(script);

            return () => {
                head.removeChild(script);
            };
        }
    }, [])

    const initiatePayment = async () =>{

        checkoutStore.updateTxStatus('') 
        if(Platform.OS == 'web'){
            
            const url = new URL(window.location.href);
            // Check for payment redirection
            if(url.pathname.includes('payment_status')){
                const orderID = url.searchParams.get('order_id')
                const api = new ApiPayment()
                api.orderPaymentStatus(orderID).then(res => { 
                    var data = {
                        orderId: "",
                        referenceId: "",
                        signature: "",
                        txStatus: 'PENDING'
                    }
                    if(res["data"] === "PAID"){
                        data['txStatus'] = "SUCCESS"
                        cartStore.resetInventoryItems()
                    }
                    else if(res["data"] === "FAILED"){
                        data['txStatus'] = "FAILED"
                    }
                    else{
                        data['txStatus'] = "PENDING"
                    }
                    checkoutStore.updatePaymentModel(data)
                    setShowMessage(true)
                })

            } else {
                // Waiting for Cashfree js to download
                let jsDownloadAttempts = 10;
                let timerId = setInterval(() => {
                    if(Cashfree != ""){
                        // cartStore.resetInventoryItems()
                        const cashfree = new Cashfree(checkoutStore.cashFreePaymentPayload.token);
                        cashfree.redirect();
                        clearInterval(timerId);
                    }
                    if(jsDownloadAttempts < 0){
                        clearInterval(timerId);
                        var data = {
                            orderId: "",
                            referenceId: "",
                            signature: "",
                            txStatus: 'FAILED'
                        }
                        checkoutStore.updatePaymentModel(data)
                        setShowMessage(true)
                    }
                    jsDownloadAttempts -= 1
                }, 1000);
            }
        }

        if(Platform.OS != 'web'){
            CFPaymentGatewayService.setCallback({
                onVerify(orderID: string): void {
                    var data = {
                        orderId: orderID,
                        referenceId: "",
                        signature: "",
                        txStatus: 'SUCCESS'
                    }
                    cartStore.resetInventoryItems()
                    checkoutStore.updatePaymentModel(data)
                    setShowMessage(true)
                },
                onError(error: CFErrorResponse, orderID: string): void {
                    if (error["code"] == "payment_failed"){
                        var data = {
                            orderId: orderID,
                            referenceId: "",
                            signature: "",
                            txStatus: 'FAILED'
                        }
                        checkoutStore.updatePaymentModel(data)
                        setShowMessage(true)
                    } else {
                        var data = {
                            orderId: orderID,
                            referenceId: "",
                            signature: "",
                            txStatus: 'CANCELLED'
                        }
                        checkoutStore.updatePaymentModel(data)
                        setShowMessage(true)
                    }
                },
            });
        }

    }

    const CashFree = () => {
            if(Platform.OS == 'web'){
                return(
                    <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center', flex: 1}}>
                        <Image style={{width:40,height:40}} source={preloader}/>
                    </View>
                )
            } else {
                const session = new CFSession(
                    checkoutStore.cashFreePaymentPayload.token,
                    checkoutStore.paymentNumber,
                    IS_DEV ? CFEnvironment.SANDBOX : CFEnvironment.PRODUCTION
                );

                const paymentModes = new CFPaymentComponentBuilder()
                .add(CFPaymentModes.CARD)
                .add(CFPaymentModes.UPI)
                .add(CFPaymentModes.NB)
                .add(CFPaymentModes.WALLET)
                .build();

                const theme = new CFThemeBuilder()
                    .setNavigationBarBackgroundColor('#E64A19')
                    .setNavigationBarTextColor('#FFFFFF')
                    .setButtonBackgroundColor('#FFC107')
                    .setButtonTextColor('#FFFFFF')
                    .setPrimaryTextColor('#212121')
                    .setSecondaryTextColor('#757575')
                    .build();
                const dropPayment = new CFDropCheckoutPayment(session, paymentModes, theme);
                CFPaymentGatewayService.doPayment(dropPayment);
            }
    }

    const getTransactionMessage = (textStatus) => {
        var txtMsg = ""
        var txtSubMsg = ""
        if(textStatus == "SUCCESS"){
            txtMsg = "Transaction Successful"
            txtSubMsg = "Thank you for shopping from Ellam"
        } else if(textStatus == "FAILED"){
            txtMsg = "Transaction Failed"
            txtSubMsg = "Please try once again"
        } else if(textStatus == "CANCELLED"){
            txtMsg = "Transaction Cancelled"
            txtSubMsg = "Please try once again"
        } else {
            txtMsg = "Transaction Pending"
            txtSubMsg = "Please wait while we confirm your order"
        }
        return [txtMsg, txtSubMsg]
    }

    return (
        <View style={{flex: 1}}>
            { showMessage ? 
                <PopupMessage 
                    display={showMessage} 
                    close={()=>setShowMessage(false)} 
                    message={getTransactionMessage(checkoutStore.txStatus)[0]} 
                    navigationMessage={getTransactionMessage(checkoutStore.txStatus)[1]} 
                    nextNavigationPath={checkoutStore.txStatus == 'SUCCESS' ? {stack: 'home', screen: 'dashboard'} : {stack: 'home', screen: 'cart'}}
                    type= {checkoutStore.txStatus}
                ></PopupMessage> 
                :
                <CashFree></CashFree>
            }
      </View>
    );
})