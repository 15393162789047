module.exports = {
  API_URL: "https://prod.ellam.in/",
  GOOGLE_MAPS_API_KEY: "AIzaSyDi8neoGGWKq3GOTbnFzDtXlHdnIqXJX68",
  GOOGLE_MAPS_API_KEY_WEB: "AIzaSyAmei5bW6nkB4TQU6A1UbLQ2KAo5Kkv5r8",
  CASHFREE_APP_ID: "641622412d83d808458f64cf426146",
  CASHFREE_URL: "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js",
  ENVIRONMENT: "",
  FREECHARGE_URL: "https://checkout.freecharge.in/api/v1/co/pay/init",
  AGREEPAY_URL: "https://biz.aggrepaypayments.com/v2/paymentrequest",
  AGREEPAY_SDK_URL: "https://biz.aggrepaypayments.com",
  AGREEPAY_SALT: '06e9f0c5d5c7545c363835b8500c9a2c0ad9449e',
  T_AND_C_URL: 'https://www.ellam.in/terms-and-conditions',
  IS_DEV: false,
  PLAYSTORE_URL: 'https://play.google.com/store/apps/details?id=com.ellam.consumer&hl=en&gl=US&pli=1'
}