import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"
import { url } from "../api-list"


// models

import { signup_details } from "../../../models/app-model/signup_model"
import { save } from "../../../utils/storage";



// User signip
  

export class ApiSignin {



  async generateOtp(mobileNumber, email) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const param = {"phoneNumber": mobileNumber, "email": email, "userType": "Consumer"}

    const response: ApiResponse<any> = await apiService.post(url.generateOtp,param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      if(response.data['statusCode'] = 200){
        signup_details['otpReferenceId'] = response.data['otpReferenceId']
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
  
  async phoneNumberStatus(phoneNumber) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    const param = {"phoneNumber": phoneNumber, "userType": "Consumer"}

    const response: ApiResponse<any> = await apiService.post(url.phoneNumberStatus,param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async signIn(param) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const response: ApiResponse<any> = await apiService.post(url.signIn, param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data){
        await save("accessToken", response.data['accessToken'])
        await save("refreshToken", response.data['refreshToken'])
        await save("deviceId", response.data['deviceId'])
      }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async resendOtp(data) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    const param = {
      "otpReferenceId": data["otpRefId"],
      "target": data["mobileNumber"],
      "targetType": "sms",
      "action": "verify"
    }
    const response: ApiResponse<any> = await apiService.post(url.resendOtp, param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



}