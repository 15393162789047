import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, Text, TouchableOpacity, ScrollView } from "react-native"
import { color } from "../../../../theme";
import { AddressPickerProps } from "./address-picker.props";
// import { AddressCard } from "../address-card/address-card";
import { useStores } from "../../../../models";
import { Button } from "../../../../components";
import { useNavigation } from "@react-navigation/native";
import { AddressCard } from "../address-card/address-card";


/**
 * Address picker
 */
export const AddressPicker: Component<AddressPickerProps> = props => {
  const {
    deliveryPincode,
    setModalVisibility,
    addressCount,
    setDeliveryLoaction,
    deliverablePincodes
  } = props
  

  const { addressStore } = useStores()

  const navigation = useNavigation()
  const navAddAddress = () => navigation.navigate('logged_in_path', {screen: "address"})


  return (
    <TouchableOpacity style={MAIN_CONTAINER} onPress={()=>setModalVisibility(false)} activeOpacity={1}>
        <TouchableOpacity style={POPUP_CARD} activeOpacity={1}> 
            <View style={{width:'100%'}}>
                <View>
                    <View style={CONTAINER_ROW}>
                        <Text style={{fontWeight: 'bold'}}>Delivery Address Details</Text>
                    </View>
                    <View style={CONTAINER_ROW}>
                        <Text style={{fontSize: 14, color: color.dim}}>Please choose your prefered delivery address</Text>
                    </View>
                    <View style={{width:'100%'}}>
                        { addressStore.getOtherDeliveryAddress().length > 0 ?
                            <ScrollView style={{width:'100%', marginTop: 10}}>
                                { 
                                    addressStore.getOtherDeliveryAddress().map((address, index) => {
                                        return (
                                            <AddressCard 
                                                onPress={() => setDeliveryLoaction(address)} 
                                                addressType={address.addressType}
                                                address={address}  
                                                deliverablePincodes={deliverablePincodes}
                                            ></AddressCard> 
                                        )
                                    })
                                }
                            </ScrollView> : 
                            <Text style={{marginVertical: 50, alignSelf: 'center', fontWeight: 'bold', textAlign: 'center', color: color.ananthamOrange}}>
                                No Address Found. Please update the address.
                            </Text>
                        }
                    </View>
                </View>
            </View>
            {
                addressCount != 4?
                    <View style={{flexDirection:'row', paddingTop:20, justifyContent:'space-between'}}>
                        <Button
                            preset="primary"
                            style={{ width:"100%" }}
                            text='ADD NEW ADDRESS'
                            onPress={() => [setModalVisibility(false), navAddAddress()]}
                        />
                    </View>
                : null
            }
        </TouchableOpacity>
    </TouchableOpacity>
    
  )
}




const MAIN_CONTAINER: ViewStyle = {
    flex:1,
    width:'100%',
    height:'100%',
    position:'absolute',
    backgroundColor: '#0D1F3C80',
    justifyContent:'flex-end',
    margin: 0,
    padding: 0,
}

const POPUP_CARD: ViewStyle = {
    width:'100%',
    backgroundColor: color.white,
    padding: 20,
    paddingTop: 10,
    paddingVertical: 20,
    borderTopLeftRadius :10,
    borderTopRightRadius :10
}

const CONTAINER_ROW: ViewStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginVertical: 5
}