import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "./api-problem"
import { Api } from "./api"
import { url } from "./api-list"


// models

import { signup_details } from "../../models/app-model/signup_model"
import { shared, setStorage } from "../../models/app-model/shared_model"
import { app_variables } from "../../models/app-model/application_model"



// User signip
  

export class ApiSignin {



  async generateOtp() {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    // const response = { 
    //   data : {
    //     kind : "ok"
    //   }
    // }

    const get_url = url.generateOtp + signup_details['phoneNumber']
    const response: ApiResponse<any> = await apiService.get(get_url)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
        signup_details['otpReferenceId'] = response.data['otpReferenceId']
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async login() {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    const param = {
        "otpRefId": signup_details["otpReferenceId"],
        "otp": signup_details["otp"],
        "mobileNumber": signup_details["phoneNumber"]
      }

    // const response = { 
    //   data : {
    //     kind : "ok"
    //   }
    // }

    const response: ApiResponse<any> = await apiService.post(url.login,param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data){
        app_variables['authToken'] = response.data['accessToken']
        setStorage('authToken',response.data['accessToken'])

        let token = response.data['accessToken']
        let input = token.split('.')[1]
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    
        let str = input.replace(/=+$/, '');
        let output = '';
    
        if (str.length % 4 == 1) {
          throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
        }
        for (let bc = 0, bs = 0, buffer, i = 0;
          buffer = str.charAt(i++);
    
          ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
            bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
        ) {
          buffer = chars.indexOf(buffer);
        }
        let output_object = JSON.parse(output)
        app_variables.businessId = output_object['business']
        setStorage('authToken',response.data['accessToken'])

      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  
  async businessStatus() {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const url = api.generateUrl('businessStatus')

    const response: ApiResponse<any> = await apiService.get(url)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      let resData = response.data
      if(resData){
        if(resData['businessName'] == null){
          // shared.WelcomeScreenState = 1
          // setStorage('WelcomeScreenState',1)
        }
        if(resData['emailVerified'] == false){
          shared.WelcomeScreenState = 1
          setStorage('WelcomeScreenState',1)
        }
        else if(resData['businessVerified'] == "PendingDetails") {
          shared.WelcomeScreenState = 2
          setStorage('WelcomeScreenState',2)
        }
        else if(resData['businessVerified'] == "Terminated") {
          shared.WelcomeScreenState = 4
          setStorage('WelcomeScreenState',4)
        }
        else if(resData['businessVerified'] == "Active") {
          shared.WelcomeScreenState = 3
          setStorage('WelcomeScreenState',3)
        }
        // shared.WelcomeScreenState = 1
        // setStorage('WelcomeScreenState',1)
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async deleteAcount() {

    const api = new Api()
    const apiService = await api.setHeader()
    const apiUrl = url.deleteAccount
    const response: ApiResponse<any> = await apiService.delete(apiUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      if (response.data) {
        return { kind: "ok", data: response.data }
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
}