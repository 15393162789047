import React, { FunctionComponent as Component, useEffect } from "react"
import { View, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { color, styles as mainStyles } from "../../theme";
import { PermissionPopupMessageProps } from "./permission-popup-message.props";
import { Icon, Button } from "..";
import {  Card, Text, Modal } from "@ui-kitten/components";



const CENTER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}


const LIGHTTEXT: TextStyle = {
  color: color.dim,
  fontSize: 13,
  marginHorizontal: 7,
  marginVertical: 1
}


const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}


export const PermissionPopupMessage: Component<any> = props => {
  const {
    message,
    subMessage,
    display,
    close,
    action,
    actionText
  } = props


  const [modalVisible, setModalVisibility] = React.useState(false);

  useEffect(() => {
    setModalVisibility(display)
  }, [display]);

  return (
    
    <View>

      <Modal visible={modalVisible}
        backdropStyle={BACKDROP}
        onBackdropPress={() => setModalVisibility(false)}
        style={{padding: 20}}>
        
        <Card>

              <View style={CENTER}>
                  <Icon icon={"warning_icon"} style={{width:60, height:60, margin: 15}}></Icon>
                  <Text style={[mainStyles.sub_title, {textAlign: 'center'}]}>{message}</Text>
              </View>

              <Text style={[LIGHTTEXT, {textAlign: 'center'}]}>{subMessage}</Text>

              <View style={[LIGHTTEXT, {flexDirection: 'row', marginTop: 10, justifyContent: "center"}]}>

              <Button
                  // preset="secondary_sm"
                  style={{margin: 10}}
                  text="Close"
                  onPress={close}/>

                { actionText ?
                  <Button
                    // preset="primary_sm"
                    style={{margin: 10}}
                    text={actionText}
                    onPress={action}/>  : null
                } 


              </View>
        </Card>
      </Modal>

  </View>
  
  );
};
