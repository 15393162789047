import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ScrollView, Text, Image } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Screen, Button } from "../../components"
import { color } from "../../theme"
import { OrderCard } from "./components/order-card/order-card"
import { ApiServicesList } from "../../services/api"
import { TouchableOpacity } from "react-native-gesture-handler"
import { useStores } from "../../models";

export const OrderListScreen: Component = observer(function OrderListScreen() {
  
  const { orderListStore, orderStore, userStore } = useStores()

  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(30);
  const [showLoadMore, setShowLoadMore] = React.useState(true);
  const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false)
  
  const loaderImage = require('../../../assets/preloader1.gif')
  const offeringImage = require('../../../assets/offering.png')

  const navigation = useNavigation()

  useEffect(()=>{
    setPageNumber(0);
    setIsLoading(true)
    orderListStore.create([])
    allOrderApi()
  }, [])

  const loadMore = ()=> {
    setShowLoadMoreLoading(true)
    setPageNumber(pageNumber+1);
    allOrderApi()
  }

  const navOrderDetails = (order) => {
    orderStore.create(JSON.parse(JSON.stringify(order)))
    navigation.navigate("logged_in_path", {screen: "order_details"})
  }

  const allOrderApi = () => {
    let limit = pageSize;
    let offset = pageNumber*pageSize;

    const api = new ApiServicesList ()
    api.allOrder(limit, offset).then(res => { 
      setIsLoading(false)
      setShowLoadMoreLoading(false)
      if(res['kind'] == "ok"){
        if(res['data']){
          orderListStore.create(orderListStore.orderList.concat(res['data']))
          if(res['data'] && res['data'].length < pageSize){
            setShowLoadMore(false)
          }
        }
      } else if (res.kind == "unauthorized"){
        userStore.reset()
        navigation.navigate("account")               
      }
    })
  }


  return (
      <Screen preset="fixed" headerTitle="Order History" showheaderTitle={true} showBackButton={true}>
        
        <View style={{flex: 1, paddingVertical:20}}>
            {
              isLoading?
                <View style={{flex:1,justifyContent:'center',alignItems:'center',}}>
                  <Image style={{width:40,height:40}} source={loaderImage}/>
                </View>
              :
              <ScrollView style={{paddingHorizontal: 10}}>
                { orderListStore.orderList.length ?
                      orderListStore.orderList.map((element,key) =>
                        <TouchableOpacity key={key} onPress={()=>navOrderDetails(element)}>
                          <OrderCard orderObject={JSON.parse(JSON.stringify(element))} itemsCount={element.items.length}></OrderCard>
                        </TouchableOpacity>
                      )
                    :
                    <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center'}}>
                        <Image style={{width:40,height:40,marginBottom:10}} source={offeringImage}/>
                        <Text style={{color:color.dim}}>
                            No Orders
                        </Text>
                    </View>
                }
                {
                  showLoadMoreLoading?
                    <View style={{justifyContent:'center',alignItems:'center', marginTop: 20}}>
                      <Image style={{width:40,height:40}} source={loaderImage}/>
                    </View>
                    :
                    <View>
                      { orderListStore.orderList.length && showLoadMore ?
                        <View style={{flexDirection: 'row', alignSelf: 'center', marginVertical: 15}}>
                            <Button
                                preset="primary"
                                text="Load More"
                                onPress={()=>loadMore()}/> 
                        </View>
                        : null
                      }
                    </View>
                }
              </ScrollView>
            }
        </View>

      </Screen>
  )
})




