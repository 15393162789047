import { types } from "mobx-state-tree";

export const RegisterBusinessModel = types
.model()
.props({ 
    otp: types.optional(types.string, ""),
    otpReferenceId: types.optional(types.string, ""),
    fullName: types.optional(types.string, ""),
    phoneNumber: types.optional(types.string, ""),
    businessName: types.optional(types.string, ""),
    businessLocation: types.optional(types.string, ""),
    businessTypeId: types.optional(types.number, 0),
    businessTypeName: types.optional(types.string, ""),
    locationId: types.optional(types.number, 0),
})
.actions(self=>({
    create(registerBusiness){
        self.otp = registerBusiness.otp
        self.otpReferenceId = registerBusiness.otpReferenceId
        self.fullName = registerBusiness.fullName
        self.phoneNumber = registerBusiness.phoneNumber
        self.businessName = registerBusiness.businessName
        self.businessLocation = registerBusiness.businessLocation
        self.businessTypeId = registerBusiness.businessTypeId
        self.locationId = registerBusiness.locationId
        self.businessTypeName = registerBusiness.businessTypeName
    },
    updateOtp(otp){
        self.otp = otp
    },
    updateOtpReferenceId(otpReferenceId){
        self.otpReferenceId = otpReferenceId
    },
    updateFullName(fullName){
        self.fullName = fullName
    },
    updatePhoneNumber(phoneNumber){
        self.phoneNumber = phoneNumber
    },
    updateBusinessName(businessName){
        self.businessName = businessName
    },
    updateBusinessLocation(businessLocation){
        self.businessLocation = businessLocation
    },
    updateBusinessTypeId(businessTypeId){
        self.businessTypeId = businessTypeId
    },
    updateLocationId(locationId){
        self.locationId = locationId
    },
    updateBusinessTypeName(businessTypeName){
        self.businessTypeName = businessTypeName
    }
}))
.views(self=>({
    getRegisterModel(){
        return {
            otp: self.otp,
            otpReferenceId: self.otpReferenceId,
            fullName: self.fullName,
            phoneNumber: self.phoneNumber,
            businessName: self.businessName,
            businessLocation: self.businessLocation,
            businessTypeId: self.businessTypeId,
            locationId: self.locationId
        }
    },
    getLoginModel(){
        return {
            otp: self.otp,
            otpRefId: self.otpReferenceId,
            mobileNumber: self.phoneNumber,
        }
    }
}))


const IdentityInfo = types
.model()
.props({
    gstNumber: types.optional(types.string, ""),
    licenseNumber: types.optional(types.string, ""),
    licenseValidFrom: types.optional(types.string, ""),
    licenseValidTo: types.optional(types.string, ""),
    gstNumberVerified: types.optional(types.boolean, false),
    licenseVerified: types.optional(types.boolean, false),
    businessCategory: types.optional(types.string, ""),
})

const BusinessInfo = types
.model()
.props({
    id: types.optional(types.number, 0),
    businessName: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    middleName: types.maybeNull(types.optional(types.string, "")),
    lastName: types.optional(types.string, ""),
    fullName: types.optional(types.string, ""),
    businessLocation: types.optional(types.string, ""),
    operationLocationId: types.optional(types.number, 0),
    operationLocationName: types.optional(types.string, ""),
    addressLaneOne: types.optional(types.string, ""),
    addressLaneTwo: types.optional(types.string, ""),
    landmark: types.optional(types.string, ""),
    district: types.optional(types.string, ""),
    zipCode: types.optional(types.string, ""),
    email: types.maybeNull(types.optional(types.string, "")),
    aadharHash: types.maybeNull(types.optional(types.string, "")),
    panCardHash: types.maybeNull((types.optional(types.string, ""))),
    businessMobile: types.optional(types.string, ""),
    aadharVerified: types.optional(types.boolean, false),
    panCardVerified: types.optional(types.boolean, false),
    emailVerified: types.optional(types.boolean, false),
    mobileVerified: types.optional(types.boolean, false),
    website: types.maybeNull(types.optional(types.string, "")),
    businessLocationId: types.optional(types.number, 0),
    businessAvatarUri: types.maybeNull(types.optional(types.string, "")),
    businessVerified: types.optional(types.string, "")
})



export const BusinessDetailsModel = types
.model()
.props({ 
    identityInfo: types.optional(IdentityInfo, {}),
    businessInfo: types.optional(BusinessInfo, {})
})
.actions(self=>({
    create(details){
        this.createIdentityInfo(details.identityInfo)
        this.createBusinessInfo(details.businessInfo)
    },
    createIdentityInfo(identityInfo){
        self.identityInfo.gstNumber = identityInfo.gstNumber
        self.identityInfo.licenseNumber = identityInfo.licenseNumber
        self.identityInfo.licenseValidFrom = identityInfo.licenseValidFrom
        self.identityInfo.licenseValidTo = identityInfo.licenseValidTo
        self.identityInfo.gstNumberVerified = identityInfo.gstNumberVerified
        self.identityInfo.licenseVerified = identityInfo.licenseVerified
        self.identityInfo.businessCategory = identityInfo.businessCategory
    },
    createBusinessInfo(businessInfo){
        self.businessInfo.id = businessInfo.id
        self.businessInfo.businessName = businessInfo.businessName
        self.businessInfo.firstName = businessInfo.firstName,
        self.businessInfo.middleName = businessInfo.middleName,
        self.businessInfo.lastName = businessInfo.lastName,
        self.businessInfo.fullName = businessInfo.firstName + businessInfo.middleName + businessInfo.lastName
        self.businessInfo.businessLocation = businessInfo.businessLocation,
        self.businessInfo.operationLocationId = businessInfo.operationLocationId
        self.businessInfo.operationLocationName = businessInfo.operationLocationName,
        self.businessInfo.businessLocationId = businessInfo.businessLocationId
        self.businessInfo.emailVerified = businessInfo.emailVerified
        self.businessInfo.addressLaneOne = businessInfo.addressLaneOne
        self.businessInfo.addressLaneTwo = businessInfo.addressLaneTwo
        self.businessInfo.landmark = businessInfo.landmark
        self.businessInfo.district = businessInfo.district
        self.businessInfo.zipCode = businessInfo.zipCode
        self.businessInfo.email = businessInfo.email
        self.businessInfo.aadharHash = businessInfo.aadharHash
        self.businessInfo.panCardHash = businessInfo.panCardHash
        self.businessInfo.businessMobile = businessInfo.businessMobile
        self.businessInfo.aadharVerified = businessInfo.aadharVerified
        self.businessInfo.panCardVerified = businessInfo.panCardVerified
        self.businessInfo.mobileVerified = businessInfo.mobileVerified
        self.businessInfo.website = businessInfo.website
        self.businessInfo.businessAvatarUri = businessInfo.businessAvatarUri
        self.businessInfo.businessVerified = businessInfo.businessVerified
    },
    updateBusinessLocationId(businessLocationId){
        self.businessInfo.businessLocationId = businessLocationId
    },
    updateEmailVerified(emailVerified){
        self.businessInfo.emailVerified = emailVerified
    },
    updateAddressLaneOne(addressLaneOne){
        self.businessInfo.addressLaneOne = addressLaneOne
    },
    updateAddressLaneTwo(addressLaneTwo){
        self.businessInfo.addressLaneTwo = addressLaneTwo
    },
    updateLandmark(landmark){
        self.businessInfo.landmark = landmark
    },
    updateDistrict(district){
        self.businessInfo.district = district
    },
    updateZipCode(zipCode){
        self.businessInfo.zipCode = zipCode
    },
    updateEmail(email){
        self.businessInfo.email = email
    },
    updateAadharHash(aadharHash){
        self.businessInfo.aadharHash = aadharHash
    },
    updatePanCardHash(panCardHash){
        self.businessInfo.panCardHash = panCardHash
    },
    updateBusinessMobile(businessMobile){
        self.businessInfo.businessMobile = businessMobile
    },
    updateAadharVerified(aadharVerified){
        self.businessInfo.aadharVerified = aadharVerified
    },
    updatePanCardVerified(panCardVerified){
        self.businessInfo.panCardVerified = panCardVerified
    },
    updateMobileVerified(mobileVerified){
        self.businessInfo.mobileVerified = mobileVerified
    },
    updateWebsite(website){
        self.businessInfo.website = website
    },
    updateGstNumber(gstNumber){
        self.identityInfo.gstNumber = gstNumber
    },
    updateLicenseNumber(licenseNumber){
        self.identityInfo.licenseNumber = licenseNumber
    },
    updateLicenseValidFrom(gstNumber){
        self.identityInfo.gstNumber = gstNumber
    },
    updateLicenseValidTo(licenseValidTo){
        self.identityInfo.licenseValidTo = licenseValidTo
    },
    updateGstNumberVerified(gstNumberVerified){
        self.identityInfo.gstNumberVerified = gstNumberVerified
    },
    updateLicenseVerified(licenseVerified){
        self.identityInfo.licenseVerified = licenseVerified
    },
    updateBusinessCategory(businessCategory){
        self.identityInfo.businessCategory = businessCategory
    },
    reset(){
        this.resetIndentityInfo()
        this.resetBusinessInfo()
    },
    resetIndentityInfo(){
        self.identityInfo.gstNumber =  ""
        self.identityInfo.licenseNumber =  ""
        self.identityInfo.licenseValidFrom =  ""
        self.identityInfo.licenseValidTo =  ""
        self.identityInfo.gstNumberVerified =  false
        self.identityInfo.licenseVerified =  false
        self.identityInfo.businessCategory =  ""
    },
    resetBusinessInfo(){
        self.businessInfo.id = 0
        self.businessInfo.businessName = ""
        self.businessInfo.firstName = ""
        self.businessInfo.middleName = ""
        self.businessInfo.lastName = ""
        self.businessInfo.fullName = ""
        self.businessInfo.businessLocation = ""
        self.businessInfo.operationLocationId = 0
        self.businessInfo.operationLocationName = ""
        self.businessInfo.businessLocationId =  0
        self.businessInfo.emailVerified =  false
        self.businessInfo.addressLaneOne =  ""
        self.businessInfo.addressLaneTwo =  ""
        self.businessInfo.landmark = "" 
        self.businessInfo.district =  ""
        self.businessInfo.zipCode =  ""
        self.businessInfo.email =  ""
        self.businessInfo.aadharHash =  ""
        self.businessInfo.panCardHash =  ""
        self.businessInfo.businessMobile =  ""
        self.businessInfo.aadharVerified =  false
        self.businessInfo.panCardVerified =  false
        self.businessInfo.mobileVerified =  false
        self.businessInfo.website =  ""
        self.businessInfo.businessVerified = ""
        self.businessInfo.businessAvatarUri = ""
    },
}))
.views(self=>({
    getTemplePostModel(){
        return {
                addressLaneOne: self.businessInfo.addressLaneOne,
                addressLaneTwo: self.businessInfo.addressLaneTwo,
                landmark: self.businessInfo.landmark,
                district: self.businessInfo.district,
                zipCode: self.businessInfo.zipCode,
                email: self.businessInfo.email,
                aadharHash: self.businessInfo.aadharHash,
                panCardHash: self.businessInfo.panCardHash,
                businessMobile: self.businessInfo.businessMobile,
                aadharVerified: self.businessInfo.aadharVerified,
                panCardVerified: self.businessInfo.panCardVerified,
                emailVerified: self.businessInfo.emailVerified,
                mobileVerified: self.businessInfo.mobileVerified,
                website: self.businessInfo.website,
                operationLocationId: self.businessInfo.operationLocationId
        }
    }
}))


