import React, { FunctionComponent as Component, useEffect } from "react"
import { Text, View, ViewStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen } from "../../components";
import { Ionicons } from "@expo/vector-icons";
import { color, styles } from "../../theme";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useStores } from "../../models";
import { SigninScreen } from "../onboarding-screen/signin-screen";



export const AccountScreen: Component = observer(function AccountScreen() {
  
    const { userStore, orderListStore } = useStores()

    const navigation = useNavigation()

    const navProfile = () => navigation.navigate('logged_in_path', {screen: "profile"})
    const navOrders = () => {orderListStore.reset(); navigation.navigate('logged_in_path', {screen: "order_list"})}
    const navHelpAndSupport = () => navigation.navigate('logged_in_path', {screen: "help_and_support"})
    

    useEffect(()=>{
        orderListStore.reset()
    }, [])


    if(userStore['isSignIn']){
        return (
            <Screen preset="fixed" headerTitle="Account" showheaderTitle={true} showBackButton={false}>
                <TouchableOpacity onPress={navProfile}>
                    <View style={{...menuContainer}}>
                        <Ionicons
                            name={'ios-person'}
                            style={{ ...menuIconView,...menuIconText}}
                            />
                        <Text style={{...styles.title_primary, textAlign:'left'}}>Profile</Text>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={navOrders}>
                    <View style={{...menuContainer}}>
                        <Ionicons
                            name={'ios-albums'}
                            style={{ ...menuIconView,...menuIconText}}
                            />
                        <Text style={{...styles.title_primary, textAlign:'left'}}>Orders</Text>
        
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={navHelpAndSupport}>
                    <View style={{...menuContainer}}>
                        <Ionicons
                            name={'ios-people'}
                            style={{ ...menuIconView,...menuIconText}}
                            />
                        <Text style={{...styles.title_primary, textAlign:'left'}}>Help & Support</Text>
                    </View>
                </TouchableOpacity>
                
            </Screen>
        )
    } else {
        return ( <SigninScreen></SigninScreen> )
    }

})

  

const menuContainer: ViewStyle = { 
    flexDirection:'row',
    alignItems:'center',
    borderBottomColor:color.line,
    borderBottomWidth:1
}
  
const menuIconView: ViewStyle = {
    padding:20
}

const menuIconText: TextStyle = {
    fontSize:24,
    textAlign:'center',
    color:color.primary
}
