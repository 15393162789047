import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { BusinessLocationsModel } from "../stores/business-store/business-locations";
import { BusinessTypesModel } from "../stores/business-store/business-types";
import { RegisterBusinessModel, BusinessDetailsModel } from "../stores/business-store/business-details";
import { Loader } from "../stores/shared-store.ts/loader";
import { Toast } from "../stores/shared-store.ts/toast";
import { ServiceListModel, ServiceModel } from "../stores/service-store/service-list";
import { UserModel } from "../stores/user-store/user-details";
import { CartModel } from "../stores/cart-store/cart-list";
import { CheckoutModel } from "../stores/payment-store/checkout";
import { OrderPaymentModel } from "../stores/payment-store/order-payment";
import { InventoryModel } from "../stores/inventory-store/inventory-model";
import { NetworkModel } from "../stores/network-store/network";
import { DomainModel } from "../stores/shared-store.ts/domain";
import { OrderListModel, OrderModel } from "../stores/orders-store/orders";
import { CategoryListModel } from "../stores/service-store/service-details";
import { UpiPaymentModel } from "../stores/payment-store/upi.payment";
import { AddressListModel } from "../stores/user-store/address";

/**
 * A RootStore model.
 */

//prettier-ignore
export const RootStoreModel = types.model("RootStore").props({


    // SignUp Store
    businessLocationsStore: types.optional(BusinessLocationsModel, {}),
    businessTypesStore: types.optional(BusinessTypesModel, {}),
    registerBusinessStore: types.optional(RegisterBusinessModel, {}),
    
    // Business Details Store
    businessDetailsStore: types.optional(BusinessDetailsModel, {}),

    // Store
    loaderStore: types.optional(Loader, {}),
    toastStore: types.optional(Toast, {}),

    // Service Store
    serviceListStore: types.optional(ServiceListModel, {}),
    serviceStore: types.optional(ServiceModel, {}),
    categoryListStore: types.optional(CategoryListModel, {}),


    selectedInventoryStore: types.optional(InventoryModel, {}),

    // Cart Store
    cartStore: types.optional(CartModel, {}),

    // User Store
    userStore: types.optional(UserModel, {}),
    addressStore: types.optional(AddressListModel, {}),

    // Payment Store
    checkoutStore: types.optional(CheckoutModel, {}),
    orderPaymentStore: types.optional(OrderPaymentModel, {}),
    upiPaymentStore: types.optional(UpiPaymentModel, {}),

    // Network Store
    networkStore: types.optional(NetworkModel, {}),

    // Order Store
    orderListStore: types.optional(OrderListModel, {}),
    orderStore: types.optional(OrderModel, {}),

    // Domain Store
    domainStore: types.optional(DomainModel, {}),

})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

export interface RegisterBusinessModel extends Instance<typeof RegisterBusinessModel> {}
export interface BusinessDetailsModel extends Instance<typeof BusinessDetailsModel> {}
export interface Loader extends Instance<typeof Loader> {}
export interface Toast extends Instance<typeof Toast> {}


/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
