import { types } from "mobx-state-tree";


const CustomDetailsModel = types
.model()
.props({
    FieldName: types.optional(types.string, ""),
    FieldValue: types.optional(types.string, ""),
    FieldType: types.optional(types.string, ""),
    IsMandatory: types.optional(types.boolean, false),
    DefaultValue: types.optional(types.string, ""),
    IsDisabled: types.optional(types.boolean, false),
})

const AvailablityModel = types
.model()
.props({
    dayOfWeek: types.maybeNull(types.optional(types.string, "")),
    endTime: types.maybeNull(types.optional(types.string, "")),
    startTime: types.maybeNull(types.optional(types.string, "")),
})


const MenuItems = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    amount: types.optional(types.number, 0),
    discountAmount: types.maybeNull(types.optional(types.number, 0)),
    description: types.optional(types.string, ""),
    availability: types.optional(types.array(AvailablityModel),[]),
    customDetails: types.maybeNull(types.optional(types.array(CustomDetailsModel),[])),
    isDellveryFeeAplicable: types.optional(types.boolean, false),
    avatarUri: types.maybeNull(types.optional(types.string, "")),
    isEnquireAndPay: types.optional(types.boolean, false)
})


export const CategoryModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    customDetails: types.maybeNull(types.optional(types.array(CustomDetailsModel),[])),
    menuItems: types.maybeNull(types.optional(types.array(MenuItems ),[])),
}).actions(self=>({
    create(serviceDetails){
        self.id = serviceDetails.id
        self.name = serviceDetails.name
        self.customDetails = serviceDetails.customDetails
        self.menuItems = 'menuItems' in serviceDetails ? serviceDetails.menuItems : []
    },
    updateId(id){
        self.id = id
    },
    updateName(name){
        self.name = name
    },
    updateCustomDetails(customDetails){
        self.customDetails = customDetails
    },
    updateMenuItems(menuItems){
        self.menuItems = menuItems
    }
}))


export const CategoryListModel = types
.model()
.props({
    categoryList: types.optional(types.array(CategoryModel),[]),
}).actions(self=>({
    create(categoryList){
        self.categoryList = categoryList
    },
    reset(){
        self.categoryList.clear()
    }
}))
