import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"
import { business_information, email_verify } from "../../../models/app-model/signup_model"
import { save } from "../../../utils/storage";



type registerBusiness_Res = { kind: "ok"; data: {} } | GeneralApiProblem
  

export class ApiSignup {

    async createOtp(phoneNumber, email, isEmailDisabled) {

      const api = new Api()
      const apiService = await api.setHeaderWOAuth()

      const param = { phoneNumber: isEmailDisabled ? null : phoneNumber, email: isEmailDisabled ? email: null,"userType": "Consumer"}
      const response: ApiResponse<any> = await apiService.post(url.createOtp, param)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      try {
        return { kind: "ok", data: response.data }
      } catch {
        return { kind: "bad-data" }
      }
    }
        

    async emailOtp(email): Promise<registerBusiness_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const url = api.generateUrl('emailOtp')

    // make the api call
    const param = {
      "email": email
    }

    const response: ApiResponse<any> = await apiService.post(url,param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      const data = response.data
      email_verify.otpReferenceId = data['otpReferenceId']
      email_verify.status = data['status']

    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

      
  async emailVerify(): Promise<registerBusiness_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    const url = api.generateUrl('emailVerify')

    // make the api call
    const param = {
      "email":business_information['email'],
      "otp": email_verify['otp'],
      "otpReferenceId": email_verify['otpReferenceId'],
    }

    const response: ApiResponse<any> = await apiService.post(url,param)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async ownerImage(imageUrl:string): Promise<registerBusiness_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()

    let body = new FormData();
    body.append('file', {uri: imageUrl, name: 'photo.png', type: 'image/png'});

    const url = api.generateUrl('ownerImage')

    const response: ApiResponse<any> = await apiService.post(url,body)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
      

  async emailStatus(email) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const param = { email: email, "userType": "Consumer"}
    const response: ApiResponse<any> = await apiService.post(url.emailStatus,param)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  
  async registerUser(data) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const param = {
      "otp": data['otp'],
      "otpReferenceId":  data['otpReferenceId'],
      "fullName": data['firstName']+" "+data['lastName'],
      "phoneNumber": data['phoneNumber'],
      "email": data['email'],
      "pushToken": data['pushToken'],
      "deviceOs": data['deviceOs'],
      "otpTarget": data['otpTarget']
    }


    const response: ApiResponse<any> = await apiService.post(url.registerUser, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){

        await save("accessToken", response.data['accessToken'])
        await save("refreshToken", response.data['refreshToken'])
        await save("deviceId", response.data['deviceId'])
        // var jwtDecode = require('jwt-decode');
        // var decoded = jwtDecode(response.data['authToken']);
      }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
}