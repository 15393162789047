import { types } from "mobx-state-tree";



export const UserModel = types
.model()
.props({ 
    otp: types.maybeNull(types.optional(types.string, "")),
    otpReferenceId: types.maybeNull(types.optional(types.string, "")),
    firstName: types.maybeNull(types.optional(types.string, "")),
    middleName: types.maybeNull(types.optional(types.string, "")),
    lastName: types.maybeNull(types.optional(types.string, "")),
    phoneNumber: types.maybeNull(types.optional(types.string, "")),
    email: types.maybeNull(types.optional(types.string, "")),
    location: types.optional(types.string, ""),
    locationTitle: types.optional(types.string, ""),
    locationLat: types.optional(types.string, ""),
    locationLng: types.optional(types.string, ""),
    locationPincode: types.optional(types.string, ""),
    userAvatarUri: types.maybeNull(types.optional(types.string, "")),
    locationId: types.optional(types.number, 0),
    newUser: types.optional(types.boolean, false),
    isSignIn: types.optional(types.boolean, false),
    emailVerified: types.optional(types.boolean, false),
    phoneVerified: types.optional(types.boolean, false),


    defaultAddress: types.optional(types.boolean, false),
    selectedAddress: types.optional(types.string, ""),

    pushToken: types.optional(types.string, ""),
    deviceOs: types.optional(types.string, "Android"),
    accessToken: types.maybeNull(types.optional(types.string, "")),
    refreshToken: types.optional(types.string, ""),
    deviceId: types.optional(types.string, ""),

    sharedInfo: types.optional(types.string, ""),
    isWebUrl: types.optional(types.boolean, false),
    otpTarget: types.optional(types.string, ""),
    loginId: types.maybeNull(types.optional(types.string, "")),
})
.actions(self=>({
    create(user){
        self.otp = user.otp
        self.otpReferenceId = user.otpReferenceId
        self.firstName = user.firstName
        self.middleName = user.middleName
        self.lastName = user.lastName
        self.phoneNumber = user.phoneNumber
        self.email = user.email
        self.locationId = user.locationId
        self.location = user.location
        self.locationLat = user.locationLat
        self.locationLng = user.locationLng
        self.userAvatarUri = user.userAvatarUri
        self.newUser = user.newUser
        self.isSignIn = user.isSignIn
        self.emailVerified = user.emailVerified


        self.defaultAddress = user.defaultAddress
        self.selectedAddress = user.selectedAddress

        self.pushToken = user.pushToken
        self.deviceOs = user.deviceOs
        self.accessToken = user.accessToken
        self.refreshToken = user.refreshToken
        self.deviceId = user.deviceId

        self.sharedInfo = user.sharedInfo
    },
    updateUserInfo(data){
        self.firstName = data.firstName
        self.lastName = data.lastName
        self.phoneNumber = data.mobileNumber
        self.emailVerified = data.emailVerified
        self.middleName = data.middleName
        self.email = data.email
        self.userAvatarUri = data.userAvatarUri
    },
    updateTokens(data){
        self.accessToken = data.accessToken
        self.refreshToken = data.refreshToken
        self.deviceId = data.deviceId
    },
    updateOtp(otp){
        self.otp = otp
    },
    updateOtpReferenceId(otpReferenceId){
        self.otpReferenceId = otpReferenceId
    },
    updateFirstName(fullName){
        self.firstName = fullName
    },
    updateMiddleName(middleName){
        self.middleName = middleName
    },
    updateLastName(fullName){
        self.lastName = fullName
    },
    updatePhoneNumber(phoneNumber){
        self.phoneNumber = phoneNumber
    },
    updateEmail(email){
        self.email = email
    },
    updateLoginId(loginId){
        self.loginId = loginId
    },
    updateLocation(loc){
        loc = JSON.parse(JSON.stringify(loc))
        self.location = loc.address
        self.locationTitle = loc.location
        self.locationPincode = loc.pincode
    },
    updateLocationId(locationId){
        self.locationId = locationId
    },
    updateLocationLat(locationLat){
        self.locationLat = locationLat
    },
    updateLocationLng(locationLng){
        self.locationLng = locationLng
    },
    updateUserAvatarUri(userAvatarUri){
        self.userAvatarUri = userAvatarUri
    },
    updateNewUser(status){
        self.newUser = status
    },
    updateIsSignIn(status){
        self.isSignIn = status
    },
    updateEmailVerified(status){
        self.emailVerified = status
    },
    updatePushToken(pushToken){
        self.pushToken = pushToken
    },
    updateDeviceOs(deviceOs){
        self.deviceOs = deviceOs
    },
    updateAccessToken(accessToken){
        self.accessToken = accessToken
    },
    updateRefreshToken(refreshToken){
        self.refreshToken = refreshToken
    },
    updateDeviceId(deviceId){
        self.deviceId = deviceId
    },
    updateDefaultAddress(defaultAddress){
        self.defaultAddress = defaultAddress
    },
    updateSharedInfo(sharedInfo){
        self.sharedInfo = sharedInfo
    },
    updateSelectedAddress(selectedAddress){
        self.selectedAddress = selectedAddress
    },
    updateOtpTarget(otpTarget){
        self.otpTarget = otpTarget
    },
    toggleIsWebUrl(value){
        self.isWebUrl = value
    },
    reset(){
        self.location = ""
        self.locationTitle = ""
        self.locationLat = ""
        self.locationLng = ""
        
        self.otp = null
        self.otpReferenceId = null
        self.firstName = null
        self.lastName = null
        self.middleName = null
        self.phoneNumber = null
        self.email = null
        self.loginId = null
        self.userAvatarUri = null
        self.locationId = 0
        self.newUser = false
        self.isSignIn = false
        self.emailVerified = false
        self.accessToken = null
    }
    
}))
.views(self=>({
    getRegisterModel(){
        return {
            otp: self.otp,
            otpReferenceId: self.otpReferenceId,
            firstName: self.firstName,
            middleName: self.middleName,
            lastName: self.lastName,
            phoneNumber: self.phoneNumber,
            email: self.email,
            pushToken: self.pushToken,
            deviceOs: self.deviceOs,
            otpTarget: self.otpTarget
        }
    },
    getLoginModel(){
        return {
            otp: self.otp,
            otpRefId: self.otpReferenceId,
            mobileNumber: self.phoneNumber,
            email: self.email,
            "userType": "Consumer",
            pushToken: self.pushToken,
            deviceOs: self.deviceOs
        }
    }
}))







