import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"
import { url } from "../api-list"
  

export class ApiPayment {

  async orderCalculate(param) {

    const api = new Api()
    const apiService = await api.setHeader()
    const response: ApiResponse<any> = await apiService.post(url.orderCalculate, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return {kind: problem.kind, data: response.data}
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async checkout(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.checkout, param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async orderPayment(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    const get_url = url.payment


    const response: ApiResponse<any> = await apiService.put(get_url, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async orderPaymentStatus(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    const get_url = url.paymentStatus.replace("{orderId}", param)

    const response: ApiResponse<any> = await apiService.get(get_url)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getAggreypayMobileIntentUrl(param) {
    // make the api call

    const api = new Api()

    const url = 'https://biz.aggrepaypayments.com/v2/getpaymentrequesturl'

    const apiService = await api.requests(url)

    // make the api call
    const response: ApiResponse<any> = await apiService.post(url, param)    
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async getOrderStatus(orderId) {
    
    const api = new Api()
    const apiService = await api.setHeader()

    const api_url = url.orderStatus.replace("{orderId}", orderId)

    const response: ApiResponse<any> = await apiService.get(api_url)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data", data: null }
    }
  }

  async getOrderStatusCashFree(orderId) {
    const api = new Api()
    const apiService = await api.setHeader()

    const api_url = url.orderStatusCashfree.replace("{orderId}", orderId)
    const response: ApiResponse<any> = await apiService.get(api_url)   
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async initatePayment(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.initiatePayment, param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}
