import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { Button, Text, Screen } from "../../components"
import { Image, TextStyle, View, ViewStyle, ToastAndroid, Platform } from "react-native"
import { color } from "../../theme"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { useStores } from "../../models"
import { ApiPayment } from "../../services/api/api-methods/api-payment";
import { ApiUser } from "../../services/api/api-methods/api-user"
import { getNetworkState } from "../../utils/network";
import { ServiceHeader } from "../../components/service-header/service.header";
import { AddressPicker } from "./components/adress-picker/address-picker";
import { PopupMessage } from "../../components/popup-message/popup-message";

export const OrderSummaryScreen: Component = observer(function OrderSummaryScreen() {

    const { cartStore, checkoutStore, userStore, selectedInventoryStore, networkStore, addressStore } = useStores()

    const navigation = useNavigation()

    const paymentNavigation = () => navigation.navigate("payment")
    const addressNavigation = () => navigation.navigate('logged_in_path', {screen: "address"})

    const preloader = require('../../../assets/preloader1.gif')

    const [isLoader, setLoader] = useState(false)
    const [modalVisible, setModalVisibility] = useState(false);
    const [isDeliverable, toggleDeliverable] = useState(false);
    const [showMessage, setShowMessage] =  React.useState(false)

    useEffect(()=>{
        orderCalculateApi()
        cartStore.updateAddressId(0)
    }, [cartStore.inventoryItems, cartStore.note])

    useEffect(()=>{
        cartStore.resetOrderAmount()
        getAllUserAddress()
    }, [])


    const serviceInfoNavigation = () => {
        selectedInventoryStore.updateId(cartStore.businessId)
        navigation.navigate('service_info')
    }

    const setDeliveryLoaction = (address) => {
        addressStore.updateDeliveryAddress(address)
        cartStore.updateAddressId(address.addressId)
        orderCalculateApi()
        setModalVisibility(false)
    }
    
    const orderCalculateApi = () => {
        if (cartStore.deliveryType == "RequireDelivery" && addressStore.deliveryAddres.addressId){
            cartStore.updateAddressId(addressStore.deliveryAddres.addressId)
        } else {
            cartStore.updateAddressId(0)
        }
        const api = new ApiPayment()
        setLoader(true)
        api.orderCalculate(cartStore.getServiceOrderModel()).then(res => { 
            if(res.kind == "ok"){
                if(res["data"]){
                    if("error" in res["data"] && res["data"]["error"]){
                        toggleDeliverable(false)
                    } else{
                        toggleDeliverable(true)
                    }
                    cartStore.updateOrderAmount(res["data"])
                } else {
                    navigation.navigate("cart")
                }
            } else if (res.kind == "unauthorized"){
                userStore.reset()
                navigation.navigate("account")               
            } else if (res.kind == "server"){
                toggleDeliverable(false)
            }
            setLoader(false)
        }) 
    }

    const getTransactionMessage = (textStatus) => {
        var txtMsg = ""
        var txtSubMsg = ""
        if(textStatus == "SUCCESS"){
            txtMsg = "Transaction Successful"
            txtSubMsg = "Thank you for shopping from Ellam"
        } else if(textStatus == "FAILED"){
            txtMsg = "Transaction Failed"
            txtSubMsg = "Please try once again"
        } else if(textStatus == "CANCELLED"){
            txtMsg = "Transaction Cancelled"
            txtSubMsg = "Please try once again"
        } else {
            txtMsg = "Transaction Pending"
            txtSubMsg = "Please wait while we confirm your order"
        }
        return [txtMsg, txtSubMsg]
    }


    
    const checkout = async (isEnquireAndPay) => {
        await checkNetworkState()
        if (cartStore.deliveryType == "RequireDelivery"){
            cartStore.updateAddressId(addressStore.deliveryAddres.addressId)
        } else {
            cartStore.updateAddressId(0)
        }
        const api = new ApiPayment()
        if(networkStore.isConnected){
            setLoader(true)
            const data = JSON.parse(JSON.stringify(cartStore.getServiceOrderModel()))
            data['isEnquireAndPay'] = isEnquireAndPay
            api.checkout(data).then(res => { 
                if(res.kind == "ok"){
                    res['data']['paymentProvider'] = 0
                    checkoutStore.create(res['data'])
                    if(!isEnquireAndPay) {
                        paymentNavigation()
                    } else {
                        setShowMessage(true)
                    }
                } 
                setLoader(false)
            })
        } else {
            ToastAndroid.show('No Internet Connection', ToastAndroid.SHORT);
        }
    }

    const checkNetworkState = async () => {
        var netState = await getNetworkState()
        networkStore.create(netState)
    }

    const getAllUserAddress = () => {
        const api = new ApiUser ()
        api.getAllAddress().then(res => { 
            if(res.kind == "ok"){
                addressStore.create(res["data"])
            }
        })
    }


    const generateFullAddress = (addres) => {
        var completeAddresses = addres.address1 || '';

        if (addres.address2) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.address2;
        }

        if (addres.locality) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.locality;
        }

        if (addres.landmark) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.landmark;
        }

        if (addres.city) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.city;
        }

        if (addres.state) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.state;
        }

        if (addres.country) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.country;
        }

        if (addres.zipCode) {
            completeAddresses += (completeAddresses ? ', ' : '') + addres.zipCode;
        }
        return completeAddresses

    }

    function billDetailLayout() {
        return (
            <View style={{...CONTAINER}}>
                <Text style={TEXT_TITLE}>Bill Details</Text>
                <View style={CONTAINER_ROW}>
                    <Text style={TEXT_DEFAULT}>Items</Text>
                    <Text style={TEXT_DEFAULT}>{cartStore.totalItems}</Text>
                </View>
                <View style={CONTAINER_ROW}>
                    <Text style={TEXT_DEFAULT}>Items Total</Text>
                    <Text style={TEXT_DEFAULT}>₹ {cartStore.itemsTotal.toFixed(2)}</Text>
                </View>   

                <View style={CONTAINER_ROW}>
                    <Text style={TEXT_DEFAULT}>Processing Fee</Text>
                    <Text style={TEXT_DEFAULT}>₹ {cartStore.processingFee.toFixed(2)}</Text>
                </View>
                
                <View style={CONTAINER_ROW}>
                    <Text style={TEXT_DEFAULT}>Delivery Fee</Text>
                    <Text style={TEXT_DEFAULT}>₹ {cartStore.deliveryCharge.toFixed(2)}</Text>
                </View>
                
                <View style={{borderColor: color.line, borderWidth: 1, width: '100%', marginVertical: 5, borderStyle: 'dashed', borderRadius: 1}} />

                <View style={CONTAINER_ROW}>
                    <Text style={TEXT_TITLE}>To Pay</Text>
                    <Text style={TEXT_TITLE_BLUE}>₹ {cartStore.totalAmount.toFixed(2)}</Text>
                </View>


            </View>
        )
    }

    function deliveryLocationLayout() {
        return (
            <View style={{...CONTAINER}}>
                <View style={CONTAINER_ROW}>
                  <Text preset="bold">Delivery Address Details</Text>
                </View>

                <View style={{justifyContent:'center'}}>
                    
                    {
                        addressStore.deliveryAddres.address1 ?
                            <View style={{marginTop: 10}}>
                                { addressStore.deliveryAddres.addressType ? 
                                    <View>
                                        <Text preset="bold">{addressStore.deliveryAddres.addressType.replace(/([A-Z])/g, ' $1').trim()}</Text>
                                    </View> : null 
                                }
                                <View style={CONTAINER_ROW}>
                                    <Text style={TEXT_DEFAULT}>{generateFullAddress(addressStore.deliveryAddres)}</Text>
                                </View>
                            </View>
                            : null
                    }

                    {/* { !addressStore.deliveryAddres.addressId || addressStore.deliveryAddres.addressId == 0 ? */}
                        <View style={CONTAINER_ROW}>
                            { addressStore.addressList.length?

                                <TouchableOpacity onPress={()=> setModalVisibility(true)}>
                                        { !addressStore.deliveryAddres.addressId || addressStore.deliveryAddres.addressId == 0 ?
                                            <Text style={[TEXT_TITLE_BLUE]}>{'Select Address'}</Text>
                                            :
                                            <Text style={[TEXT_TITLE_BLUE]}>{'Change Address'}</Text> 
                                        }                        
                                </TouchableOpacity> :
                                <TouchableOpacity onPress={()=> addressNavigation()}>
                                        <Text style={[TEXT_TITLE_BLUE]}>{'Add Address'}</Text>       
                                </TouchableOpacity> 
                            }
                        </View> 
                        {/* : null
                    } */}

                </View>


            </View>
        )
    }


    return (
        <Screen preset="fixed" headerTitle="Order Summary" showheaderTitle={true} showBackButton={true}>


                <ServiceHeader 
                    title={cartStore.serviceName} 
                    subTitle={cartStore.serviceLocation} 
                    imageUrl={cartStore.serviceIconUri}
                    onCardPress={serviceInfoNavigation}
                ></ServiceHeader>

                { !isLoader ?
                    <ScrollView>

                        { cartStore.note ?
                            <View>
                                <View style={{margin: 10, padding: 10, borderRadius: 10, borderWidth: 1, borderColor: color.line}}>
                                    <Text style={{fontWeight: 'bold', marginBottom: 3}}>Notes</Text>
                                    <Text text={cartStore.note}></Text>
                                </View> 
                            </View> : null
                        }
                        
                        <View>
                            {billDetailLayout()}
                        </View>

                        <View>
                            { cartStore.deliveryType == "RequireDelivery"? deliveryLocationLayout() : null}
                        </View>

                    </ScrollView> :
                    
                    <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center', flex: 1}}>
                        <Image style={{width:40,height:40}} source={preloader}/>
                    </View>

                }

                { Platform.OS == "web" ?
                <Text style={INFO_TEXT}>
                    Payment Gateway charges are NIL for UPI and RUPAY DEBIT card Payments. Other mode of payment, charges may apply.
                </Text> : null
                }

                { !isLoader ? 
                    <View style={{marginHorizontal: 15, marginBottom:10}}>
                        { cartStore.isEnquireAndPay ? 
                            <Button
                                preset="primary"
                                text={'REQUEST APPROVAL'}
                                onPress={ ()=> checkout(true)}
                            />  
                            :
                            <View> 
                                { isDeliverable || cartStore.deliveryType != "RequireDelivery" ? 
                                    <Button
                                        preset={ cartStore.deliveryType == "RequireDelivery" && 
                                            (addressStore.deliveryAddres.addressId == 0 || addressStore.deliveryAddres.addressId == null )
                                            ? "primary_disabled" : "primary"}
                                        text={'PROCEED TO PAY ₹ ' + cartStore.totalAmount}
                                        onPress={ ()=> cartStore.deliveryType == "RequireDelivery" && 
                                            (addressStore.deliveryAddres.addressId == 0 || addressStore.deliveryAddres.addressId == null)
                                                        ? setModalVisibility(true) : checkout(false)}
                                    /> : 
                                    <View style={{borderColor: color.ananthamOrange, borderWidth: 1, flexDirection: 'row',  justifyContent: 'space-between', padding: 20, borderRadius: 10}}>
                                        <View style={{marginRight: 5, flex: 1}}>
                                            <Text style={{color: color.ananthamOrange, fontSize: 13, fontWeight: 'bold', marginBottom: 10}}>NO DELIVERY</Text>
                                            <Text style={{fontSize: 12}}>No Delivery to the selected address. Please try with an alternate address.</Text>
                                        </View>
                                        <Image source={require("../../../assets/food-delivery.png")} style={{width: 50, height: 50}}></Image>
                                    </View>
                                } 
                            </View>
                        }
                    </View> : null
                }

                { modalVisible ?
                    <AddressPicker  
                        deliveryPincode = {addressStore.deliveryAddres.zipCode}
                        setDeliveryLoaction={(address) => setDeliveryLoaction(address)}
                        setModalVisibility={(value)=> setModalVisibility(value)}
                        addressCount={addressStore.addressList.length} 
                        deliverablePincodes={cartStore.deliverablePincodes}
                    ></AddressPicker> : null
                }

                { showMessage ? 
                    <PopupMessage 
                        display={showMessage} 
                        close={()=>setShowMessage(false)} 
                        message={'SUCCESS'} 
                        navigationMessage={'SUCCESS'} 
                        nextNavigationPath={checkoutStore.txStatus == 'SUCCESS' ? {stack: 'home', screen: 'dashboard'} : {stack: 'home', screen: 'cart'}}
                        type= {'SUCCESS'}
                    ></PopupMessage> : null 
                }
        
        </Screen>
    )

})


const CONTAINER: ViewStyle = {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderColor: color.line,
    borderWidth: 1,
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 5
}

const CONTAINER_ROW: ViewStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginVertical: 5
}

const TEXT_TITLE: TextStyle = {
    fontWeight: 'bold',
} 

const TEXT_DEFAULT: TextStyle = {
    fontSize: 14,
    color: color.dim
}

const INFO_TEXT: TextStyle = {
    color: color.ananthamOrange,
    textAlign: 'center',
    marginVertical: 10,
    fontSize: 12
} 

const TEXT_TITLE_BLUE: TextStyle = {
    fontWeight: 'bold',
    color: color.primary,
}
