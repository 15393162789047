import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ViewStyle, Text, Image, TextStyle, Platform, ScrollView, PermissionsAndroid } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, Screen } from "../../components"
import { color } from "../../theme"
import { ApiServicesList } from "../../services/api"
import { OrderDetailsCard } from "./components/order-detail-card.tsx/order-details-card"
import { useStores } from "../../models";
import moment from "moment";
import fetch_blob from 'rn-fetch-blob';
import FileViewer from "react-native-file-viewer";

import { ApiPayment } from "../../services/api/api-methods/api-payment"
import { useNavigation } from "@react-navigation/native"
import * as Device from 'expo-device';


export const OrderDetailsScreen: Component = observer(function OrderDetailsScreen() {

  const { domainStore, orderStore, checkoutStore } = useStores()

  const [inventoryMap, setInventoryMap] = useState({})

  const [billDetails, setBillDetails] = useState({})
  const [orderAmount, setOrderAmount] = useState({})
  const [apiLevel, setApiLevel] = useState(null);

  const [isLoading, setIsLoading] = useState(false)
  const loaderImage = require('../../../assets/preloader1.gif')

  const navigation = useNavigation()

  const paymentNavigation = () => navigation.navigate("payment")

  useEffect(() => {
    if (Platform.OS === "android") {
      fetchApiLevel();
    }
    setIsLoading(true)
    getServicesListDetailsApi()
  }, [])

  const fetchApiLevel = async () => {
    const apiLevel = Device.platformApiLevel;
    setApiLevel(apiLevel);
  };

  const getServicesListDetailsApi = () => {
    const api = new ApiServicesList()
    api.getServicesListDetails(orderStore.businessId, domainStore.getDomainName(orderStore.businessTypeId)).then(res => {
      if (res['kind'] == "ok") {
        let data = res['data']
        if (data) {
          setBillDetails(data)
          let inventoryMap = {}
          data.forEach(element => {
            if (element['menuItems']) {
              element['menuItems'].forEach(element1 => {
                inventoryMap[element1["id"]] = element1["name"]
              });
            }
          });
          setInventoryMap(inventoryMap)
        }
      }

      if (orderStore.history[0].orderStatus == 'PendingPayment') {
        orderCalculateApi()
      } else {
        setIsLoading(false)
      }
    })
  }

  const orderCalculateApi = () => {
    const api = new ApiPayment()
    var data = JSON.parse(JSON.stringify(orderStore.getServiceOrderModel()))
    data['items'] = []
    orderStore.getServiceOrderModel().items.forEach(item => {
      var newItem = JSON.parse(JSON.stringify(item))
      newItem['isDellveryFeeAplicable'] = item.deliveryType == 'RequireDelivery' ? true : false
      newItem['amount'] = item['totalAmount']
      if (newItem.enquiryStatus == 'Approved') {
        data['items'].push(newItem)
      }
    })
    api.orderCalculate(data).then(res => {
      if (res.kind == "ok") {
        orderStore.updateBillDetails(res['data'])
        setOrderAmount(res['data'])
      }
      setIsLoading(false)
    })
  }

  const initiatePaymentApi = () => {
    const api = new ApiPayment()
    var data = JSON.parse(JSON.stringify(orderStore.getServiceOrderModel()))
    data['orderInfoId'] = orderStore.id
    data['orderAmount'] = orderAmount

    api.initatePayment(data).then(res => {
      if (res.kind == "ok") {
        res['data']['paymentProvider'] = 0
        checkoutStore.create(res['data'])
        paymentNavigation()
      }
    })
  }


  const cartItemLayout = (prop, index, inventoryMap) => {
    return (
      <View style={CONTAINER}>
        <View style={{ flexShrink: 1 }}>
          <Text style={TEXT_TITLE}>{inventoryMap[prop['inventoryId']]}  </Text>

          {prop.customDetails && prop.customDetails.map((prop1, key1) => {
            return (
              <View key={key1}>
                {prop1['fieldName'] != 'Discount' ?
                  <Text style={[TEXT_DEFAULT, { marginBottom: 1 }]}>{prop1['fieldName']}:
                    &nbsp;{prop1['fieldValue']}&nbsp;
                    {prop1['fieldName'] == 'Duration' ? prop1['fieldValue'] > 1 ? 'Hours' : 'Hour' : null}
                  </Text>
                  : null
                }
              </View>
            );
          })}
          <Text style={TEXT_DEFAULT}>Date : {moment(prop.bookingDate).format('MMM DD YYYY')}</Text>
          {prop.isEnquireAndPay ?
            <View style={{ flexDirection: 'row', marginTop: 3 }}>
              <Text style={TEXT_DEFAULT}>Status : </Text>
              {itemStatus(prop.enquiryStatus)}
            </View> : null
          }
          {prop.itemAdditionalNotes ?
            <Text style={{ fontSize: 12, marginTop: 3, color: color.red }}>
              <Text style={{ fontWeight: 'bold' }}>Note:</Text> {prop.itemAdditionalNotes}
            </Text> : null
          }
        </View>
        <View style={{ flexGrow: 1 }}>
          <Text style={{ ...TEXT_TITLE_BLUE, textAlignVertical: 'center' }}>₹ {prop['totalAmount']}  </Text>
        </View>
      </View>
    )
  }

  const billDetailLayout = () => {
    return (
      <View style={BILL_LAYOUT_CONTAINER}>
        <View style={BILL_LAYOUT_ROW}>
          {orderStore.history[0].orderStatus == 'PendingPayment' ?
            <Text style={{ fontWeight: "bold" }}>Revised </Text> : null
          }
          <Text style={TEXT_TITLE}>Bill Details</Text>
        </View>
        <View style={BILL_LAYOUT_ROW}>
          <Text style={TEXT_DEFAULT}>Items</Text>
          <Text style={TEXT_DEFAULT}>{orderStore.itemCount}</Text>
        </View>
        <View style={BILL_LAYOUT_ROW}>
          <Text style={TEXT_DEFAULT}>Order Total</Text>
          <Text style={TEXT_DEFAULT}>₹ {orderStore.orderAmount.toFixed(2)}</Text>
        </View>
        <View style={BILL_LAYOUT_ROW}>
          <Text style={TEXT_DEFAULT}>Processing Fee</Text>
          <Text style={TEXT_DEFAULT}>₹ {orderStore.processingFee.toFixed(2)}</Text>
        </View>
        {
          'deliveryCharge' in orderStore ?
            <View style={BILL_LAYOUT_ROW}>
              <Text style={TEXT_DEFAULT}>Delivery Fee</Text>
              <Text style={TEXT_DEFAULT}>₹ {orderStore.deliveryCharge.toFixed(2)}</Text>
            </View>
            :
            <View></View>
        }

        <View style={{ borderColor: color.line, borderWidth: 1, width: '100%', marginVertical: 5, borderStyle: 'dashed', borderRadius: 1 }} />

        <View style={BILL_LAYOUT_ROW}>
          <Text style={TEXT_TITLE}>Total Amount</Text>
          <Text style={TEXT_TITLE_BLUE}>
            ₹ {orderStore.totalAmount && orderStore.processingFee ? (orderStore.totalAmount + orderStore.processingFee).toFixed(2) : '--'}
          </Text>
        </View>
        <View style={BILL_LAYOUT_ROW}>
          <Text style={TEXT_DEFAULT}>Payment Mode</Text>
          <Text style={TEXT_DEFAULT}>Online Payment</Text>
        </View>
      </View>
    )
  }

  const getDocumentUrl = (data) => {
    var urlVal = "";
    data.forEach(element => {
      if (element['orderStatus'] == "Completed" || element['orderStatus'] == "PendingDelivery") {
        urlVal = element["orderDocumentUrl"]
      }
    });
    return urlVal
  }

  return (
    <Screen preset="fixed" headerTitle="Order Details" showheaderTitle={true} showBackButton={true}>

      <View style={{ flex: 1 }}>
        {orderStore && !isLoading ?
          <ScrollView style={{ marginVertical: 20, paddingHorizontal: 10 }}>

            <OrderDetailsCard orderObject={orderStore} ></OrderDetailsCard>

            <View style={CART_CONTAINER}>
              <Text style={CARD_TITLE}>Details</Text>
              {orderStore.items.map((prop, key) => {
                return (
                  <View key={key} style={{ width: '100%', paddingHorizontal: 10 }}>
                    {cartItemLayout(prop, key, inventoryMap)}
                  </View>
                );
              })}
            </View>
            {orderStore.remarks ?
              <View style={CART_CONTAINER}>
                <Text style={CARD_TITLE}>Notes</Text>
                <Text style={CARD_TEXT}>{orderStore.remarks}</Text>
              </View> : null
            }

            <View>
              {billDetailLayout()}
            </View>

            {getDocumentUrl(orderStore.history) ?
              <View style={[CART_CONTAINER, { padding: 10 }]}>
                <Text style={[CARD_TITLE, { marginBottom: 10 }]}>Order Image</Text>
                <Image
                  source={{ uri: getDocumentUrl(orderStore.history) }}
                  style={{
                    width: "95%",
                    height: 150,
                    borderRadius: 10,
                    resizeMode: "cover"
                  }}
                />
              </View> : null
            }

            {orderStore.history[0].orderStatus == 'PendingPayment' && orderStore.totalAmount > 0 ?
              <View style={[{ justifyContent: 'center', paddingHorizontal: 10, marginTop: 25 }]}>
                <Button text={orderStore.totalAmount && orderStore.processingFee ?
                  "PROCEED TO PAY ₹" + (orderStore.totalAmount + orderStore.processingFee).toFixed(2) : '--'
                }
                  onPress={() => initiatePaymentApi()}></Button>
              </View> : null
            }

            <View style={EXPORT_BUTTON_CONTAINER}>
              <Button preset="tertiary" text="Export as PDF" onPress={() => exportOrderDetailsApi(orderStore, inventoryMap, apiLevel)}></Button>
            </View>

          </ScrollView> :
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
            <Image style={{ width: 40, height: 40 }} source={loaderImage} />
          </View>
        }
      </View>

    </Screen>
  )
})

const itemStatus = (status) => {
  if (status == 'Pending') {
    return <Text style={{ backgroundColor: color.blueStatusBg, color: color.blueStatus, borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal: 8 }}> {status}  </Text>;
  } else if (status == "Rejected") {
    return <Text style={{ backgroundColor: color.redStatusBg, color: color.redStatus, borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal: 8 }}> {status}  </Text>;
  } else if (status == "Approved") {
    return <Text style={{ backgroundColor: color.greenStatusBg, color: color.greenStatus, borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal: 8 }}> {status}  </Text>;
  } else {
    return null
  }
}

const exportOrderDetailsApi = (billDetails: {}, inventoryMap: {}, apiLevel: number) => {
  const api = new ApiServicesList()
  api.generateOrderDetailPdf(billDetails, inventoryMap).then(res => {
    if (res['kind'] == "ok") {
      let data = res['data']
      if (data) {
        const file = new Blob([data], { type: 'application/pdf' })

        if (Platform.OS == 'web') {
          const fileURL = URL.createObjectURL(file)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = "/Invoice-" + billDetails['referenceNumber'] + ".pdf"
          link.click()
        } else if (Platform.OS == 'android') {
          let base64data
          const fileReaderInstance = new FileReader()
          fileReaderInstance.readAsDataURL(file)
          fileReaderInstance.onload = async () => {
            base64data = fileReaderInstance.result
            const dirs = fetch_blob.fs.dirs
            const file_path = apiLevel >= 30 ? dirs.DownloadDir + "/Invoice-" + billDetails['referenceNumber'] + ".pdf" : dirs.DocumentDir + "/Invoice-" + billDetails['referenceNumber'] + ".pdf"
            var base64dataCode = base64data.split('data:application/pdf;base64,');
            try {
              if (apiLevel >= 30) {
                fetch_blob.fs.writeFile(file_path, base64dataCode[1], 'base64')
                  .then((res) => {
                    console.log(file_path, apiLevel + '>>>>>>>>fetch_blobfile_path')
                    FileViewer.open(file_path)
                  }).catch((error) => {
                    console.error("Error writing file>>" + apiLevel, error);
                  });
              } else {
                const permissionGranted = await requestStoragePermission();
                if (permissionGranted) {
                  fetch_blob.fs.writeFile(file_path, base64dataCode[1], 'base64')
                    .then((res) => {
                      console.log(file_path, apiLevel + '<<<<<<<<fetch_blobfile_path')
                      FileViewer.open(file_path)
                    }).catch((error) => {
                      console.error("Error writing file::" + apiLevel, error);
                    });;

                } else {
                  console.error("Permission not granted");
                }
              }
            } catch (error) {
              console.error("Error writing file::" + apiLevel, error);
            }
          }
        }
      }
    }
  })
}

async function requestStoragePermission() {
  if (Platform.OS === 'android') {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "Storage Permission",
          message: "App needs access to your storage to download files",
          buttonNeutral: "Ask Me Later",
          buttonNegative: "Cancel",
          buttonPositive: "OK"
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("You can use the storage");
        return true;
      } else {
        console.log("Storage permission denied");
        return false;
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }
  return true;
}

const CONTAINER: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingVertical: 10,
  paddingHorizontal: 10,
  borderColor: color.nuetralBlue,
  borderWidth: 1,
  borderRadius: 10,
  marginVertical: 5,
}

const CART_CONTAINER: ViewStyle = {
  alignItems: 'center',
  paddingVertical: 10,
  borderColor: color.nuetralBlue,
  borderWidth: 1,
  borderRadius: 10,
  marginHorizontal: 10,
  paddingHorizontal: 5,
  marginVertical: 5,
  display: 'flex'
}


const TEXT_DEFAULT: TextStyle = {
  fontSize: 14,
  color: color.dim
}


const TEXT_TITLE: TextStyle = {
  fontWeight: 'bold',
  fontSize: 14,
  flexGrow: 1,
}


const TEXT_TITLE_BLUE: TextStyle = {
  fontWeight: 'bold',
  color: color.primary,
  flexGrow: 1,
  textAlign: 'right'
}

const CARD_TITLE: TextStyle = {
  paddingLeft: 10,
  width: '100%',
  textAlign: 'left',
  fontWeight: 'bold',
  flexGrow: 1
}

const CARD_TEXT: TextStyle = {
  paddingLeft: 10,
  width: '100%',
  textAlign: 'left',
  flexGrow: 1,
  color: color.grey
}

const BILL_LAYOUT_CONTAINER: ViewStyle = {
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingVertical: 10,
  paddingHorizontal: 15,
  borderColor: color.nuetralBlue,
  borderWidth: 1,
  borderRadius: 10,
  marginHorizontal: 10,
  marginVertical: 5,
}

const BILL_LAYOUT_ROW: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginVertical: 5
}

const EXPORT_BUTTON_CONTAINER: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: 25,
  paddingHorizontal: 10
}

