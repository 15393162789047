export const signup_details = {
    fullName : '',
    businessName : '',
    businessLocation : '',
    businessType : 1,
    businessTypeLabel : "Ellam Stores",
    businessCategory : "MSME",
    phoneNumber : '',
    otp: '',
    otpReferenceId : '',
    "businessTypeId": 1,
  }
  
  export const business_information = {
    "addressLaneOne": "",
    "addressLaneTwo": "",
    "district": "",
    "zipCode": "",
    "email": "",
    "aadharNumber": "",
    "gstNumber": "",
    "fssaiLicense": "",
    "validityFrom": "2020-06-28T13:51:20.778Z",
    "validityTo": "2020-06-28T13:51:20.778Z"
  }


  export const email_verify = {
    "otp": "",
    "otpReferenceId": "",
    "status": "",
  }