import { palette } from "./palette"

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The screen background.
   */
  background: palette.white,
  /**
   * The main tinting color.
   */
  primary: palette.blue,
  /**
   * Anantham Logo blue color.
   */
  ananthamBlue: palette.cyanBlue,
    /**
   * Anantham input border blue color.
   */
  nuetralBlue: palette.nuetralBlue,
  /**
   * Anantham Logo orange color.
   */
  ananthamOrange: palette.orange,
  /**
   * A subtle color used for borders and lines.
   */
  line: palette.offWhite,
  /**
   * The default color of text in many components.
   */
  text: palette.black,
  /**
   * Secondary information.
   */
  dim: palette.lightGrey,
    /**
   * Secondary information.
   */
  grey: palette.grey,
  /**
   * Error messages and icons.
   */
  // error: palette.angry,
  error: palette.orange,
  
  /**
   * Default white color.
   */
  white: palette.white,

    /**
   * Default black color.
   */
  black: palette.black,

    /**
   * Default Green color.
   */
  green: palette.green,
  
    /**
   * Default Red color.
   */
  red: palette.red,

  /**
   * Storybook background for Text stories, or any stories where
   * the text color is color.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: palette.black,

  /**
   * Storybook text color for stories that display Text components against the
   * white background
   */
  storybookTextColor: palette.black,

  // PrimaryColor : '#347AF0',
  // PrimaryTextColor: '#485068',
  // SecondaryTextColor: '#FFFFFF',
  // TertiaryTextColor: '#347AF0',
  // BackgroundColor: "#EDF1F9",

  // AnanthamBlue: '#009EE0',

  // White: '#FFFFFF',
  // Blue: '#347AF0',
  // Red: "#DF5060",
  // Green: "#75BF72",
  // Dark: "#0D1F3C",
  // Black: "#000000",
  // Grey: "#B5BBC9",
  // Orange: "#FF6300"

  blueStatusBg: palette.blueStatusBg,
  blueStatus: palette.blueStatus,
  yellowStatusBg: palette.yellowStatusBg,
  yellowStatus: palette.yellowStatus,
  greenStatusBg: palette.greenStatusBg,
  greenStatus: palette.greenStatus,
  redStatusBg: palette.redStatusBg,
  redStatus: palette.redStatus,
  purpleStatusBg: palette.purpleStatusBg,
  purpleStatus: palette.purpleStatus,
}
