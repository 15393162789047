import React, { FunctionComponent as Component, useEffect } from "react"
import { spacing, color, styles } from "../../theme"
import { ViewStyle, TextStyle, View, TouchableWithoutFeedback } from "react-native";
import { AutoCompleteProps } from "./autocomplete.props";
import { AutocompleteItem, Autocomplete } from "@ui-kitten/components";
import { Ionicons } from "@expo/vector-icons";
import { Icon } from "../icon/icon";
import { Text } from "../text/text"
import style from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-estuary-dark";

 

// static styles
const ROOT: ViewStyle = {
  padding: spacing[2]
}

const labelStyle: TextStyle = {
  fontSize: spacing[3],
  color: color.dim,
  marginBottom: spacing[2]
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const AutoComplete: Component<AutoCompleteProps> = props => {
  const {
    list,
    placeholder,
    title,
    titleStyle,
    getSelectedItem,
    defaultValue
  } = props

  
  const filter = (item, query) => item.name.toLowerCase().includes(query.toLowerCase());
  
  const categoryIcon = (icon) => (
    <Icon icon={icon}></Icon>
    );
  
  const [value, setValue] = React.useState(null);
  const [data, setData] = React.useState(list);

  const onSelect = (index) => {
    setValue(list[index].name);
    getSelectedItem(list[index])
  };

  const onChangeText = (query) => {
    setValue(query);
    setData(list.filter(item => filter(item, query)));
  };

  const clearInput = () => {
    setValue('');
    setData(list);
  };


  useEffect(()=>{
    if(defaultValue){
      data.map((item, index) =>{
        if(defaultValue == item.id){
          setValue(list[index].name);
        }
      })
    }
  }, [defaultValue])

  // {dropdownList.map(r => <SelectItem title={r.item}/>)}    


  const renderOption = (item, index) => (
    <AutocompleteItem
      key={index}
      title={item.name}
      accessoryLeft={()=>categoryIcon(item.icon)}
    />
  );

  const renderCloseIcon = (props) => (
    <TouchableWithoutFeedback onPress={clearInput}>
      <Ionicons
        name={'ios-close'}
        size={spacing[5]}
        style={{marginHorizontal: spacing[4]}}
      />
    </TouchableWithoutFeedback>
  );
  

  return (
    <View style={{ ...ROOT }}>
        <Text preset="fieldLabel"  text={title}  style={{...styles.label_lg, ...titleStyle}} />

        <Autocomplete
          value={value}
          placeholder={placeholder}
          accessoryRight={renderCloseIcon}
          onChangeText={onChangeText}
          onSelect={onSelect}>
          {data.map(renderOption)}
        </Autocomplete>
    </View>
    
  )

}



