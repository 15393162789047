import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Dimensions } from "react-native"
import { Ionicons } from "@expo/vector-icons";
import { spacing, color } from "../../../../theme";
import { SettingsCardProps } from "./settings-card.props";
import { translate } from "../../../../i18n";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[1],
    padding: spacing[1],
    width: "100%"
}

const CARD_STYLE: ViewStyle = { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.background,    
    borderRadius: 20,
    margin: spacing[1],
    paddingVertical: spacing[3],
}

const CARD_TITLE: TextStyle = { 
    color: color.text,
    fontSize: spacing[4],
    marginLeft: spacing[2],
    marginRight: spacing[4]
}

const CARD_VALUE: TextStyle = { 
  flexDirection:'row', 
  alignItems:'center',
}

const CARD_VALUE_COLOR: TextStyle = { 
  color: color.dim
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const SettingsCard: Component<SettingsCardProps> = props => {
  const {
    cardText,
    cardValue,
    icon,
    onCardPress,
    style,
    titleStyle
  } = props
  
  const cardtext = cardText || (cardText && translate(cardText)) || ""

  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity onPress={onCardPress}>
            <View style={{...CARD_STYLE}}>
              <View style={{...CARD_VALUE}}>
              <Ionicons
                  name={'md-'+icon}
                  style={{width: 15,fontSize:15,textAlign:'center'}}
                  color={color.primary}
                  />
                <Text style= {{...CARD_TITLE, ...titleStyle}}>{cardtext}</Text>
              </View>
            <View style={{...CARD_VALUE}}>
              <Text style= {{ ...CARD_VALUE_COLOR,...titleStyle}}>{cardValue}</Text>
              <Ionicons
                  name={'ios-arrow-forward'}
                  size={spacing[4]}
                  style={{marginLeft: spacing[3]}}
                  color={color.text}
                  />
            </View>



            </View>
        </TouchableOpacity>
    </View>
    
  )
}
