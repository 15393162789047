// import React from 'react';
// import { View } from 'react-native';

// import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

// export const GoogleStoreBtn = (props: any) => {
//     return (
//         <View style={{ marginRight: '20px', marginTop: '10px' }}>
//             <GooglePlayButton
//                 url={props.APKUrl}
//                 theme={"dark"}
//                 className={"custom-style"}
//             />
//         </View>
//     );
// };
// export const AppStoreBtn = (props: any) => {
//     return (
//         <View style={{ marginRight: '20px', marginTop: '10px' }}>
//             <AppStoreButton
//                 url={props.IOSUrl}
//                 theme={"dark"}
//                 className={"custom-style"}
//             />
//         </View>
//     );
// };

import React from 'react';
import { TouchableOpacity, View, Image, Linking } from 'react-native';

const GoogleStoreBtn = ({ url }) => {

    const google_playstore_logo = require('../../../assets/google_playstore_logo.png')
    const handlePress = () => {
        // Open the URL in the default browser
        Linking.openURL(url);
    };

    return (
        <View>
            <TouchableOpacity onPress={handlePress}>
                <Image source={google_playstore_logo} style={{ width: 210, height: 65, marginRight: 40, marginTop: 10 }} />
            </TouchableOpacity>
        </View>
    );
};

export default GoogleStoreBtn;
