import { ViewStyle, TextStyle } from "react-native"
import { color, spacing } from "../../theme"

/**
 * All text will start off looking like this.
 */
const BASE_VIEW: ViewStyle = {
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[2],
  borderRadius: 4,
  justifyContent: "center",
  alignItems: "center",
  height: 46,
}

const BASE_TEXT: TextStyle = {
  paddingHorizontal: spacing[3],
}

const BASE_VIEW_SMALL: ViewStyle = {
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[2],
  borderRadius: 4,
  justifyContent: "center",
  alignItems: "center",
  height: 40,
}

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const viewPresets = {
  /**
   * A smaller piece of secondard information.
   */
  primary: { ...BASE_VIEW, backgroundColor: color.palette.blue } as ViewStyle,
  secondary: { ...BASE_VIEW, backgroundColor: color.transparent, borderColor:color.primary, borderWidth:1 } as ViewStyle,

  active: { ...BASE_VIEW,  backgroundColor: '#ECF9FF', borderColor: '#ECF9FF' } as ViewStyle,
  inactive: { ...BASE_VIEW, backgroundColor: color.palette.white, borderColor: '#ECF9FF', borderWidth:1 } as ViewStyle,

  active_sm: { ...BASE_VIEW_SMALL,  backgroundColor: '#ECF9FF', borderColor: '#ECF9FF' } as ViewStyle,
  inactive_sm: { ...BASE_VIEW_SMALL, backgroundColor: color.palette.white, borderColor: '#ECF9FF', borderWidth:1 } as ViewStyle,

  primary_disabled: { ...BASE_VIEW, backgroundColor: color.dim } as ViewStyle,

  tertiary: { ...BASE_VIEW, backgroundColor: color.red, borderColor:color.red, borderWidth:1 } as ViewStyle,


  /**
   * A button without extras.
   */
  link: {
    ...BASE_VIEW,
    paddingHorizontal: 0,
    paddingVertical: 0,
    alignItems: "flex-start",
  } as ViewStyle,
}

export const textPresets = {
  primary: { ...BASE_TEXT, fontSize: 15, color: color.palette.white } as TextStyle,
  secondary: { ...BASE_TEXT, fontSize: 15, color: color.primary } as TextStyle,
  active: { ...BASE_TEXT, color: color.palette.blue } as TextStyle,
  inactive: { ...BASE_TEXT, color: color.palette.blue } as TextStyle,
  active_sm:  { ...BASE_TEXT, color: color.palette.blue } as TextStyle,
  inactive_sm:  { ...BASE_TEXT, color: color.palette.blue } as TextStyle,
  primary_disabled: { ...BASE_TEXT, fontSize: 15, color: color.palette.white } as TextStyle,


  link: {
    ...BASE_TEXT,
    color: color.text,
    paddingHorizontal: 0,
    paddingVertical: 0,
  } as TextStyle,
}

/**
 * A list of preset names.
 */
export type ButtonPresetNames = keyof typeof viewPresets

