import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle,  Text, TouchableOpacity } from "react-native"
import { Ionicons } from "@expo/vector-icons";
import { spacing, color } from "../../theme";
import { AddressCardProps } from "./address-card.props";

 
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[1],
    padding: spacing[2],
    width: "100%"
}


export const AddressCard: Component<AddressCardProps> = props => {
  const {
    showActions = true,
    address,
    addressType,
    editPress,
    deletePress,
    style,
  } = props
  
  const getIcon = (addressType) => {
        if(addressType == "Home"){
            return "ios-home"
        } else if (addressType == "Work") {
            return "ios-briefcase"
        } else if (addressType == "RentalHome") {
            return "ios-pin"
        } else {
            return "ios-pin"
        }
  } 

  return (
    <View style={{ ...ROOT, ...style }}>
        <View style={{flexDirection:'row', borderWidth:1, borderColor:color.nuetralBlue, width:"100%", padding:10, borderRadius:5}}>
            <View style={{padding:5, justifyContent:'center'}}>
                <Ionicons
                    name={getIcon(addressType)}
                    style={{width: 50,fontSize:30, textAlign:'center'}}
                    color={color.primary}
                />
            </View>
            
            <View style={{borderColor:color.nuetralBlue, borderRightWidth:1, height:'100%', borderStyle:'dotted', marginHorizontal:10}}>
            </View>
            
            <View style={{paddingHorizontal:10, flex: 1}}>
                
                <View>
                    <Text style={{fontWeight:'bold',paddingVertical:5}}>{addressType.replace(/([A-Z])/g, ' $1').trim()}  </Text>
                    { address['address1'] ? <Text>{address['address1']}</Text> : null }
                      {/* {address['address2'] ? <Text>{address['address2']}</Text> : null} */}
                      {address['landmark'] ? <Text>{address['landmark']}</Text> : null}
                      {address['locality'] ? <Text>{address['locality']} </Text> : null}
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                          {address['state'] ? <Text>{address['state']}, </Text> : null}
                          {address['country'] ? <Text>{address['country']}, </Text> : null}
                      { address['zipCode'] ? <Text>{address['zipCode']}</Text> : null }
                    </View>
                </View>

                { showActions ? <View style={{flexDirection:'row'}}>
                    <TouchableOpacity onPress={()=>editPress(address)}>
                        <Text style={{fontWeight:'bold', paddingVertical: 10,paddingRight:20, color:color.primary}}>Edit  </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => deletePress(address['addressId'])}>
                        <Text style={{fontWeight:'bold', padding:10, paddingHorizontal:20, color:color.primary}}>Delete  </Text>
                    </TouchableOpacity>
                </View> : null 
                }
            </View>

        </View>
    </View>
    
  )
}
