import { types } from "mobx-state-tree"
import { Platform } from "react-native"

const CustomDetails = types
.model()
.props({
    fieldName: types.maybeNull(types.optional(types.string, "")),
    fieldValue: types.maybeNull(types.optional(types.string, "")),
})

const Items = types
.model()
.props({
    bookingDate: types.maybeNull(types.optional(types.string, "")),
    createdOn: types.maybeNull(types.optional(types.string, "")),
    customDetails: types.maybeNull(types.optional(types.array(CustomDetails), [])),
    deliveryType: types.maybeNull(types.optional(types.string, "")),
    discountCode: types.maybeNull(types.optional(types.string, "")),
    enquiryStatus: types.maybeNull(types.optional(types.string, "")),
    id: types.optional(types.number, 0),
    inventoryId: types.optional(types.number, 0),
    isDiscountApplied: types.maybeNull(types.optional(types.boolean, false)),
    isEnquireAndPay: types.maybeNull(types.optional(types.boolean, false)),
    itemAdditionalNotes: types.maybeNull(types.optional(types.string, "")),
    orderInfoId: types.optional(types.number, 0),
    quantity: types.optional(types.number, 0),
    rowVersion: types.optional(types.number, 0),
    taxAmount: types.maybeNull(types.optional(types.number, 0)),
    totalAmount: types.maybeNull(types.optional(types.number, 0)),
    updatedOn: types.maybeNull(types.optional(types.string, "")),
})


const History = types
.model()
.props({
    createdBy: types.maybeNull(types.optional(types.number, 0)),
    createdOn: types.maybeNull(types.optional(types.string, "")),
    id: types.optional(types.number, 0),
    orderDocumentUrl: types.maybeNull(types.optional(types.string, "")),
    orderInfoId: types.optional(types.number, 0),
    orderStatus: types.maybeNull(types.optional(types.string, "")),
    remarks: types.maybeNull(types.optional(types.string, "")),
})

export const OrderModel = types
.model()
.props({
    businessId: types.optional(types.number, 0),
    businessName: types.maybeNull(types.optional(types.string, "")),
    businessTypeId: types.optional(types.number, 0),
    deliveryCharge: types.maybeNull(types.optional(types.number, 0)),
    deliveryAddressId: types.maybeNull(types.optional(types.number, 0)),
    history: types.maybeNull(types.optional(types.array(History), [])),
    id: types.optional(types.number, 0),
    items: types.maybeNull(types.optional(types.array(Items), [])),
    processingFee: types.maybeNull(types.optional(types.number, 0)),
    referenceNumber: types.maybeNull(types.optional(types.string, "")),
    remarks: types.maybeNull(types.optional(types.string, "")),
    totalAmount: types.maybeNull(types.optional(types.number, 0)),
    orderAmount: types.maybeNull(types.optional(types.number, 0)),
    userId: types.optional(types.number, 0),
    itemCount: types.maybeNull(types.optional(types.number, 0)),
}).actions(self=>({
    create(order){
        self.businessId = order.businessId
        self.businessName = order.businessName
        self.businessTypeId = order.businessTypeId
        self.deliveryCharge = order.deliveryCharge
        self.history = order.history
        self.id = order.id
        self.items = order.items
        self.processingFee = order.processingFee
        self.referenceNumber = order.referenceNumber
        self.remarks = order.remarks
        self.userId = order.businessId
        self.deliveryAddressId = order.deliveryAddressId
        self.itemCount = order.items.length
        self.totalAmount = order.totalAmount
        self.orderAmount = 0
        self.items.forEach(item=>{
            self.orderAmount+= item.totalAmount
        })
    },
})).views(self=>({
    getServiceOrderModel(){
        return {
            businessId: self.businessId,
            remarks: self.remarks,
            items: self.items,
            addressId: self.deliveryAddressId,
            channel: Platform.OS.toUpperCase(),
            description: "OrderDescription",
            redirectUrl: null
        }
    }
})).actions(self=>({
    updateBillDetails(charges){
        self.deliveryCharge = charges.deliveryCharge
        self.processingFee = charges.processingFee
        self.orderAmount = 0
        self.itemCount = 0
        self.items.forEach(item=>{
            if(item.enquiryStatus == 'Approved') {
                self.orderAmount+= item.totalAmount
                self.itemCount+=1
            }
        })
        self.totalAmount = self.orderAmount + self.deliveryCharge
    }
}))


export const OrderListModel = types
.model()
.props({
    orderList: types.optional(types.array(OrderModel), [])
})
.actions(self=>({
    create(orderList){
        self.orderList = JSON.parse(JSON.stringify(orderList)) 
    },
    reset(){
        self.orderList.clear()
    }
}))
