import { types } from "mobx-state-tree"


export const ServiceModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    iconUri: types.maybeNull(types.optional(types.string, "")),
    location: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    priority: types.maybeNull(types.optional(types.number, 0)),
    domainName: types.maybeNull(types.optional(types.string, "")),
    deliverablePincodes: types.maybeNull(types.optional(types.array(types.number), [])),
    operationLocation: types.maybeNull(types.optional(types.string, "")),
    leadTime: types.maybeNull(types.optional(types.number, 0)),
    longOrderDays: types.maybeNull(types.optional(types.number, 0)),
    minimumQuantity: types.maybeNull(types.optional(types.number, 0)),
    maximumQuantity: types.maybeNull(types.optional(types.number, 0)),
    minimumAmountForDelivery: types.maybeNull(types.optional(types.number, 0)),
}).actions(self=>({
    create(serviceModel){
        self.id = Number(serviceModel.id)
        self.iconUri = serviceModel.iconUri ? serviceModel.iconUri : serviceModel.avatar ? serviceModel.avatar: ""
        self.location = serviceModel.location
        self.name = serviceModel.name
        self.domainName = serviceModel.domainName
        self.deliverablePincodes = "deliverablePincodes" in serviceModel ? serviceModel.deliverablePincodes : null
        self.operationLocation = serviceModel.operationLocation
        self.leadTime = serviceModel.leadTime
        self.longOrderDays = serviceModel.longOrderDays
        self.minimumQuantity = serviceModel.minimumQuantity
        self.maximumQuantity = serviceModel.maximumQuantity
        self.minimumAmountForDelivery = serviceModel.minimumAmountForDelivery
    },
    updateId(id){
        self.id = id
    },
    updateName(name){
        self.name = name
    },
    updateIconUri(iconUri){
        self.iconUri = iconUri
    },
    updateLocation(location){
        self.location = location
    },
    reset(){
        self.id = 0
        self.iconUri = ""
        self.location = ""
        self.name = ""
    }
}))     


export const ServiceListModel = types
.model()
.props({
    serviceList: types.optional(types.array(ServiceModel), [])
})
.actions(self=>({
    create(serviceList){
        self.serviceList = serviceList
    },
}))
