import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { Button, Text, Screen } from "../../../components"
import { Image, View, ViewStyle, Platform } from "react-native"
import { color } from "../../../theme"
import { ScrollView } from "react-native-gesture-handler"
import { useStores } from "../../../models"
import { ApiServicesList } from "../../../services/api"
import { ViewCartToast } from "../components/view-cart-toast/view-cart-toast"
import { DatePickerComponent } from "../../../components/date-picker/date-picker";
import { ServiceHeader } from "../../../components/service-header/service.header";
import { AddItemPopup } from "../components/add-item-popup/add-item-popup";
import { WarningPopup } from "../components/warning-popup/warning-popup";
import { InventoryListCard } from "../components/inventory-list-card/inventory-list-card";
import moment from "moment";
import GoogleStoreBtn from "../../../components/mobile-store-button/mobile-store-button"
import { Helmet, HelmetProvider } from 'react-helmet-async';
const { PLAYSTORE_URL } = require("../../../config/env")


export const ServiceDetailsScreen: Component = observer(function ServiceDetailsScreen(props) {

    const navigation = useNavigation()

    const { serviceStore, cartStore, selectedInventoryStore, domainStore, categoryListStore, userStore } = useStores()

    const [serviceDate, setServiceDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [serviceDay, setServiceDay] = React.useState('');
    const [availabilityDays, setAvailabilityDays] = React.useState({});
    const [selectedCategory, setSelectedCategory] = React.useState('All');
    const [showAddItemPopup, toggleAddItemPopup] = React.useState(false);
    const [selectedValues,setSelectedValues] = React.useState({'item':{}});
    const [showWarningModal, toggleWarningModal] = React.useState(false);
    const [modelWaringType, setModalWarningType] = React.useState("");
    const [inventoryCount, setInventoryCount] = useState({})
    const [selectedItem, setSelectedItem] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [noData, setNoData] = useState(false)


    const [accountBtn, toggleAccountBtn] = useState(false)

    const cartNavigation = () => { navigation.navigate("cart_from_service")}

    const loaderImage = require('../../../../assets/preloader1.gif')
    const offeringImage = require('../../../../assets/offering.png')
    
    const serviceInfoNavigation = ()=> {
        selectedInventoryStore.updateId(serviceStore.id)
        navigation.navigate('service_info')
    }
    
  const [pageTitle, setPageTitle] = useState(null);
  const [pageDescription, setPageDescription] = useState(null);

    useEffect(()=>{
        if(props['route'].params){
            var serviceName = props['route'].params['service']
            var domainName = props['route'].params['domain']
            if (serviceName){
                userStore.toggleIsWebUrl(true)
                getServicesDetailsFromUrlApi(serviceName, domainName)
                toggleAccountBtn(true)
            } else {
                userStore.toggleIsWebUrl(false)
            }
        } else {
            userStore.toggleIsWebUrl(false)
            cartStore.updateRedirectUrl(null)
        }
    }, [])


    useEffect(()=>{
        setServiceDate(moment().add(serviceStore.leadTime, 'hours').format('YYYY-MM-DD'))
        getServicesDetailsApi(domainStore.domainName[1])
        updateInventoryCount()
    }, [cartStore.inventoryItems])


    useEffect(()=>{
        updateInventoryCount()
    }, [cartStore.totalItems])


    const getServicesDetailsFromUrlApi = (service, domain) => {
        domainStore.createFromDomainName(domain)
        const api = new ApiServicesList()
        api.getServicesDetailsFromUrlApi(service, domain).then(res => {            
            if(res.kind == "ok"){
                serviceStore.create(res['data'])
                cartStore.updateRedirectUrl(window.location.href)
                getServicesDetailsApi(domain)
            } else {
                setNoData(true)
            }

        })
    }

    const getServicesDetailsApi = (domainName) => {
        setIsLoading(true)      
        const api = new ApiServicesList()
        api.getServicesDetails(serviceStore.id, domainName).then(res => {
            if(res['kind'] == 'ok'){
                serviceStore.create(res["data"])
                if(res['data']['leadTime']){
                    setServiceDate(moment().add(res['data']['leadTime'], 'hours').format('YYYY-MM-DD'))
                }
                setMetaData(res['data']['seoTitle'],res['data']['seoDescription']);
            } 
            getServicesListDetailsApi()
        })
    }

    const setMetaData = (title,description) => {
        setPageTitle(title);
        setPageDescription(description);
    }

    const getServicesListDetailsApi = () => {
        setIsLoading(true)      
        const api = new ApiServicesList()
        api.getServicesListDetails(serviceStore.id, domainStore.domainName[1]).then(res => {
            categoryListStore.create(res['data'])
            setDayAvailability(categoryListStore.categoryList, moment().format('dddd'))
            setIsLoading(false)    
        })
    }


    const updateInventoryCount = () => {
        let countObject={}
        cartStore.inventoryItems.forEach(element => {
            if (Object.prototype.hasOwnProperty.call(countObject, element['inventoryId'])) {
                countObject[element['inventoryId']] = countObject[element['inventoryId']] + 1
            }
            else{
                countObject[element['inventoryId']] = 1
            }
        });
        setInventoryCount(countObject)
    }


    const addInventory = (item, customDetails) => {
        
        var inventoryItems = {}
        inventoryItems['isDellveryFeeAplicable'] = item['isDellveryFeeAplicable']
        inventoryItems['isEnquireAndPay'] = item['isEnquireAndPay']
        inventoryItems['enquiryStatus'] = item['isEnquireAndPay'] ? 'Pending' : null
        inventoryItems['inventoryId'] = item['id']
        inventoryItems['name'] = item['name']
        inventoryItems['quantity'] = 1
        inventoryItems['amount'] = item['amount']
        inventoryItems['bookingDate'] = serviceDate.toString()

        var customFields = []
        for (const key in customDetails) {
            if(key == 'Discount Code' && customDetails[key]) {
                inventoryItems['amount'] = item['discountAmount']
                inventoryItems['isDiscountApplied'] = true
                inventoryItems['discountCode'] = customDetails[key]
            } else {
                customFields.push({"fieldName": key, "fieldValue": customDetails[key]})
            }
        }
        
        inventoryItems['customFields'] = customFields.length ? customFields : null

        if(cartStore.totalItems == 0 || 
            ( serviceStore.id == cartStore.businessId && inventoryItems['bookingDate'] == cartStore.orderDate && 
            inventoryItems['isEnquireAndPay'] == cartStore.isEnquireAndPay)){
            cartStore.addInventoryItems(inventoryItems)
            cartStore.updateAddToCartDate(moment().format('YYYY-MM-DD'))
            cartStore.updateServiceIconUri(serviceStore.iconUri)
            cartStore.updateServiceLocation(serviceStore.location)
            cartStore.updateServiceName(serviceStore.name)
            cartStore.updateDeliverablePincodes(serviceStore.deliverablePincodes)
            cartStore.updateMinimumQuantity(serviceStore.minimumQuantity)
            cartStore.updateMaximumQuantity(serviceStore.maximumQuantity)
            cartStore.updateMinimumAmountForDelivery(serviceStore.minimumAmountForDelivery)
            cartStore.updateBusinessTypeId(domainStore.businessTypeId)
        } else {
            if(inventoryItems['bookingDate'] != cartStore.orderDate ){
                setModalWarningType('dateWarning')
            }
            if(serviceStore.id != cartStore.businessId){
                setModalWarningType('businessWarning')
            }
            if(item['isEnquireAndPay'] != cartStore.isEnquireAndPay) {
                setModalWarningType('enquirePayWarning')
            }

            setSelectedValues({'item':inventoryItems})
            toggleWarningModal(true)
            toggleAddItemPopup(false)
            return
        }
        cartStore.updateBusinessId(serviceStore.id)
        cartStore.updateOrderDate(serviceDate.toString())
        cartStore.updateIsEnquireAndPay(item['isEnquireAndPay'])
        toggleAddItemPopup(false)
        updateInventoryCount()
    }

    const resetThenAddInventory = () => {

        toggleWarningModal(false) 
        cartStore.resetInventoryItems() 
        cartStore.addInventoryItems(selectedValues['item']) 
        cartStore.updateAddToCartDate(moment().format('YYYY-MM-DD'))
        cartStore.updateServiceIconUri(serviceStore.iconUri)
        cartStore.updateServiceLocation(serviceStore.location)
        cartStore.updateServiceName(serviceStore.name)
        cartStore.updateDeliverablePincodes(serviceStore.deliverablePincodes)
        cartStore.updateMinimumQuantity(serviceStore.minimumQuantity)
        cartStore.updateMaximumQuantity(serviceStore.maximumQuantity)
        cartStore.updateMinimumAmountForDelivery(serviceStore.minimumAmountForDelivery)
        cartStore.updateBusinessTypeId(domainStore.businessTypeId)

        cartStore.updateBusinessId(serviceStore.id)
        cartStore.updateOrderDate(serviceDate.toString()) 
        cartStore.updateIsEnquireAndPay(!cartStore.isEnquireAndPay)
        toggleAddItemPopup(false)  
        updateInventoryCount() 
    }

    const checkCustomFeildsAndInventory = (selectedCategory, selectedInventory) => {
        
        if(!selectedInventory.hasOwnProperty('customDetails') 
            || ('customDetails' in selectedInventory && (selectedInventory['customDetails'] == null 
            || selectedInventory['customDetails'].length == 0))) {
            addInventory(selectedInventory, {})
        } else {
            toggleAddItemPopup(true)
            selectedInventory["categoryName"] = selectedCategory["name"]
            setSelectedItem(selectedInventory)
        }
    }


    const setDayAvailability = (inventorieslist=categoryListStore.categoryList, day=serviceDay) => {
        if(inventorieslist.length && day){
            let inventoriesAvailability = {}
                inventorieslist.map((value1, key1) => {
                    if(value1['menuItems']){
                        value1['menuItems'].map((value2, key2) => {
                            if(value2['availability']){
                                value2['availability'].map((value, key) => {
                                    if(value['dayOfWeek'] == day){
                                        if(inventoriesAvailability[value1['name']]){
                                            inventoriesAvailability[value1['name']][value2['id']]=true
                                        }
                                        else{
                                            inventoriesAvailability[value1['name']] = {}
                                            inventoriesAvailability[value1['name']][value2['id']]=true
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            setAvailabilityDays(inventoriesAvailability)
        }
    }


    return (
        <Screen 
            preset="fixedHeight" 
            headerTitle={domainStore.businessName + " " + (domainStore.businessName == 'Parking' ? '' : domainStore.inventoryName[2])} 
            showheaderTitle={true} 
            showBackButton={true} 
            showAccountBtn={accountBtn}
        >
         <HelmetProvider>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          {/* Add any other meta tags as needed */}
        </Helmet>
            
            { !noData && !isLoading ?
                <View style={{flex:1}}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <ServiceHeader 
                            title={serviceStore.name} 
                            subTitle={serviceStore.location} 
                            imageUrl={serviceStore.iconUri}
                            onCardPress={serviceInfoNavigation}
                    ></ServiceHeader>
                        {Platform.OS == 'web' ? <GoogleStoreBtn url={PLAYSTORE_URL}></GoogleStoreBtn> : null}
                    </View>
                    
                    { categoryListStore.categoryList.length ?
                        <DatePickerComponent 
                            initialDate={ serviceStore.id == 105 ? moment('2023-12-26').add(serviceStore.leadTime, 'hours').format('YYYY-MM-DD') 
                                            : moment().add(serviceStore.leadTime, 'hours').format('YYYY-MM-DD')
                                        } 
                            endDate={serviceStore.id == 105 ? '2024-01-06' : '2099-12-30'}
                            getDate={(date)=> { 
                                setServiceDate(date); 
                                setServiceDay(moment(date).format('dddd')); 
                                setDayAvailability(categoryListStore.categoryList, moment(date).format('dddd')
                            )}}
                        ></DatePickerComponent> : null
                    }

                    { categoryListStore.categoryList.length ?
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} indicatorStyle='white' style={{height:60, maxHeight:60}}>
                                
                            <View style={BUTTON_CONTAINER}>
                                { categoryListStore.categoryList.length > 0 && Object.keys(availabilityDays).length ?
                                    <Button
                                        preset ={selectedCategory == "All" ? "active_sm" : "inactive_sm"}
                                        style={{marginRight:10}}
                                        text="All"
                                        onPress={() => setSelectedCategory('All')}
                                    /> : null
                                }

                                { categoryListStore.categoryList.map((category, key) => {
                                    category = JSON.parse(JSON.stringify(category))
                                    if (category.name in availabilityDays) {
                                        return (
                                            <Button
                                                preset ={selectedCategory == category.name ? "active_sm" : "inactive_sm"}
                                                style={{marginRight:10}}
                                                key={key}
                                                text={category.name}
                                                onPress={() => setSelectedCategory(category.name)}
                                            />
                                        );
                                    } else {
                                        return null
                                    }
                                })}
                            </View>

                        </ScrollView> : null
                    }
                    
                    <View style={{width:'100%',borderBottomColor:color.nuetralBlue, borderBottomWidth:1, marginBottom: 15}}/>

                    <View style={{flex: 1}}>
                        { isLoading?
                            <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center',}}>
                                <Image style={{width:40,height:40}} source={loaderImage}/>
                            </View> 
                            :
                            <ScrollView style={{flex:1}}>
                                { categoryListStore.categoryList.length ? 
                                    categoryListStore.categoryList.map((category, key) => {
                                        if ((selectedCategory == 'All' || selectedCategory == category.name) && 'menuItems' in category &&  category.name in availabilityDays) {
                                            return (
                                                <View key={key} style={ROOT_CONTAINER}>
                                                    { selectedCategory == 'All' ? 
                                                        <Text style={{ fontSize: 16, paddingBottom:10, fontWeight: 'bold' }}>{category.name}</Text> : null
                                                    }
                                                    <InventoryListCard 
                                                        category={category} 
                                                        inventoryKey={key} 
                                                        checkCustomFeildsAndInventory={(category, inventory) => 
                                                            checkCustomFeildsAndInventory(category, inventory)}
                                                        daysAvailability={availabilityDays}
                                                        inventoryCount={inventoryCount}
                                                    ></InventoryListCard>
                                                </View>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                    :
                                    <View style={{flex: 1, marginTop: 100, justifyContent: 'center', alignItems: 'center'}}>
                                        <Image style={{width:40,height:40,marginBottom:10}} source={offeringImage}/>
                                        <Text style={{color:color.dim}}>
                                            Currently not avaliable
                                        </Text>
                                    </View>
                                }
                            </ScrollView>
                        }
                    </View>
                    
                    { showAddItemPopup  ?
                        <AddItemPopup 
                            item={selectedItem} 
                            newInventoryVar={[]}  
                            addInventory={(item, customField) => addInventory(JSON.parse(JSON.stringify(item)), customField)}
                            closePopup={()=> toggleAddItemPopup(false)}
                        ></AddItemPopup>
                        :
                        cartStore.totalItems > 0 ?
                            <ViewCartToast
                                date={cartStore.orderDate}
                                count={cartStore.totalItems.toString()} 
                                amount={cartStore.itemsTotal.toString()} 
                                onButtonPress={() => cartNavigation()}
                            ></ViewCartToast> : null
                    }
        
                    { showWarningModal ?
                        <WarningPopup 
                            serviceDate={serviceDate} 
                            modelWaringType={modelWaringType} 
                            resetThenAddInventory={()=>resetThenAddInventory()}
                            setModalVisibility={(value)=> toggleWarningModal(value)}
                        ></WarningPopup> : null
                    }
                </View> :

                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                { isLoading ? 
                    <View style={{paddingVertical:100,justifyContent:'center',alignItems:'center',}}>
                        <Image style={{width:40,height:40}} source={loaderImage}/>
                    </View>  
                    :
                    <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                        
                        <Text style={{fontSize: 80, color: color.primary}}>404</Text>
                        <Text style={{fontSize: 14, fontWeight: 'bold'}}>PAGE NOT FOUND</Text>
                        <Text style={{fontSize: 12, marginTop: 15, color:color.dim}}>The page you are looking for does not seem to exist.</Text>

                        <Button
                            preset = "primary"
                            text="GO TO HOME"
                            style={{marginTop: 30}}
                            textStyle={{fontSize: 13}}
                            onPress={() => navigation.navigate('home')}
                        />                
                    </View>
                }
                </View>
            }
            </HelmetProvider>
        </Screen>
    )
})





const BUTTON_CONTAINER: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginHorizontal: 20,
}

const ROOT_CONTAINER: ViewStyle = {
    marginHorizontal: 30,
    marginVertical: 10,
}

