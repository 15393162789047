import React, { FunctionComponent as Component, useState, useEffect } from "react"
import {  View, ViewStyle, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Icon } from "../../components"
import { color, styles } from "../../theme"
import { useStores } from "../../models/root-store/root-store-context";
import { ApiSignin } from "../../services/api/api-methods/api-signin"
import { ApiSignup } from "../../services/api";


export const SigninScreen: Component = observer(function SigninScreen(props) {

  const { userStore } = useStores()
  const [errormsg,setErrormsg] = useState("")

  const navigation = useNavigation()

  useEffect(()=>{
    userStore.reset()
  }, [])

  function phoneNumberStatusApi() {
    const api = new ApiSignin ()
    api.phoneNumberStatus(userStore.phoneNumber).then(res => { 
      responseWork(res)
    })
  }

  function emailStatusApi(){
    const api = new ApiSignup ()
    api.emailStatus(userStore.email).then(res => { 
      responseWork(res)
    })
  }

  function generateOtpApi() {
    const api = new ApiSignin ()
    api.generateOtp(userStore.phoneNumber, userStore.email).then(res => { 
      if(res['kind'] == "ok"){
        let data = res['data']
        if(data["otpReferenceId"]){
          userStore.updateOtpReferenceId(data["otpReferenceId"])
          userStore.updateNewUser(false)
          navigation.navigate("logged_out_path", {screen: "verify_otp"})
        }
      }
    })
  }

  function validateInput() {
    if(userStore.loginId){
      setErrormsg('')
      if(validateEmailAddress(userStore.loginId)){
        userStore.updateEmail(userStore.loginId.trim())
        userStore.updatePhoneNumber(null)
        userStore.updateOtpTarget("email")
        emailStatusApi()
      } else if (validatePhoneNumber(userStore.loginId)){ 
        userStore.updatePhoneNumber(userStore.loginId.trim())
        userStore.updateEmail(null)
        userStore.updateOtpTarget("sms")
        phoneNumberStatusApi()
      } else{
        setErrormsg('Invalid Phone Number/ Email')
      }
    } else{
      setErrormsg('Invalid Phone Number/ Email')
    }
  }


  const responseWork = (res) => {

    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["isUserExists"]){
        generateOtpApi()
      } else {
        navigation.navigate("logged_out_path", {screen: "register"})
      }
    }
  }


  const validateEmailAddress = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email.trim()).toLowerCase());
  }

  const validatePhoneNumber = (mobileNumber) => {
    const re = /^[1-9]{1}[0-9]{3,16}$/
    return re.test(String(mobileNumber.trim()).toLowerCase());
  }



  return (
    <Screen preset="scroll" headerTitle={userStore['isSignIn']?'Account':'SignIn'} showheaderTitle={true} showBackButton={false}>
      <View style={CONTAINER}>
        <View style={{ alignItems: 'center', marginBottom: 40 }}>
          <Text preset="boldLarge">
            Account
            </Text>
          {/* <Image source={img} style={imgStyle}></Image> */}
          <View style={{alignItems:'center', marginVertical: 10, backgroundColor: color.black, borderRadius: 20, padding: 15, width:90}}>
              <Icon icon={'ellam_icon'} style={{width: 60, height: 60}}></Icon>
          </View>
          <Text preset="boldLarge">Ellam</Text>
        </View>
        <View style={{ marginBottom: 40, width: "100%", maxWidth: 500 }}>
          <TextField 
              defaultValue={userStore.loginId}
              errorMessage={errormsg} 
              placeholder='Phone Number or Email'  
              icon='phone' 
              keyboardType='email-address' 
              getText={text => userStore.updateLoginId(text)}>
          </TextField>
        </View>

        <Text style={{ marginBottom: 40, ...TITLE_SECONDARY }}>
          We will send you One Time Passsword (OTP) to your Phone Number/ Email Address
        </Text>

        <View style={{ marginBottom: 40 }}>
          <Button
            preset="primary"
            style={{minWidth: "100%"}}
            text="CONTINUE"
            onPress={validateInput}
          />
          <Text style={{color: color.ananthamOrange, fontSize: 12, paddingVertical: 15, paddingHorizontal: 10, textAlign: 'center'}}>
            Outside India users please register with your Email Id to get OTP
          </Text>
        </View>
      </View>
    </Screen>

  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  backgroundColor: color.white,
  padding: 20,
  marginTop: 15
}


const TITLE_SECONDARY: TextStyle = {
  width: "100%",
  color: color.dim,
  textAlign: "center",
} 

