import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { Image, View, Dimensions, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Icon } from "../../../components"
import { color } from "../../../theme"
import { ServicerCard } from "../components/service-card/service-card";
import { ApiServicesList } from "../../../services/api"
import { TouchableOpacity, ScrollView } from "react-native-gesture-handler"
import { useStores } from "../../../models"
import { MaterialIcons } from '@expo/vector-icons'; 

export const ServiceListScreen: Component = observer(function ServiceListScreen() {

    const { userStore, serviceListStore, domainStore, serviceStore, categoryListStore, addressStore } = useStores()

    const navigation = useNavigation()

    const [searchKey, setSearchKey] = useState('')
    const [nearOption, setNearOption] = useState(false)
    const [popularOption, setPopularOption] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const loaderImage = require('../../../../assets/preloader1.gif')


    useEffect(()=>{
      allBusiness()
    }, [])

    const detailsSceen = (element) => {
      categoryListStore.reset()
      serviceStore.updateId(element["id"])
      navigation.navigate(`service_details`)
    }

    const allBusiness = () => {
      setPopularOption(false)
      serviceListApi(searchKey, nearOption, false)
    }

    const nearByBusiness = (value) => {
      setNearOption(value)
      serviceListApi(searchKey, value, popularOption)
    }

    const popularBusiness = () => {
      setPopularOption(true)
      serviceListApi(searchKey, nearOption, true)
    }

    const updateSearchText = (text:string) => {
          setSearchKey(text)
          serviceListApi(text, nearOption, popularOption)
    } 

   const serviceListApi = (text='', near=true, popular) => {
      setIsLoading(true)      
      const api = new ApiServicesList()
      api.getServicesList(text, near, popular, domainStore.domainName[1], addressStore.deliveryAddres).then(res => {
          serviceListStore.create(res['data'])
          setIsLoading(false)      
      })
    }



    return (
        
      <Screen preset="fixed" headerTitle={domainStore.businessName} showBackButton={true} showheaderTitle={true}>

        <View style={{marginTop: 20, flex: 1}}>

            <View style={{paddingHorizontal: 20}}>

              <TextField  placeholder={'Search ' + domainStore.businessName} icon='lens_blue' getText={text => updateSearchText(text)}></TextField>

              <ScrollView style={{marginTop: 10}} horizontal={true} showsHorizontalScrollIndicator={false} indicatorStyle='white'>
                  
                  <View style={{marginHorizontal: 10, alignSelf: "center"}}>
                    <Button
                      preset={!popularOption ? "active": "inactive"}
                      text={"All " + domainStore.businessName}
                      onPress={allBusiness}
                    />
                  </View>
                  <View style={{marginHorizontal: 10, alignSelf: "center"}}>
                    <Button
                      preset={popularOption ? "active": "inactive"}
                      text={"Most Visited"}
                      onPress={popularBusiness}
                    />
                  </View>
    
              </ScrollView>

            </View>

            { Platform.OS != "web" ?
              <View style={{marginTop: 10, position: 'absolute', bottom: 20, right: 20, justifyContent: 'center', alignItems: 'center', zIndex: 200}}>
                <TouchableOpacity 
                    style={{backgroundColor: nearOption ? color.primary: color.dim, borderRadius: 25, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}
                    onPress={()=>nearByBusiness(!nearOption)}>
                    <MaterialIcons name="my-location" size={22} color="white" />
                </TouchableOpacity>
                <Text style={{color: nearOption ? color.primary: color.dim, marginTop: 5, fontWeight: 'bold', fontSize: 12}}>{ nearOption ? "List All " + domainStore.businessName + "(s)" : "Near By " + domainStore.businessName + "(s)"}</Text>
              </View> : null
            }

            <ScrollView style={{marginTop:10, paddingHorizontal: 20}}>
              { isLoading ?
                  <View style={{paddingVertical:50,justifyContent:'center',alignItems:'center',}}>
                    <Image style={{width:40,height:40}} source={loaderImage}/>
                  </View>
                  :
                  <View>
                    {
                      serviceListStore.serviceList && serviceListStore.serviceList.length ?
                        serviceListStore.serviceList.map((element,index) => 
                              <ServicerCard  
                                key={index}
                                title={element.name}  
                                location={element.location} 
                                imageSource={element.iconUri} 
                                onCardPress={()=> detailsSceen(element)}
                              ></ServicerCard>
                        )
                      :
                        <View style={{marginTop:  Dimensions.get('window').height/6, alignItems:'center', justifyContent:'center'}}>
                          <Icon icon="prarthana" style={{width: 60, height: 60}}></Icon>
                          <Text style={{color:color.dim, marginTop: 10}}>
                              No {domainStore.businessName} Near You
                          </Text>
                        </View>
                    }
                  </View>
              }
            </ScrollView>
        </View>

      </Screen>
  )
})
