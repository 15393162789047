import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"
import { url } from "../api-list"
  

export class ApiServicesList {

  async getServicesList(searchKey, near, popular, domainName, deliveryDetails) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const get_url = url.services.replace('{domain}', domainName)
    
    const param = {
        "latitude": deliveryDetails.latitude,
        "longitude": deliveryDetails.longitude,
        "key": searchKey,
        "locationType": near? 'near' : 'all',
        "popular": popular,
        "pincode": deliveryDetails.zipCode
    }

    const response: ApiResponse<any> = await apiService.post(get_url, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async inverntoryDetails(domainName) {

    const api = new Api()
    const apiService = await api.setHeader()

    // const response: ApiResponse<any> = await apiService.get("/order/all")
    let param = {
      "latitude": "",
      "longitude": "",
      "key": "",
      "locationType": ""
    }
    const response: ApiResponse<any> = await apiService.post(url.services.replace("{domain}", domainName), param)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
        
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async allOrder(limit, offset) {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.orderAll  
      + '/' + limit
      + '/' + offset
    const response: ApiResponse<any> = await apiService.get(apiUrl)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
        
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getServicesDetails(id, domainName) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    
    const response: ApiResponse<any> = await apiService.get(url.servicesDetails.replace('{businessInfoId}', id.toString()).replace("{domain}", domainName))
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getServicesInfo(id, domainName) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    
    const response: ApiResponse<any> = await apiService.get(url.servicesInfo.replace('{businessInfoId}', id.toString()).replace("{domain}", domainName))
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getServicesListDetails(id, domainName) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    
    var postUrl = url.servicesListDetails.replace('{businessInfoId}',id.toString()).replace('{domain}', domainName)

    const response: ApiResponse<any> = await apiService.get(postUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getServicesDetailsFromUrlApi(service, domain){
    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    
    const apiURL = url.servicesDetailsFromUrl.replace('{service}', service).replace('{domain}', domain)

    const response: ApiResponse<any> = await apiService.get(apiURL)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
        
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async validateDiscountCode(code){
    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    
    const apiURL = url.validateDiscount.replace('{code}', code)

    const response: ApiResponse<any> = await apiService.get(apiURL)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      if(response.data['statusCode'] = 200){}
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async generateOrderDetailPdf(data, inventoryMap) {
    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.orderExport

    const response: ApiResponse<any> = await apiService.post(apiUrl, { data: data, inventory: inventoryMap }, { responseType: 'blob' })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data", data: null }
    }
  }
}
