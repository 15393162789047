import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField } from "../../../components"
import { color } from "../../../theme"
import { useStores } from "../../../models/root-store/root-store-context";
import { ApiUser } from "../../../services/api/api-methods/api-user"



export const EditPhoneScreen: Component = observer(function EditPhoneScreen() {
  
  const { userStore } = useStores()
  const navigation = useNavigation()
  
  const [errormsg,setErrormsg] = useState("")
  const [errormsgPhone,setErrormsgPhone] = useState("")
  const [newPhone,setNewPhone] = useState("")
  let [showOtpScreen,setShowOtpScreen] = useState(false)
  let [resendOtp,setRresendOtp] = useState(false)
  let [resendOtpTime,setRresendOtpTime] = useState(30)

  const navProfileInfo = () => navigation.navigate('profile')

  useEffect(()=>{
    setNewPhone(userStore.phoneNumber)

  }, [])

  function startResendOtpTimer() {
    // userStore.updateOtp("")
    setRresendOtp(false)
    let interval = setInterval(() => {
      if(resendOtpTime>0){
        setRresendOtpTime(--resendOtpTime)
      }
      else{
        setRresendOtp(true)
        clearInterval(interval)
        setRresendOtpTime(30)
      }
     }, 1000);
  }

  
  function verifyInputs() {
    userStore.updateOtp("")
    if(newPhone.length == 10){
      setErrormsgPhone('')
      phoneNumberStatusAPi()
    }
    else{ setErrormsgPhone('Enter Phone number') }
  }


  const responseWorkPhone = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["isUserExists"]){
        setErrormsgPhone('Phone number already exist')
      }
      else{
        PhoneNumberOtpApi()
      }
    }
    else{
      setErrormsgPhone('Invalid Phone number')
    }
  }

  

  const responseWorkOtp = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["otpReferenceId"]){
        userStore.updateOtpReferenceId(data['otpReferenceId'])
        userStore.updateOtp('')
        setShowOtpScreen(true)
        startResendOtpTimer()
      }
      else{
        // updateApi()
      }
    }
    else{
      setErrormsgPhone('Invalid Phone number')
    }
  }

  

    // API
    function phoneNumberStatusAPi() {
      const api = new ApiUser ()
      api.phoneNumberStatus(newPhone).then(res => { 
        responseWorkPhone(res)
      })
    }
  
    function PhoneNumberOtpApi() {
      const api = new ApiUser ()
      api.phoneOtp(newPhone).then(res => { 
        responseWorkOtp(res)
      })
    }



  function verifyOtpInput() {
    if(userStore.otp.length == 4){
      setErrormsg('')
      updateApi()
    }
    else{ setErrormsg('Invalid OTP') }
  }

  function updateApi(){
    let param = {
      "firstName": userStore.firstName,
      "middleName": userStore.middleName,
      "lastName": userStore.lastName,
      "emailDetails": null,
      "phoneNumberDetails": {
        "otp": userStore.otp,
        "otpReferenceId": userStore.otpReferenceId,
        "mobileNumber": newPhone
      }
    }


    const api = new ApiUser ()
    api.userInfoUpdate(param).then(res => { 
      responseWork(res)
    })
  }

  
  const responseWork = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data['value']){
        userStore.updatePhoneNumber(newPhone)
        navProfileInfo()
      }
      else{
        // error message
      setErrormsg('Invalid OTP')

      }
    }
    else{
      setErrormsg('Invalid OTP')
    }

  }



  return (

    <Screen preset="fixed" headerTitle="Phone" showheaderTitle={true} showBackButton={true}>

      <View style={CONTAINER}>
            <View>
            <TextField errorMessage={errormsgPhone}  label='Change Mobile Number' disabled={showOtpScreen} keyboardType='numeric' icon='phone' defaultValue={newPhone} getText={text => setNewPhone(text)}></TextField>
            
            {
                showOtpScreen?  
                  <View>
                        <View style={{marginBottom:20}}>
                          <TextField errorMessage={errormsg} label='Enter Your OTP' icon='phone' keyboardType='numeric'  getText={text => userStore.updateOtp(text)}></TextField>
                        </View>
                        <Text style={{marginBottom: 40, ...SUB_TITLE}}>
                          Didn’t receive the OTP?
                          {resendOtp?
                              <Text style={{color: color.primary}} onPress={() => [verifyInputs(), startResendOtpTimer()]}>
                                {' '} Resend OTP
                              </Text>
                              :
                              <Text style={{color:color.dim}}>
                                {' '} Resend OTP (00:{resendOtpTime})
                              </Text>
                          }

                        </Text>
                      </View>
                  :
                  <View>
                    <Text style={{textAlign:'center',padding:10,color:color.dim}}>
                      We will send you One Time Passsword (OTP)
                      to your Phone Number, if you want to update
                      the phone number
                    </Text>
                  </View>
                        }
            </View>

              {
                showOtpScreen?


                  <View style={{marginBottom: 40, alignSelf: "center"}}>
                    <Button
                      style={{minWidth:'100%'}}
                      text="Submit & Update Phone Number"
                      onPress={verifyOtpInput}
                    />
                  </View>
                :
                
                <View style={{ alignSelf: "center"}}>
                  <Button
                    style={{minWidth:'100%'}}
                    text="Send OTP"
                    onPress={verifyInputs}></Button>
                </View>

              }


      </View>

    </Screen>
)
})

const CONTAINER: ViewStyle = {
  justifyContent: "space-between",
  alignItems: "center",
  height:'100%',
  padding: 20,
}
  
const  SUB_TITLE: TextStyle = {
  width: "100%",
  color: color.text,
  textAlign: "center"
}

