import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { Image, ImageStyle, View, ViewStyle, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField } from "../../../components"
import { color, spacing, styles } from "../../../theme"
import { SettingsCard } from "../components/settings-card/settings-card";
import { ScrollView } from "react-native-gesture-handler";
import { clear } from "../../../utils/storage";
import { useStores } from "../../../models/root-store/root-store-context";
import { ApiUser } from "../../../services/api/api-methods/api-user"



export const EditNameScreen: Component = observer(function EditNameScreen() {
  
  const { userStore } = useStores()
  const navigation = useNavigation()
  
  const [errormsgFname,setErrormsgFname] = useState("")
  const [newFname,setNewFname] = useState("")
  const [newMname,setNewMname] = useState("")
  const [newLname,setNewLname] = useState("")
  const navProfileInfo = () => navigation.navigate('profile')

  useEffect(()=>{
    setNewFname(userStore.firstName)
    setNewMname(userStore.middleName)
    setNewLname(userStore.lastName)

  }, [])

  
  function verifyInputs() {
    if(newFname){
      setErrormsgFname('')
      updateApi()
    }
    else{ setErrormsgFname('Enter First Name') }
  }

  
  function updateApi(){
    let param = {
      "firstName": newFname,
      "middleName": newMname,
      "lastName": newLname,
      "emailDetails": null,
      "phoneNumberDetails": null
    }

    const api = new ApiUser ()
    api.userInfoUpdate(param).then(res => { 
      responseWork(res)
    })
  }

  
  const responseWork = (res) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data['value']){
        userStore.updateFirstName(newFname)
        userStore.updateMiddleName(newMname)
        userStore.updateLastName(newLname)
        navProfileInfo()
      }
      else{
        // error message
      }
    }

  }



  return (

    <Screen preset="fixed" headerTitle="Username" showheaderTitle={true} showBackButton={true}>

      <View style={CONTAINER}>
 
        <View>
              <TextField errorMessage={errormsgFname} label='First Name' icon='user' defaultValue={newFname} getText={text => setNewFname(text)}></TextField>
              <TextField label='Middle Name' icon='user' defaultValue={newMname} getText={text => setNewMname(text)}></TextField>
              <TextField label='Last Name' icon='user' defaultValue={newLname} getText={text => setNewLname(text)}></TextField>
              {/* <TextField errorMessage={errormsgEmail} placeholder='Your Email Id' icon='mail' defaultValue={userStore.email} getText={text => userStore.updateEmail(text)}></TextField> */}
              {/* <TextField placeholder='Your Mobile Number' keyboardType='numeric' icon='phone' defaultValue={userStore.phoneNumber} disabled={true} getText={text => userStore.updateEmail(text)}></TextField> */}

        </View>
        <View style={{ alignSelf: "center"}}>
                <Button
                  style={{minWidth:'100%'}}
                  text="Save"
                  onPress={verifyInputs}></Button>
        </View>
      </View>

    </Screen>
)
})

const CONTAINER: ViewStyle = {
  justifyContent: "space-between",
  alignItems: "center",
  height:'100%',
  padding: 20,
}

