export const palette = {
  black: "#000000",
  white: "#FFFFFF",
  offWhite: "#E6E6E6",
  orange: "#FF6300",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#DD3333",
  blue: "#40BFFF",
  cyanBlue: "#009EE0",
  lightBlue: "#EDF1F9",
  red: "#DF5060",
  green: "#75BF72",
  nuetralBlue: "#EBF0FF",
  grey: "#696969",

  blueStatusBg: "#D6E5FC",
  blueStatus: "#347BF1",
  yellowStatusBg: "#FFF3CD",
  yellowStatus: "#FFC107",
  greenStatusBg: "#E3F2E3",
  greenStatus: "#75BF72",
  redStatusBg: "#ffe4e6",
  redStatus: "#ba1d23",
  purpleStatusBg: "#ffe8d9",
  purpleStatus: "#b35900"
  
}
