import { types } from "mobx-state-tree";

const cashFreeModel = types
.model()
.props({
    notifyUrl: types.optional(types.string, ""),
    token: types.optional(types.string, ""),
})

const freeChargeModel = types
.model()
.props({
    amount: types.optional(types.string, ""),
    channel: types.optional(types.string, ""),
    checksum: types.optional(types.string, ""),
    currency: types.optional(types.string, ""),
    customerName: types.optional(types.string, ""),
    furl: types.optional(types.string, ""),
    merchantId: types.optional(types.string, ""),
    merchantTxnId: types.optional(types.string, ""),
    mobile: types.optional(types.string, ""),
    s2sUrl: types.optional(types.string, ""),
    surl: types.optional(types.string, ""),
})


const agreePayModel = types
.model()
.props({
    amount: types.optional(types.string, ""),
    currency: types.optional(types.string, ""),
    api_key: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    hash: types.optional(types.string, ""),
    mode: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    order_id: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    return_url: types.optional(types.string, ""),
    return_url_failure: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    country: types.optional(types.string, ""),
    zip_code: types.optional(types.string, ""),
    split_info: types.maybeNull(types.optional(types.string, ""))
})

export const CheckoutModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    orderRefNumber: types.optional(types.string, ""),
    paymentNumber: types.optional(types.string, ""),
    // paymentGatewayToken: types.optional(types.string, ""),
    // notifyUrl: types.maybeNull(types.optional(types.string, "")),
    freeChargePaymentPayload: types.optional(freeChargeModel, {}),
    cashFreePaymentPayload: types.optional(cashFreeModel, {}),
    agreePayPayload: types.optional(agreePayModel, {}),
    paymentProvider: types.optional(types.number, 0),

    gatewayRefenceId: types.optional(types.string, ""),
    isSuccessful: types.optional(types.boolean, false),
    paymentSignature: types.optional(types.string, ""),
    txMsg: types.optional(types.string, ""),
    txTime: types.optional(types.string, ""),
    paymentMode: types.optional(types.string, ""),
    txStatus: types.optional(types.string, ""),
})
.actions(self=>({
    create(data){

        self.id = data.id
        self.orderRefNumber = data.orderRefNumber
        self.paymentNumber = data.paymentNumber
        self.paymentProvider = data.paymentProvider
        if(data.paymentProvider == 0){
            self.cashFreePaymentPayload = data.paymentPayload
        } else if(data.paymentProvider == 1){
            self.freeChargePaymentPayload = data.paymentPayload['data']
        } else if(data.paymentProvider == 2){
            self.agreePayPayload = data.paymentPayload['data']
        }
    },
    updateTxStatus(txStatus){
        self.txStatus = txStatus
    },
    updatePaymentModel(data){
        self.isSuccessful = data.isSuccessful
        self.paymentNumber = data.orderId
        self.gatewayRefenceId = data.referenceId
        self.paymentSignature = data.signature
        self.txMsg = data.txMsg
        self.txTime = data.txTime
        self.paymentMode = data.paymentMode
        self.txStatus = data.txStatus
    }
}))
.views(self=>({
    getOrderModel(){
        return {
            id: self.id,
            isSuccessful: self.isSuccessful,
            paymentNumber: self.paymentNumber,
            gatewayRefenceId: self.gatewayRefenceId,
            paymentSignature: self.paymentSignature
        }
    },
    getFreeChargePayload(){
        return {
            "amount": self.freeChargePaymentPayload.amount,
            "channel": self.freeChargePaymentPayload.channel,
            "checksum": self.freeChargePaymentPayload.checksum,
            "currency": self.freeChargePaymentPayload.currency,
            "customerName": self.freeChargePaymentPayload.customerName,
            "furl": self.freeChargePaymentPayload.furl,
            "merchantId": self.freeChargePaymentPayload.merchantId,
            "merchantTxnId": self.freeChargePaymentPayload.merchantTxnId,
            "mobile": self.freeChargePaymentPayload.mobile,
            "surl": self.freeChargePaymentPayload.surl,
            "s2sUrl": self.freeChargePaymentPayload.s2sUrl
        }
    }
}))




