import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"
import { url } from "../api-list"
import { Platform } from "react-native"

export class ApiUser {

  
  async userInfo() {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.get(url.userInfo)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async userInfoUpdate(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.put(url.userInfoUpdate,param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async emailStatus(email) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    const param = { email: email,"userType": "Consumer"}
    const response: ApiResponse<any> = await apiService.post(url.emailStatus,param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    // else{
    //   if(response.data['statusCode'] = 200){
    //     signup_details['otpReferenceId'] = response.data['otpReferenceId']
    //   }
    // }

    // transform the data into the format we are expecting
    try {
    //   const resdata: registerBusiness_Model = {
    //     "otpStatus": true,
    //     "accountStatus": true,
    //     "accountId": 0,
    //     "businessInfoId": 0
    //   }
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async emailOtp(email) {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()


    // make the api call
    const param = {
      "email":email
    }

    const response: ApiResponse<any> = await apiService.post(url.emailOtp,param)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async emailVerify(param) {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()


    // make the api call
    const response: ApiResponse<any> = await apiService.post(url.emailVerify,param)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async phoneNumberStatus(phoneNumber) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    const param = {'phoneNumber': phoneNumber,"userType": "Consumer"}
    const response: ApiResponse<any> = await apiService.post(url.phoneNumberStatus,param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    // else{
    //   if(response.data){

    //     await save("businessTypeId", decoded['businessType'])
    //   }
    // }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async phoneOtp(mobileNumber) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    const param = { phoneNumber: mobileNumber}
    const response: ApiResponse<any> = await apiService.post(url.createOtp,param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      // if(response.data['statusCode'] = 200){
      //   signup_details['otpReferenceId'] = response.data['otpReferenceId']
      // }
    }

    // transform the data into the format we are expecting
    try {
    //   const resdata: registerBusiness_Model = {
    //     "otpStatus": true,
    //     "accountStatus": true,
    //     "accountId": 0,
    //     "businessInfoId": 0
    //   }
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async userImage(imageUrl:string) {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()


    let body = new FormData();
    if(Platform.OS == "web"){
      const base64Response = await fetch(imageUrl);
      const blob = await base64Response.blob();
      body.append('file', blob);
    }
    else{
      body.append('file', {uri: imageUrl,name: 'photo.png',type: 'image/png'});
    }

    const response: ApiResponse<any> = await apiService.post(url.userImage,body)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async addressAdd(param) {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const response: ApiResponse<any> = await apiService.post(url.addressAdd, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async addressUpdate(param, id) {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const putUrl = url.addressUpdate + "/" + id
    const response: ApiResponse<any> = await apiService.put(putUrl, param)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
  
  async getAllAddress() {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const response: ApiResponse<any> = await apiService.get(url.addressgGet)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
  
  async addressDelete(id) {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const deleteUrl = url.addressDelete +'/' + id
    const response: ApiResponse<any> = await apiService.delete(deleteUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}