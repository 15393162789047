import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Image, Platform } from "react-native"
import { spacing, color } from "../../theme"
import { TimePickerProps } from "./time-picker.props";
import { Text } from "../text/text"
import moment from 'moment';
import { Modal, Datepicker } from "@ui-kitten/components";
import DateTimePicker from '@react-native-community/datetimepicker';
import { Button } from "../button/button";




/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const TimePickerWidget: Component<TimePickerProps> = props => {
  const {
    title,
    getTime,
    initialTime,
    titleStyle,
    showIcon
  } = props



  const [time, setTime] = useState();

  useEffect(()=>{

    if(initialTime){
      if(Platform.OS == "web"){
        var time = moment(initialTime, 'HH:mm:ss');      
        setTime(time.format("hh:mm"))
      } else {
        var time = moment(initialTime, 'hh:mm A')
        setTime(new Date(time.toLocaleString()))
      }
    } else {
      setTime(null)
    }
  }, [initialTime])


  const [modalVisible, setModalVisibility] = React.useState(false);


  const onChange = (event) => {
    let selectedTime = event.target.value
    setTime(selectedTime)
    var timeNew = selectedTime + ':00'
    getTime(timeNew)
    // setModalVisibility(false)
  };



  const onChangeMobile = (event, selectedDate) => {
    setModalVisibility(Platform.OS === 'ios');
    setTime(selectedDate);
    getTime(moment(selectedDate).format('HH:mm:ss'))
  }
  

  const showTimepicker = () => {
    setModalVisibility(true);
  };


  const ROOT: ViewStyle = {
    paddingHorizontal: spacing[2],
    paddingBottom: spacing[2]
  }

  const INPUT_CONTAINER: ViewStyle = {
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    overflow:'hidden',
    minHeight: 44,
    borderColor: color.nuetralBlue,
  }
  
  let inputCssStyle = {
    "height": "40px",
    "padding": "0px 15px",
    "border-width": "1px",
    "border-radius": "5px",
    "overflow": "hidden",
    "border-color": color.nuetralBlue,
    "border-style": "solid",
    "color": color.grey,
    "font-size": "15px"
  }


  return (
    <View style={ROOT}>

          <TouchableOpacity onPress={showTimepicker} style={INPUT_CONTAINER}>
            { showIcon ?
              <Image style={{width: 20, height: 20, marginHorizontal: 15}} source={require('./clock.png')} /> : null
            }
            { time?
              <Text>{Platform.OS == "web" ? moment(time, 'HH:mm').format('hh:mm A') : moment(time).format('hh:mm A')}</Text> :
              <Text style={{color: color.grey}}>{title}</Text>
            }
          </TouchableOpacity>


          <Modal visible={modalVisible}
            backdropStyle={backdrop}
            onBackdropPress={() => setModalVisibility(false)}>

            <View style={{alignItems: "center"}}>
              { Platform.OS == "web" ?
                <View style={{paddingVertical:40, paddingHorizontal:60, backgroundColor:'white', borderRadius:15}}>
                  <input 
                    placeholder="Time" 
                    style={inputCssStyle} 
                    type="time" 
                    value={time ? time : moment().format("hh:mm")}
                    onChange={(event)=>onChange(event)}
                    />
                  <View style={{marginTop:30, marginHorizontal:50, alignSelf: "center"}}>
                    <Button
                        preset="primary"
                        text="Close"
                        onPress={()=>setModalVisibility(false)}/>
                  </View>
                </View>
                :
                <DateTimePicker
                  testID="dateTimePicker"
                  value={time ? time : new Date()}
                  mode='time'
                  is24Hour={false}
                  display="default"
                  onChange={onChangeMobile}
                />
              }
            </View>
          </Modal>


    </View>
  )
}


const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: 8
}


const backdrop: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}