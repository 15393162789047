export const icons = {
  ellam_icon: require("./ellam.png"),
  
  back: require("./back-icon.png"),
  bullet: require("./back-icon.png"),
  left: require("./back-icon-solid.png"),
  close: require("./times-solid.png"),

  user: require("./User.png"),
  mail: require("./Message.png"),
  phone: require("./Phone.png"),
  lens_blue: require("./Lens.png"),
  trash: require("./Trash.png"),


  config_selected: require("./config-selected.png"),
  config_unselected: require("./config-unselected.png"),
  account_selected: require("./account-selected.png"),
  account_unselected: require("./account-unselected.png"),
  dashboard_selected: require("./dashboard-selected.png"),
  dashboard_unselected: require("./dashboard-unselected.png"),
  ellam_selected: require("./ellam-selected.png"),
  ellam_unselected: require("./ellam-unselected.png"),
  cart_unselected: require("./cart-unselected.png"),
  cart_selected: require("./cart-selected.png"),

  location_picker: require("./location_pin.png"),

  success_icon: require("./success-icon.png"),
  warning_icon: require("./warning-icon.png"),
  error_icon: require("./error-icon.png"),

  update_icon: require("./update-icon.png"),
  wifi_icon: require("./wifi-icon.png"),


  empty_cart: require("./empty-cart.png"),
  temple: require("./temple.png"),
  prarthana: require("./prarthana.png"),

  ellam_stores_logo: require("./open-kitchen/logo.png"),
  prarthana_logo: require("./prarthana/logo.png"),
  saloon_spa_logo: require("./saloon-spa/logo.png"),
  community_services_bill_payment_logo: require("./community-services/logo.png"),
  parking_logo: require("./parking/logo.png"),

}

export type IconTypes = keyof typeof icons
