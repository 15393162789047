import { types } from "mobx-state-tree";


const customFieldsModel = types
  .model()
  .props({
    FieldName: types.optional(types.string, ""),
    FieldValue: types.optional(types.string, ""),
    IsMandatory: types.optional(types.boolean, false),
  })


export const InventoryModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    amount: types.optional(types.number, 0),
    bookingDate: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    message: types.optional(types.string, ""),
    customFields: types.optional(types.array(customFieldsModel),[]),

})
.actions(self=>({
    create(cartModel){
        self.id = cartModel.id
        self.name = cartModel.name
        self.amount = cartModel.amount
        self.bookingDate = cartModel.bookingDate
        self.description = cartModel.description
        self.message = cartModel.message
        self.customFields = cartModel.customFields
    },
    updateId(id){
        self.id = id
    },
    updateName(name){
        self.name = name
    },
    updateAmount(amount){
        self.amount = amount
    },
    updateBookingDate(date){
        self.bookingDate = date
    },
    updateDescription(description){
        self.description = description
    },
    updateMessage(message){
        self.message = message
    },
    updateCustomFields(customFields){
        self.customFields = customFields
    },

}))

