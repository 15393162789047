import { getGeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { Api } from "../api"
import { url } from "../api-list";

export class ApiBusiness {

  async getBusinessTypes()  {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    const response: ApiResponse<any> = await apiService.get(url.getBusinessServiceTypes)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}