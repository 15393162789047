// This model used to store local state while running the application
import { save } from "../../utils/storage"

export const shared ={
    SignIn : true,
    WelcomeScreenState : 1
}

export function setStorage(key, value){
    save(key, value)
}

export const isToast = true

export function getToast(){
    return isToast
}