import { types } from "mobx-state-tree";

export const UpiPaymentModel = types
.model()
.props({
    expiry_datetime: types.maybeNull(types.optional(types.string, "")),
    order_id: types.maybeNull(types.optional(types.string, "")),
    url: types.maybeNull(types.optional(types.string, "")),
    uuid: types.maybeNull(types.optional(types.string, "")),
    vpa: types.maybeNull(types.optional(types.string, "")),
})
.actions(self=>({
    create(data){
        self.expiry_datetime = data.expiry_datetime
        self.order_id = data.order_id
        self.url = data.url
        self.uuid = data.uuid
        self.vpa = data.vpa
    },
    reset(){
        self.expiry_datetime = null
        self.order_id = null
        self.url = null
        self.uuid = null
        self.vpa = null
    }
}))




