import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, Text, TouchableOpacity } from "react-native"
import { OrdersCardProps } from "./order-card.props"
import { spacing, color } from "../../../../theme"
import moment from "moment";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing[2],
    alignItems: "center",
    paddingBottom: spacing[5],
}

const CARD_CONTAINER: ViewStyle = {
    borderWidth: 1,
    borderColor: color.nuetralBlue,
    width: '100%',
    borderRadius: 10,
    padding: 10,
    paddingHorizontal: 20,
}

export const OrderCard: Component<OrdersCardProps> = props => {
  const {
    itemsCount,
    orderObject,
    onCardPress,
    style,
  } = props
  
  const stringToDate = (str) => {
      if(Date.parse(str)){
        let datestr = new Date(Date.parse(str))
        return datestr.toDateString()
      } else {
          return ''
      }
  }

  const statusBadge = (orderStatus) => {
    
    var status = orderStatus == "PendingDelivery" ? "Accepted" 
    : orderStatus == "PendingApproval" ? "Pending Approval" 
    : orderStatus == "PendingPayment" ? "Pending Payment"
    : orderStatus

    if(status == 'Pending'){
      return <Text style={{backgroundColor: '#D6E5FC', color: '#347BF1', borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}> Payment Complete </Text>;
    } else if (status == "Accepted") {
      return <Text style={{backgroundColor: '#FFF3CD', color: '#FFC107', borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}> {status}  </Text>;
    } else if (status == "Completed") {
      return <Text style={{backgroundColor: '#E3F2E3', color: '#75BF72', borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}> {status}  </Text>;
    } else if (status == "Failed") {
        return <Text style={{backgroundColor: '#FFD1D1', color: '#FF5B5B', borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}> {status}  </Text>;
    } else if (status == "Pending Approval") {
      return <Text style={{backgroundColor: color.redStatusBg, color: color.redStatus, borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}>{status}</Text>;
    } else if (status == "Pending Payment") {
        return <Text style={{backgroundColor: color.purpleStatusBg, color: color.purpleStatus, borderRadius: 10, fontSize: 12, fontWeight: 'bold', padding: 2, paddingHorizontal:8}}>{status}</Text>;
    } else {
        return <Text> {orderStatus}  </Text>
    }
  }



  return (
    <View>
      { "history" in orderObject  && orderObject["history"][0] ?
        <View style={{ ...ROOT, ...style }}>
          <TouchableOpacity style={{...CARD_CONTAINER}} onPress={onCardPress}>
              <View style={{flexDirection:'row', justifyContent:'space-between', width:"100%", paddingVertical:5}}>
                  <Text style={{fontSize:12}}>Date: {moment(orderObject["history"][0]["createdOn"]).format('MMM DD YYYY hh:mm A')}</Text>
                  { statusBadge(orderObject["history"][0]["orderStatus"]) }
              </View> 
              <View style={{flexDirection:'row', justifyContent:'space-between', paddingVertical:5, flexGrow:1}}>
                  <Text style={{fontWeight:"bold",textAlign:'left', width:"95%"}}>{orderObject["businessName"]}  </Text>
                  <Text> </Text>
              </View>
              <View style={{borderColor: color.line, borderWidth: 1, width: '100%', marginVertical: 5, borderStyle: 'dashed', borderRadius: 1}} />

              <View style={{flexDirection:'row', justifyContent:'space-between', width:"100%", paddingVertical:5}}>
                  <Text style={{color:color.dim}}>Items</Text>
                  <Text style={{color:color.dim}}>{itemsCount}</Text>
              </View>
              <View style={{flexDirection:'row', justifyContent:'space-between', width:"100%", paddingVertical:5}}>
                  <Text style={{color:color.dim}}>Total Amount</Text>
                  <Text style={{color:color.ananthamBlue, fontWeight:'bold',minWidth:100,textAlign:'right'}}>₹ {orderObject["totalAmount"] && orderObject["processingFee"] ? (orderObject["totalAmount"]+orderObject["processingFee"]).toFixed(2): '--'}</Text>
              </View>
          </TouchableOpacity>
        </View> 
        : null
      }
    </View>
  )
}

