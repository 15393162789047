import React, { FunctionComponent as Component, useCallback } from "react"
import { Text, View, ViewStyle, TouchableOpacity, Alert, Linking } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Icon } from "../../components";
import { color } from "../../theme";
import { Ionicons } from "@expo/vector-icons";
import Constants from 'expo-constants';
const { T_AND_C_URL } = require("../../config/env")


export const HelpAndSupportScreen: Component = observer(function HelpAndSupportScreen() {
  
  const OpenURLButton = ({ url, children }) => {
      const handlePress = useCallback(async () => {
        // Checking if the link is supported for links with custom URL scheme.
        // const supported = await Linking.canOpenURL(url);

        // if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(url);
        // } else {
        //   Alert.alert(`Invalid Reqest`);
        // }
      }, [url]);

      return (
        <TouchableOpacity  onPress={handlePress}>
            <Text style={{color: color.ananthamBlue}}>{children}</Text>
        </TouchableOpacity>
      );
  };
  
  return (
    <Screen preset="fixed" headerTitle="Help & Support" showheaderTitle={true} showBackButton={true}>

        <View style={ROOT_CONTAINER}>

          <View  style={{marginTop: 30, alignItems:'center'}}>
              <View style={{alignItems:'center', marginTop: 10, backgroundColor: color.black, borderRadius: 20, padding: 20, width:100}}>
                  <Icon icon={'ellam_icon'} style={{width: 60, height: 60}}></Icon>
              </View>
              <Text style={{width:100,textAlign: 'center', marginTop: 15, fontSize: 20, fontWeight: 'bold'}}>
                  Ellam  
              </Text>
              <Text style={{textAlign: 'center',width:200, marginTop: 5, fontSize: 14, fontWeight: 'normal', color: color.dim}}>
                  Consumer Version {Constants.manifest.version}  
              </Text>
          </View>

        </View>

        <View style={CONTAINER}>
            <Text style={{fontSize: 18, marginTop: 10, marginBottom:20, fontWeight: 'bold'}}>Contact Us</Text>

            <View style={{flexDirection :'row', marginVertical: 5}}>
                <Text>Email Us : </Text><OpenURLButton url={'mailto: info@ellam.in'}>info@ellam.in</OpenURLButton>
            </View>
            <View style={{flexDirection :'row', marginVertical: 5}}>
                <Text>Contact Number : </Text><OpenURLButton url={'tel:+916282826916'}>+91 6282826916</OpenURLButton>
            </View>
        </View>

        <TouchableOpacity onPress={()=> Linking.openURL(T_AND_C_URL)}
                          style={[CONTAINER, {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}]}>
            <Text style={{fontSize: 14, fontWeight: 'bold'}}>Legal, Terms & Conditions    </Text>
            <Ionicons name="ios-arrow-forward" size={24} color="black" />
        </TouchableOpacity>

    </Screen>
)
})

const ROOT_CONTAINER: ViewStyle = {
  backgroundColor: color.background,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center"
}

const CONTAINER: ViewStyle = {
  borderColor: color.nuetralBlue,
  borderWidth: 1,
  backgroundColor: color.white,
  padding: 20,
  marginTop: 15,
  borderRadius: 10,
  marginHorizontal: 30
}


