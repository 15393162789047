import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ViewStyle, Platform, TextStyle, Image } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Icon } from "../../components"
import { color } from "../../theme"
import { useStores } from "../../models/root-store/root-store-context";
import { ApiSignup, ApiSignin } from "../../services/api"
import { ApiUser } from "../../services/api/api-methods/api-user"
const appConfig = require('../../../app.json');
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

if(Platform.OS != "web"){
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}


export const VerifyotpScreen: Component = observer(function VerifyotpScreen(props) {

  const { userStore } = useStores()
  const [errormsg, setErrormsg] = useState("")
  const [loader, setLoader] = useState(false)

  let [resendOtp,setRresendOtp] = useState(false)
  let [resendOtpTime,setRresendOtpTime] = useState(30)
  
  const loaderImage = require('../../../assets/preloader1.gif')

  useEffect(()=>{
    let interval = setInterval(() => {
      if(resendOtpTime>0){
        setRresendOtpTime(--resendOtpTime)
      }
      else{
        setRresendOtp(true)
        clearInterval(interval)
      }
     }, 1000);
  }, [resendOtp])


  // navigations
  const navigation = useNavigation()

  // Functions
  const responseWork = async (res) => {
    setLoader(false)
    if(res['kind'] == "ok"){
      userStore.updateTokens(res['data'])
      userStore.updateIsSignIn(true)
      
      if(userStore.sharedInfo == 'navBackToCart'){
        userStore.updateSharedInfo('')
        navigation.navigate("cart_from_service")
      }
      else if(userStore.isWebUrl){
        navigation.navigate("account_from_service")
      } else {
        userStore.updateSharedInfo('')
        navigation.navigate("home", {screen: "account"})
      }
      userInfoApi()
      setErrormsg('')
    }
    else{
      setErrormsg('Invalid OTP')
    }

  }


  const verifyOtp = async () => {
    setLoader(true)
    if(userStore.otp.length==4){
      setErrormsg('')
      if(Platform.OS != "web"){
        await registerForPushNotificationsAsync()
      }
      if(userStore.newUser){
        registerUserApi()
      } else{
        signInApi()
      }
    }
    else{
      setErrormsg('Invalid OTP')
      setLoader(false)
    }
  }



  const userInfoApi = () => {
    const api = new ApiUser ()
    api.userInfo().then(res => { 
      if(res['kind'] == "ok"){
        if(res['data']){
          userStore.updateUserInfo(res['data'])
        }
      }
    })
  }

  const registerUserApi = () => {
    const api = new ApiSignup ()
    api.registerUser(userStore.getRegisterModel()).then(res => { 
      responseWork(res)
    })
  }

  const signInApi = () => {
    const api = new ApiSignin ()
    api.signIn(userStore.getLoginModel()).then(res => { 
      responseWork(res)
    })
  }

  const resendOtpApi = () => {
    setRresendOtp(false)
    setRresendOtpTime(30)
    const api = new ApiSignin ()
    api.resendOtp(userStore.getLoginModel()).then(res => { 
      if(res['kind'] == "ok"){
        let data = res['data']
        userStore.updateOtpReferenceId(data)
      }    
    })
  }

  const registerForPushNotificationsAsync = async () => {
    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
    }

    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          console.log('Failed to get push token for push notification!');
          return;
        }
        const projectId = appConfig?.expo?.extra?.eas?.projectId;
        await Notifications.getExpoPushTokenAsync({projectId}).then(token=> {
            userStore.updatePushToken(token.data)
            userStore.updateDeviceOs(Platform.OS)
        })
        .catch(err=>{console.log(err)});

    } else {
        console.log('Must use physical device for Push Notifications');
    }
}
  

  return (
    <Screen preset="scroll" headerTitle="Verify Details" showheaderTitle={true} showBackButton={true}>
      
      <View style={CONTAINER}>
          
          {/* <View></View> */}
          
          <View style={{ alignItems: 'center', marginBottom: 40}}>
            <View style={{alignItems:'center', marginVertical: 10, backgroundColor: color.black, borderRadius: 20, padding: 15, width: 80}}>
                <Icon icon={'ellam_icon'} style={{width: 50, height: 50}}></Icon>
            </View>
            <Text preset="boldLarge">Verify OTP</Text>
            <Text preset="fieldLabel" style={{marginTop: 10}}>OTP sent to {userStore.loginId}</Text>
          </View>  

          {/* <View style={{marginBottom: 10, flexDirection: 'row'}}>
            <Text>Enter the OTP sent to </Text> <Text style={{fontWeight: 'bold'}}>{userStore.loginId}</Text>
          </View> */}

          <View style={{marginBottom:20}}>
            <TextField errorMessage={errormsg} placeholder='Enter Your OTP' icon='phone' 
                            keyboardType='numeric' getText={text => userStore.updateOtp(text)}></TextField>
          </View>

          <Text style={{marginBottom: 40, ...SUB_TITLE}}>
            Didn’t receive the OTP?
            { resendOtp?
                <Text style={{color: color.primary}} onPress={() => resendOtpApi()}>
                  {' '} Resend OTP
                </Text>
                :
                <Text style={{color:color.dim}}>
                  {' '} Resend OTP (00:{resendOtpTime<10?'0'+resendOtpTime:resendOtpTime})
                </Text>
            }
          </Text>

          <View style={{marginBottom: 40, width: 400, maxWidth: "100%", alignItems: 'center'}}>
            { !loader ? 
              <Button
                style={{minWidth: "100%"}}
                preset="primary"
                text="CONFIRM"
                onPress={verifyOtp}
              /> :
              <Image style={{width: 35, height: 35}} source={loaderImage}/>
            }

          </View>


        
      </View>

    </Screen>

  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  // justifyContent: 'flex-start',
  alignItems: "center",
  backgroundColor: color.white,
  marginTop: 80,
  padding: 20,
}



const  SUB_TITLE: TextStyle = {
  width: "100%",
  color: color.text,
  textAlign: "center"
}
