import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Text} from "../../components";



export const TermsAndConditionsScreen: Component = observer(function TermsAndConditionsScreen() {
  
  
  return (
    <Screen preset="fixed" headerTitle="Terms & Conditions" showheaderTitle={true} showBackButton={true}>

        <View style={{flexDirection: "column", margin: 15}}>
            <View style={{flexDirection: "column"}}>
            
              <View style={VIEW_MARGIN}>
            <Text style={{ fontWeight: "bold" }}>Ellam Applications for Supplier and Consumers, Terms of Use v3.1.2 dates 14Mar2024</Text>
              </View>

              <View>
                <Text>
              This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions
              pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the
              provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy
              and Terms of Use for access or usage of www.ellam.in website and ELLAM applications (“ELLAM” for Consumers and “ELLAM for Enterprises” for
              Organizations) owned by Anantham Online Pvt Ltd for mobiles and handheld devices.
                </Text>
              </View>

              <View style={VIEW_MARGIN}>
                <Text style={{fontWeight: "bold"}}>Terms of Use</Text>
              </View>

              <View>
                <Text>
              1. These terms of use (the "Terms of Use") govern your use of our website www.ellam.in (the "website") and application "ELLAM"and/or “ELLAM for
              Enterprises” for mobile and handheld devices (the "App"). The Website and the App are jointly referred to as the "Platform". Please read these Terms of Use
              carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to
              uninstall the App or not use the website. The Platform is owned, maintained and operated by Anantham Online Private Limited, further referred to as the
              “Company”, a private limited company incorporated under the Companies Act, 2013 and having its registered office at House No 3, North Girinagar, Kadavanthra,
              Kochi - 682020, Kerala, India.
              {"\n"}
              2. For the purpose of these Terms of Use, wherever the context so requires, "you", “user”, “users”, “Users”, “Consumer” or “User” shall mean any natural or
              legal person who shall transact on the Platform by providing registration data while registering on the Platform as a registered user. The terms "ELLAM",
              “ELLAM For Business”, "we", "us" or "our" shall mean Anantham Online Private Limited. The term “SUPPLIER” or “MERCHANT” means the legal entity or
              individual or person who offers product and/or services to the users. Both these terms are interchangeably used or called collectively as either “SUPPLIER” or
              “MERCHANT”. Further the term “Domain” or “domain” shall mean the area of business on which suppliers offers their product and/ or services categorically
              for the users for their purchase and use.
              {"\n"}
              3. By installing, downloading or even merely using the Platform, you shall be contracting with and agree to the “Company” that you signify your acceptance to
              this Terms of Use and other ELLAM policies (including but not limited to the Cancellation & Refund Policy and Privacy Policy) as posted on the Platform and
              amended from time to time, which takes effect on the date on which you download, install or use the Platform, and create a legally binding arrangement to
              abide by the same.
              {"\n"}
              4. ELLAM enables transactions on its Platform between participating Suppliers and buyers from different domains such as (a) Temples, (b) food and
              beverages, (c) consumer goods, and (d) other products and services ("Platform Services")that may be onboarded in future. The buyers ("Buyer/s") can
              choose from listed product and / or services and place orders ("Orders") offered for sale by various merchants/ suppliers.
              {"\n"}
              5. ELLAM enables a facility for the delivery of products that the users have placed orders for, through this platform from various suppliers. Third party delivery
              service providers or the suppliers themselves act as the delivery service providers / partners who will be responsible for the pick-up and delivery services and
              completing respective tasks for the orders initiated by the users of the Platform (Buyers). The Platform Services and Delivery Services are collectively referred
              to as "Services". For both Platform and Delivery Services, ELLAM is merely acting as an intermediary between the Suppliers and Buyers and/or Delivery
              Partners and Buyers and/or Suppliers.
              {"\n"}
              6. Suppliers/ Merchants or the delivery partners may add an additional charge for the delivery services. A service fee (inclusive of applicable taxes whenever
              not expressly mentioned) is determined on the basis of various factors including but not limited to distance covered, time taken, demand for delivery
              services/Tasks, real time analysis of traffic and weather conditions, seasonal peaks or such other parameters as may be determined from time to time.
              {"\n"}
              7. These Terms of Use are subject to modifications. We, Anantham Online Pvt Ltd, reserve the right to modify or change these Terms of Use and other Ellam
              policies at any time by posting modified documents on the Platform and notifying you of the same for your perusal. You shall be liable to update yourself of
              such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other Ellam policies and
              note the changes made on the Platform. As long as you comply with these Terms of Use, Ellam grants you a personal, non- exclusive, non-transferable,
              limited privilege to access, enter, and use the Platform.
                </Text>
              </View>
              
              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Use of Platform and Services</Text>
              </View>

              <View>
                <Text>
              1. All commercial/contractual terms are offered by and agreed to between Buyers and Suppliers and/or Merchants alone; with respect to products and
              services being offered by the Suppliers. The commercial/contractual terms include without limitation price, applicable taxes, shipping costs –where
              applicable, payment terms, date, period and mode of delivery, warranties –where applicable related to products and services and after sales services related
              to products and services.
              {"\n"}
              2. We do not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms
              between the Buyers and Suppliers/Merchants. Ellam may, however, offer support services to Suppliers with respect to Order fulfilment, mode of payment,
              payment collection, call centre support and other ancillary services, pursuant to independent contracts executed by Ellam with the Suppliers. The price of the
              product and services offered by the Supplier are determined by the Supplier itself and Ellam has no role to play in such determination of price in any way
              whatsoever.
              {"\n"}
              3. ELLAM does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Suppliers/
              Delivery Partners. You are advised to independently verify the bona fides of any particular Supplier that you choose to deal with in the Platform and use your
              best judgment on that behalf. All Supplier offers and third-party offers are subject to respective party terms and conditions. ELLAM takes no responsibility for
              such offers.
              {"\n"}
              4. ELLAM neither make any representation or warranty as to specifics (such as quality, value, availability, etc.) of the products or services proposed to be sold
              or offered to be sold or purchased on the Platform nor does it implicitly or explicitly support or endorse the sale or purchase of any products or services on
              the Platform. ELLAM accepts no liability for any errors or omissions, whether on behalf of itself or third parties. Also, ELLAM does not make any
              representation or warranty with respect to the product and services provided by the service/ delivery partners.
              {"\n"}
              5. ELLAM is operating as an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer
              and Supplier and/or Buyer and Delivery partner on the Platform come into or take possession of any of the products or services offered by Supplier or Delivery
              Partner. At no time shall ELLAM hold any right, title or interest over the products nor shall ELLAM have any obligations or liabilities in respect of such contract
              entered into between Buyer and Supplier and/or Buyer and the Delivery Partner.
              {"\n"}
              6. ELLAM is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly
              bipartite contract between the Supplier and the Buyer. In case of complaints from the Buyer pertaining to efficacy, quality, or any other such issues, ELLAM
              shall notify the same to Supplier and shall also redirect the Buyer to the consumer to the call centre / contact of the Supplier as registered with ELLAM. The
              Supplier / Delivery Partner shall be liable for redressing Buyer complaints.
              {"\n"}
              7. Please note that there could be risks in dealing with underage persons or people acting under false pretence.

                  <View style={VIEW_MARGIN}>
                <Text style={{ fontWeight: "bold" }}>Terms and Conditions to Use ELLAM’s Website and Mobile App – Supplier
                        {"\n"}{"\n"}
                      </Text>
                        
                      <Text>
                  You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles:
                      </Text>
                      <View style={{marginHorizontal:20, marginTop: 10}}>
                          <Text>
                    1. You shall not host, display, upload, download, modify, publish, transmit, update or share any information which:
                    <View style={{ marginHorizontal: 20, marginTop: 10, marginBottom: 10 }}>
                                  <Text>
                        1.1. belongs to another person and which you do not have any right to;
                        {"\n"}
                        1.2. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, slanderous, criminally inciting or invasive
                        of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or
                        otherwise, unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent
                        representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
                        {"\n"}
                        1.3. is misleading or misrepresentative in any way;
                        {"\n"}
                        1.4. is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry,
                        hatred or physical harm of any kind against any group or individual;
                        {"\n"}
                        1.5. harasses or advocates harassment of another person;
                        {"\n"}
                        1.6. involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
                        {"\n"}
                        1.7. promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;
                        {"\n"}
                        1.8. infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without
                        limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;
                        {"\n"}
                        1.9. contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
                        {"\n"}
                        1.10. provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
                        {"\n"}
                        1.11. provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing
                        or creating computer viruses;
                        {"\n"}
                        1.12. contains video, photographs, or images of another person (with a minor or an adult);
                        {"\n"}
                        1.13. tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account
                        information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or
                        unlawful purposes from other users;
                        {"\n"}
                        1.14. engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and
                        pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms of Use, ELLAM’s prior written consent
                        means a communication coming from ELLAM’s Legal Department, specifically in response to your request, and expressly addressing and allowing
                        the activity or conduct for which you seek authorization;
                        {"\n"}
                        1.15. solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
                        {"\n"}
                        1.16. interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;
                        {"\n"}
                        1.17. refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains
                        content that would be prohibited or violates the letter or spirit of these Terms of Use;
                        {"\n"}
                        1.18. harm minors in any way;
                        {"\n"}
                        1.19. infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or
                        shall not be fraudulent or involve the sale of counterfeit or stolen products;
                        {"\n"}
                        1.20. violates any law for the time being in force;
                        {"\n"}
                        1.21. deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or
                        menacing in nature;
                        {"\n"}
                        1.22. impersonate another person;
                        {"\n"}
                        1.23. threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes
                        incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;
                        1.24. is false, inaccurate or misleading;
                        {"\n"}
                        1.25. directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any
                        manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or
                        {"\n"}
                        1.26. creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.
                                  </Text>
                                </View>
                    2. You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the
                    Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other
                    illegitimate means.
                    {"\n"}
                    3. You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication
                    measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other
                    user of or visitor to Platform, or any other Buyer, including any account on the Platform not owned by you, to its source, or exploit the Platform or any
                    service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not
                    limited to personal identification or information, other than your own information, as provided for by the Platform.

                    {"\n"}4. You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us
                    including the name “ELLAM”, or otherwise engage in any conduct or action that might tarnish the image or reputation, of “ELLAM” or Supplier on
                    platform or otherwise tarnish or dilute any “ELLAM’s” trade or service marks, trade name and/or goodwill associated with such trade or service marks,
                    as may be owned or used by us.
                    {"\n"}
                    5. You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited
                    to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited
                    commercial or junk email to other users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform
                    in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior
                    explicit consent. In addition, we can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other
                    government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible
                    crimes, especially those that may involve personal injury.
                    {"\n"}
                    6. We reserve the right, but has no obligation, to monitor the materials posted on the Platform. ELLAM shall have the right to remove or edit any content
                    that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this
                    right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE
                    MESSAGES. Please be advised that such Content posted does not necessarily reflect ELLAM views. In no event shall ELLAM assume or have any
                    responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the
                    Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and
                    that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information.

                    {"\n"}7. It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and
                    that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you
                    due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses,
                    but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or
                    share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.
                    8.ELLAM shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your
                    own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without
                    our prior consent or a mutual legal agreement.
                          </Text>
                      </View>
                  
                  </View>
                </Text>
              </View> 

              <View style={VIEW_MARGIN}>
            <Text style={{ fontWeight: "bold" }}>Account Registration – Consumer : Terms & Conditions</Text>
              </View>

              <View>
                <Text>
              1. You may access the Platform by registering an account ("ELLAM Consumer Account") using your mobile number, email id, name and address.

              {"\n"}2. We will create your ELLAM Consumer Account for your use of the Platform services based upon the personal information you provide to us. You shall have
              only one ELLAM Account and are not permitted to create multiple accounts. ELLAM reserves the right to suspend such multiple accounts without being liable
              for any compensation where you have created multiple accounts on the Platform.

              {"\n"}3. You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current
              and complete.
                  {"\n"}
              4. Your access to the application is enabled through an OTP based validation. The session is remained valid until the consumer clears app history or ask for
              logout from the application. Hence it is the soul responsibility of the consumer to ensure unauthorized access to his/her account by accessing his/her mobile.

              {"\n"}5. In case customer wants to logoff from the application during an unpleasant even of the loss of his/her handset or being doubtful of unauthorized access of
              his account, he/she should immediately contact the support team of ELLAM either directly or through the means of contact provided in its website
              (www.ellam.in) to disable consumer account details.
                  {"\n"}
              6. Goods and services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not
              acting as an agent for other parties.
                  </Text>
              </View>

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Order Booking and Financial Terms</Text>
              </View>

              <View>
                <Text>
              1. The Platform allows the Buyers/ Consumers to place Orders and upon acceptance of such Orders by the Suppliers/Merchants, ELLAM will, subject to the
              terms and conditions set out herein, facilitates real time update of the status as been confirmed and updated by the authorized user/s of the supplier/
              merchant.
                  {"\n"}
              2. ELLAM does not own, sell, resell on its own such products offered by the Supplier/Merchant, and/or does not control the Suppliers/Merchants or the related
              services provided in connection thereof. As a general rule, all Orders placed on the Platform are considered ‘Ordered’ unless the supplier on the other side,
              confirms the acceptance and the completion (in case of services) of the services. In case of any delivery services, being requested by the Consumer during
              order booking, it is the sole responsibility of the supplier to arrange the same and ensure that the product is being delivered to the consumers on time.

              {"\n"}3. However, upon Buyer’s successful completion of booking an Order, we shall share an alert in the form of notification along with receipts been automatically
              been made available in their application.
                  {"\n"}
              4. In addition to the foregoing, we may also contact you by phone and / or email to inform and confirm any change in the Order, due to availability or
              unavailability or change in Order or change in price of any item in the Order as informed by the Supplier/Merchant. Please note that any change or
              confirmation of the Order shall be treated as final.
                  {"\n"}
              5. All payments made against the Orders or Services on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. You
              understand, accept and agree that the payment facility provided by ELLAM is neither a banking nor financial service but is merely a facilitator enabling an
              electronic, online payment gateway using the existing authorized banking infrastructure and credit card payment gateway networks.

              {"\n"}6. Buyer agrees to pay for the total amount for the Order placed on the Platform. ELLAM will collect the total amount in accordance with these Terms of Use
              and the pricing terms set forth in the applicable listing of product or restaurant service for the particular Supplier/Merchant, apart from the delivery fees for
              Delivery Services where applicable and a processing fee.
                  {"\n"}
              7. In connection with Buyer’s Order, he/she will be asked to provide customary billing information such as name, billing address and credit card information
              either to us or our third-party payment processor. While being redirected to the third-party payment processor, he/she may be subject to terms and
              conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions
              and privacy policy before using the Platform services. Once the Order is confirmed you will see a status of 'Payment Complete' in the website or the app.

              {"\n"}8. The prices of the product and services reflected on the Platform, including packaging or handling charges, are determined solely by the Supplier/merchant
              and are listed based on Supplier’s / Merchant’s information. The transactions are bilateral between the Supplier and Buyer.
                  </Text>
              </View> 

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Cancellations and Refunds</Text>
              </View>

              <View>
                <Text>
              1. The platform does not offer any cancellation facility of orders for the temple services. When an order is placed (Offering) for a temple for a particular date,
              the temple authorities, on their discretion, shall propose to modify the date of that order based on the possibility / feasibility to perform the offering (pooja) at
              the temple. In such a scenario, the order for the offering shall be executed by the respective temple on the new date as proposed by the temple authorities
              instead of the actual order date requested by the consumer.
              {"\n"}
              2. You agree that, in such as scenario, you shall not claim for any refund or cancellation of the order submitted through ELLAM.

              {"\n"}3. Once submitted, its not possible to change the temple for which the order is created. You shall not claim any refunds or request for any cancellation or
              modification of the order to change the temple for which the order has been created.
              {"\n"}
              4. Refunds for the payments, if not processed by the respective banks of the consumer is refunded by the bank itself. You completely understand the fact
              that we ELLAM is not responsible for any payment failures at the side of the consumer’s bank.
              {"\n"}
              5. Ellam reserves the right to change or modify the cancellation and refund policies time to time and for domains to domain.
                  </Text>
              </View>

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Terms of service</Text>
              </View>

              <View style={{flexDirection: "column"}}>
                  <Text>
              1. The Buyer agrees and acknowledges that ELLAM shall not be responsible for:
                    </Text>
            <View style={{ marginHorizontal: 20, marginTop: 10, marginBottom: 10 }}> 
                      <Text>
                1.1. The services or goods provided by the Supplier/Merchant including but not limited to serving of food Orders suiting your requirements and taste;

                {"\n"}1.2. The Supplier’s/ Merchant's services or goods not being up to Buyer expectations or leading to any loss, harm or damage to him/her;

                {"\n"}1.3. The availability or unavailability of certain items on the menu;
                {"\n"}
                1.4. The Merchant serving the incorrect Orders; or
                {"\n"}
                1.5. Product liability of goods provided by Merchants.
                      </Text>
                    </View>
                    <Text>
              2. The details of the menu and price list available on the Platform with respect to Temple services, goods or any other services are based on the information
              provided by the Supplier/Merchant and ELLAM shall not be responsible for any change or cancellation or unavailability.
              {"\n"}3. Buyers and Merchants agree and acknowledge that ELLAM is not responsible for any liability arising out of delivery services provided by Delivery partner or
              the Supplier/Merchant themselves to the Buyers.
              {"\n"}4. Buyers may not be able to avail Services if their delivery location is outside ELLAM’s current scope of Service. ELLAM will keep the Buyer informed of the
              same at the time of confirming his/her Order booking.
              {"\n"}5. The Buyer understands that ELLAM’s liability ends once Order has been delivered to him/her by the delivery partner.
              <View style={VIEW_MARGIN}>
                      <Text>Services provided:</Text>
                        <View style={{marginHorizontal: 20}}>
                          <Text>
                    1. Buyer shall be required to provide credit or debit card details to the approved payment gateways while making the payment on the Platform. In this
                    regard, Buyer agrees to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. Buyer shall
                    not use the credit/ debit card which is not lawfully owned by Buyer, i.e. in any transaction, Buyer must use his/her own credit/ debit card. The
                    information provided by the Buyer will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation
                    or court order. Buyer shall be solely responsible for the security and confidentiality of his/her credit/ debit card details. We expressly disclaim all
                    liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.

                    {"\n"}2. ELLAM does not offer any refunds against goods or services already purchased from a Merchant through the Platform unless an error that is directly
                    attributable to ELLAM has occurred during the purchase of such product or services.
                    {"\n"}
                    3. We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion,
                    contact you with further instructions.
                            </Text>
                        </View>
                      </View>
                    </Text>
              </View>

              <View style={VIEW_MARGIN}>
            <Text style={{ fontWeight: "bold" }}>General: Terms & Conditions</Text>
              </View>

              <View>
                <Text>
              1.Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not
              eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and
              you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy.
              Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and ELLAM policies, you shall immediately discontinue its use. ELLAM
              reserves the right to terminate your account or deny access to the platform if it is brought to ELLAM’s notice that you are under the age of 18 years.

              {"\n"}2.As we are providing services in the select cities in India, we have complied with applicable laws of India in making the Platform and its content available to
              you. We make no representation that the Platform and its contents are available or otherwise suitable for use outside select cities. If you choose to access or
              use the Platform from or in locations outside select cities, you do so on your own and shall be responsible for the consequences and ensuring compliance of
              applicable laws, regulations, byelaws, licenses, registrations, permits, authorizations, rules and guidelines.

              {"\n"}3.You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and ELLAM
              policies to the attention of all such persons accessing the Platform on your computer or mobile device.

              {"\n"}4.You agree and grant permission to ELLAM to receive promotional SMS and e-mails from ELLAM or allied partners. In case you wish to opt out of receiving
              promotional SMS or email please send a mail to info@ellam.in.
              {"\n"}
              5.By using the Platform you represent and warrant that All registration information you submit is truthful, lawful and accurate and that you agree to maintain
              the accuracy of such information.
              {"\n"}
              6.Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address
              and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with
              other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy,
              international communications and the transmission of technical or personal data.
              {"\n"}
              7. Reviews, Feedback, Submissions - All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered
              to ELLAM directly or otherwise disclosed, submitted or offered in connection with your use of this Site (collectively referred to "Comments") will remain
              ELLAM property. Such disclosure, submission or offer of any comments shall constitute an assignment to ELLAM of all worldwide rights, titles and interests in
              all copyrights and other intellectual properties in the comments, thus, it exclusively owns all such rights, titles and interests and shall not be limited in any way
              in its use, commercial or otherwise. ELLAM will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and
              distribute any comments you submit for any purpose whatsoever, without restriction and without compensating you in any way. ELLAM is and shall be under
              no obligation (1) to maintain any Comments in confidence; or (2) to pay you any compensation for any Comments; or (3) to respond to any Comments. You
              agree that any comments submitted by you to the Site will not violate this policy or any right of any third party, including copyright, trademark, privacy or
              other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no comments submitted by you to the site will be
              libelous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain
              letters, mass mailings or any form of "spam".
              {"\n"}
              ELLAM does not regularly review posted comments, but does reserve the right (but not the obligation) to monitor and edit or remove any comment submitted
              to the Site. You grant ELLAM the right to use the name that you submit in connection with any of the posted comments. You agree not to use a false email
              address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are and shall remain solely responsible for
              the content of any comments you make and you agree to indemnify ELLAM and its affiliates for all claims resulting from any Comments you submit, we take
              no responsibility and assume no liability for any comments submitted by you or any third party.

              {"\n"}8. Accuracy of Content/ Information of Products on the Web Site - While ELLAM strives to provide accurate product and pricing information, typographical
              errors may occur. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information,
              ELLAM shall have the right, on our sole discretion, to modify the price of the products, or information of the products or to refuse or cancel any orders placed
              for that product, unless the product has already been dispatched. 
                </Text>
              </View>

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Disclaimers</Text>
              </View>

              <View>
                <Text>
              1. THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.

              {"\n"}2. DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN
              PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED
              ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION
              POSTED IS CORRECT IN EVERY CASE.
              {"\n"}
              3. WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.

              {"\n"}4. YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH
              SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU
              ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR
              WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY
              INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD
              PARTY.
              {"\n"}
              5. ELLAM DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO
              PRODUCTS AND SERVICES OFFERED BY THE MERCHANT
              {"\n"}
              6. WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED,
              THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES,
              REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS,
              EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED
              HEREIN.
              {"\n"}
              7. WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON
              THE PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, PARTNERS, AUTHORS OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
              DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.
                  </Text>
              </View>

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Intellectual property</Text>
              </View>

              <View>
                  <Text>
              1. We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through
              multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout
              format, Order placing process flow and any content thereof.
              {"\n"}
              2. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations,
              photographs, video or audio sequences or any graphics separately from any accompanying text.
              {"\n"}
              3. You must not use any part of the materials on the Platform for commercial purposes without obtaining a license to do so from us or our licensors.

              {"\n"}4. If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to use the Platform will cease immediately and you
              must, at our option, return or destroy any copies of the materials you have made.
                  </Text>
              </View>

              <View>
                <View style={VIEW_MARGIN}>
                    <Text style={{fontWeight: "bold"}}>Treatment of information provided by you</Text>
                </View>

                <View>
                  <Text>
                We process information provided by you to us in accordance with our Privacy Policy. 
                  </Text>
                </View>
              </View>

              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Governing law and dispute resolution</Text>
              </View>

              <View>
                <Text>
              These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or
              relating to this Platform, shall be subject to the jurisdiction of the courts at Kerala, India.
                  </Text>
              </View>
            
              <View style={VIEW_MARGIN}>
                  <Text style={{fontWeight: "bold"}}>Contact Us</Text>
              </View>
              
              <View>
                <Text>
              Please contact us at info@ellam.in for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Platform.
              {"\n"}{"\n"}Grievance Officer/Nodal Officer
              {"\n"}In accordance with (1) Information Technology Act, 2000 and rules made there under, and (2) Consumer Protection (E-Commerce) Rules 2020l the name and
              contact details of the Grievance Officer/Nodal Officeris provided below:
              {"\n"}{"\n"}
              Ms.Neethu Rajasekharan
              {"\n"}
              Anantham Online Pvt Ltd
              {"\n"}
              Phone: +91 - 8086186688
              {"\n"}
              Email: info@ellam.in
              {"\n"}
              Time: Monday – Friday (9:00 – 18:00)
                  </Text>
              </View>

            </View>
          </View>
                  
    </Screen>
)
})


const VIEW_MARGIN: ViewStyle = {
  marginTop: 25, 
  marginBottom: 15
}