import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { Button, Text, Screen, TextField, Icon } from "../../components"
import { TextStyle, View, ViewStyle, Platform} from "react-native"
import { Picker } from "@react-native-picker/picker"
import Checkbox from 'expo-checkbox';
import { color } from "../../theme"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { useStores } from "../../models"
import moment from "moment";
import { ServiceHeader } from "../../components/service-header/service.header";
import Toast from 'react-native-simple-toast';

var deliveryPrefernce = []
 


export const CartScreen: Component = observer(function CartScreen() {

    const { userStore, cartStore, selectedInventoryStore, domainStore } = useStores()

    const navigation = useNavigation()

    const [pickerValue, setPickerValue] = useState('')
    const [isChecked, setChecked] = useState(false);

   

    useEffect(()=>{
 
        // set default local value pick up
        setPickerValue('Pick Up')
        // set default store value pick up/SelfCollection
        cartStore.updateDeliveryType("SelfCollection")

        // set default store value cartitem pick up/SelfCollection
        // cartStore.updateInventoryItemsDeliveryType("NoDelivery")
        cartStore.updateInventoryItemsDeliveryTypeIfApplicable('SelfCollection')

        // check for delivery options
        deliveryOptionCheck()
        cleanupCart()
        // cartStore.resetInventoryItems()
    }, [cartStore.inventoryItems, userStore.isSignIn])


    useEffect(()=>{
        domainStore.createCartInventory(cartStore.businessTypeId)

        if(domainStore.cartBusinessName == 'Temple'){
            deliveryPrefernce = [
                {"name":'Pick Up', "value":'SelfCollection'},
                {"name":'Deliver', "value":'RequireDelivery'},
                {"name":'Distribute', "value":'NoDelivery'}
           ]
        } else if (domainStore.cartBusinessName == 'Ellam Stores') {
            deliveryPrefernce = [
                {"name":'Pick Up', "value":'SelfCollection'},
                {"name":'Deliver', "value":'RequireDelivery'},
           ]
        } else {
            deliveryPrefernce = []
        }
 
        deliveryOptionCheck()
    }, [])
  

    const onDeliverySelect = (deliveryOption, index) => {
        cartStore.updateDeliveryType(deliveryPrefernce[index]['value'])
        cartStore.updateInventoryItemsDeliveryTypeIfApplicable(deliveryPrefernce[index]['value'])
    }

    const serviceInfoNavigation = () => {
        selectedInventoryStore.updateId(cartStore.businessId)
        navigation.navigate('service_info')
    }

    const deliveryOptionCheck = () => {
  
        if(cartStore.getIsDelivery() == false){
            setPickerValue('')
            cartStore.updateDeliveryType("NoDelivery")
            cartStore.updateInventoryItemsDeliveryTypeIfApplicable('NoDelivery')
        }

    }

    const removeInventoryItems = (index) => {
        cartStore.removeInventoryItems(index,'index')
        deliveryOptionCheck()
    }

    const orderSummaryNavigation = () => {
        if (cartStore.businessId == 105) {
            if (isChecked){
                navigation.navigate("order_summary")
            } else {
                if(Platform.OS != 'web'){
                    Toast.show('Please read and accept the instructions.', Toast.LONG);
                }
            }
        } else {
            navigation.navigate("order_summary")
        }
    }

    const cleanupCart = () => {
        if(cartStore.totalItems>0){
          let today = moment().format('YYYY-MM-DD')
          if(today != cartStore.addToCartDate){
            cartStore.resetInventoryItems()
          }
        }
    }

    const waitForSignIn = () => {
        userStore.updateSharedInfo('navBackToCart')
        if(userStore.isWebUrl){
            navigation.navigate("account_from_service")
        } else {
            navigation.navigate("home", {screen: "account"})
        }
    }


    return (
        <Screen preset="fixed" headerTitle="Your Cart" showheaderTitle={true} showBackButton={true}>
            { cartStore.totalItems > 0 ?

                    <View style={{height:"100%"}}>
                        <ServiceHeader 
                            title={cartStore.serviceName} 
                            subTitle={cartStore.serviceLocation} 
                            imageUrl={cartStore.serviceIconUri}
                            onCardPress={serviceInfoNavigation}>    
                        </ServiceHeader>

                    <ScrollView
                        style={Platform.OS === 'web' ? { height: 'calc(100vh - 240px)' } : { flex: 1 }}
                        contentContainerStyle={{ flexGrow: 1 }}
                        horizontal={false}>
                            <View>
                                {cartStore.inventoryItems.map((prop, key) => {
                                    return (
                                        <View key={key}>
                                            {cartItemLayout(prop, key)}
                                        </View>
                                    );
                                })}
                            </View>

                            <View style={{paddingTop: 5, paddingHorizontal: 6}}>
                                <TextField multiline={false}  defaultValue={cartStore.note} placeholder="Any additional notes? Please enter here" 
                                                getText={text => cartStore.updateNote(text)}>
                                </TextField>
                            </View>
                            
                            <View>
                                { cartStore.getIsDelivery() && deliveryPrefernce.length ? deliverySelectionLayout() : null }
                            </View>

                            
                            <View>
                                {billDetailLayout()}
                            </View>
                            <View>
                                {userStore.isSignIn && cartStore.businessId == 105 ? agrementLayout() : null}
                            </View>

                        </ScrollView>
                                            
                        <View style={{marginHorizontal: 15, marginBottom:10}}>
                            
                            { cartStore.validateCartItems().status == "error" ?
                                <Text preset="error" style={{textAlign: 'center', margin: 10}}>{cartStore.validateCartItems().msg}</Text> : null
                            }

                            { userStore.isSignIn ? cartStore.totalItems == 0 ? null :
                                    <Button
                                        style={!isChecked && cartStore.businessId == 105 ? {backgroundColor: '#C6C6C6'} : null}
                                        preset= {cartStore.validateCartItems().status == "success" ? "primary" : "primary_disabled"}
                                        text='CHECKOUT'
                                        onPress={() => {cartStore.validateCartItems().status == "success" ? orderSummaryNavigation(): null}}
                                    />
                                :
                                    <Button
                                        preset="primary"
                                        text='SIGN-IN / REGISTER TO CONTINUE'
                                        onPress={() => waitForSignIn()}
                                    />
                            }
                        </View>
                    </View> 
                :
                    <View style={{alignItems:'center', justifyContent:'center', height:'100%'}}>
                        <Icon icon="empty_cart" style={{width: 60, height: 60}}></Icon>
                        <Text style={{color:color.dim, marginTop: 10}}>
                            Your cart is empty!
                        </Text>
                    </View>
            }
        </Screen>
    )

    function cartItemLayout(prop, index) {
        return (

            <View style={OUTER_CONTAINER}>
                
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={{marginBottom: 2, fontSize: 15, fontWeight: 'bold'}}>{prop.name}</Text>
                    
                    <View>{prop.isDiscountApplied ? 
                        <View style={{borderRadius: 25, width: 120, alignItems: "center", padding: 3,
                            backgroundColor: color.green}}>
                            <Text style={{color: color.white, fontWeight: 'bold', fontSize: 10}}>
                                Discount Applied
                            </Text>
                        </View>
                        : null} 
                    </View>
                </View>

                <View style={CONTAINER}>
                    <View style={{marginRight: 20, flex: 1}}>
                        { prop.customFields && prop.customFields.length ?
                            <View>
                                { prop.customFields && prop.customFields.map((prop1, key1) => {
                                    return (
                                        <View key={key1}>
                                            { prop1['fieldName'] != 'Discount' ?
                                                <Text style={[TEXT_DEFAULT, {marginBottom: 1}]}>{prop1['fieldName']}: 
                                                    &nbsp;{prop1['fieldValue']}&nbsp;
                                                    {prop1['fieldName'] == 'Duration' ? prop1['fieldValue'] > 1 ? 'Hours' : 'Hour' : null}
                                                </Text> 
                                                : null 
                                            }
                                        </View>
                                    );
                                })}
                            </View> : null 
                        }
                        <Text style={TEXT_DEFAULT}>{domainStore.cartInventoryName[5]} Booking Date : {prop.bookingDate.toString()}</Text>
                        {
                            prop.isDellveryFeeAplicable && domainStore.cartBusinessName == "Temple" ?
                            <View>
                                <Text style={{color: color.primary}}>( This pooja have Prasadam delivery )    </Text>
                            </View>
                            : null
                        }
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Text style={TEXT_TITLE_BLUE}>₹ {prop.amount}</Text>
                        <TouchableOpacity onPress={() => removeInventoryItems(index)} style={{width:40,height:40,justifyContent:'center',alignItems:'center',}}>
                            <Icon icon='trash'></Icon>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    function billDetailLayout() {
        return (
            <View style={{...BILL_LAYOUT_CONTAINER}}>
                <Text style={{fontWeight: 'bold', marginTop: 5}}>Bill Details</Text>
                <View style={BILL_LAYOUT_ROW}>
                    <Text style={TEXT_DEFAULT}>Items</Text>
                <Text style={TEXT_DEFAULT}>{cartStore.totalItems}</Text>
                </View>
                <View style={BILL_LAYOUT_ROW}>
                    <Text style={TEXT_DEFAULT}>Items Total</Text>
                    <Text style={TEXT_DEFAULT}>{cartStore.itemsTotal.toFixed(2)}</Text>
                </View>
                
                <View style={{borderColor: color.line, borderWidth: 1, width: '100%', marginVertical: 5, borderStyle: 'dashed', borderRadius: 1}} />

                <View style={BILL_LAYOUT_ROW}>
                    <Text style={{fontWeight: 'bold'}}>Total</Text>
                    <Text style={TEXT_TITLE_BLUE}>₹ {cartStore.itemsTotal.toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    function agrementLayout() {
        return (
            <View style={{...BILL_LAYOUT_CONTAINER}}>
                <Text style={{fontWeight: 'bold', marginTop: 5}}>Instructions</Text>
                <View style={BILL_LAYOUT_ROW}>
                    <Checkbox
                        value={isChecked}
                        onValueChange={setChecked}
                        color={color.ananthamOrange}
                    />
                    <Text style={TEXT_AGREMENT}>{`1. Pre booked vehicles please enter the premises only via Kanjoor or Sreemoola Nagaram \n\n2. Parking Operator shall not be responsible for wrong details provided by the Devotees During Booking.\n\n3. Parking reservation fee shall not be refundable or Transferable.`}</Text>
                </View>
            </View>
        )
    }

    function deliverySelectionLayout() {
        return (
            <View style={{...BILL_LAYOUT_CONTAINER}}>
                <Text style={{fontWeight: 'bold', marginTop: 5}}>Delivery Preference</Text>

                <View style={BILL_LAYOUT_ROW}>
                    <Text style={TEXT_DEFAULT}>Please choose your preference</Text>
                </View>

                <View style={{width:"100%"}}>
                    <View style={{paddingHorizontal: 0}}>
                        <View style={{ marginBottom: 10, height: 50, width: "100%", borderWidth:1, borderRadius:5, borderColor:color.nuetralBlue, paddingHorizontal: 10, justifyContent: 'center'}}>
                            <Picker
                                selectedValue={pickerValue}
                                style={{color:pickerValue==''?color.grey:color.black, borderWidth:0 }}
                                onValueChange={(itemValue, itemIndex) => [ setPickerValue(itemValue), onDeliverySelect(itemValue,itemIndex)]}
                                >
                                {
                                deliveryPrefernce.map((starValue, starKey) => {
                                    return (
                                        <Picker.Item key={starKey} label={starValue.name} value={starValue.name} />
                                        );
                                    })
                                }
                            </Picker>
                        </View>
                    </View>
                </View>

            </View>
        )
    }

})




const OUTER_CONTAINER: ViewStyle = {
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderColor: color.line,
    borderWidth: 1,
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 5,
}

const CONTAINER: ViewStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    flexWrap: 'wrap'
}

const BILL_LAYOUT_CONTAINER: ViewStyle = {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderColor: color.line,
    borderWidth: 1,
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 5,
}

const BILL_LAYOUT_ROW: ViewStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginVertical: 5,
}

const TEXT_DEFAULT: TextStyle = {
    fontSize: 15,
    color: color.dim,    
}

const TEXT_AGREMENT: TextStyle = {
    fontSize: 14,
    color: color.ananthamOrange, 
    fontWeight: 'bold',
    marginRight: 30

}

const TEXT_TITLE_BLUE: TextStyle = {
    fontWeight: 'bold',
    color: color.primary,
}

