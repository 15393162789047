import { ApisauceInstance, create, ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import { app_variables } from "../../models/app-model/application_model"
import { url } from "./api-list"
import { load, save } from "../../utils/storage";

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        'access-control-allow-origin': '*',
        Accept: "application/json",
      },
    })
  }

  async getValidToken(){

    let accessToken = await load("accessToken")

    if(accessToken){
      var jwtDecode = require('jwt-decode');
      var decoded = jwtDecode(accessToken);
      var now =  new Date().getTime()
      var timeout_msec = 60000 // 1 min
      var exp_time = (decoded['exp']*1000) - now // time diff b/w exp time and now in milliSec.
      if(exp_time < timeout_msec){
        var response = await this.getRefreshToken()
        if(response['kind'] == 'ok'){
          await save("accessToken", response['data']['value'])
          return response['data']['value']
        }
      }
      else{
        return accessToken
      }
    }

    return accessToken
  }

  async getRefreshToken(){

    const apiService = await this.setHeaderWOAuth()
    const refreshToken = await load("refreshToken")
    const deviceId = await load("deviceId")
    let param = {
      "refreshToken" : refreshToken,
      "deviceId": deviceId
    }
    const response: ApiResponse<any> = await apiService.post(url.token,param)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return { kind: "ok" , data:response.data}
  }

  async setHeader() {

    // construct the apisauce instance
    const accessToken = await this.getValidToken()

      return this.apisauce = create({
        baseURL: this.config.url,
        timeout: this.config.timeout,
        headers: {
          'access-control-allow-origin': '*',
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
  }

  setHeaderWOAuth() {
    // construct the apisauce instance
    return this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        'access-control-allow-origin': '*',
        Accept: "application/json"
      },
    })
  }

  async setMultipartHeader() {
    // construct the apisauce instance
    // const accessToken = await load("accessToken")
    const accessToken = await this.getValidToken()

    return this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers:{  
        'access-control-allow-origin': '*',
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + accessToken
        }
    })
  }


  requests(url) {
    return this.apisauce = create({
      baseURL: url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        // 'access-control-allow-origin': '*'
      },
    })
  }

  generateUrl(apiName): string {
    let urlPath = url[apiName].replace('{businessId}',app_variables.businessId)
    urlPath = urlPath.replace('{businessInfoId}',app_variables.businessId)
    return urlPath
  }


}