import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, StyleSheet, FlatList } from "react-native"
import { color, styles as mainStyles, spacing } from "../../theme"
import { DayPickerProps } from "./day-picker-props";

 
// const initialState = {
//     Monday: false,
//     Tuesday: false,
//     Wednesday: false,
//     Thursday: false,
//     Friday: false,
//     Saturday: false,
//     Sunday: false
// }



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const DayPicker: Component<DayPickerProps> = props => {
  const {
    type,
    days,
    getDays,
    title,
    titleStyle
  } = props

    const [value, setValue] = React.useState(days);
    const [allValue, setAllValue] = React.useState(false);

    useEffect(()=>{
        setValue(days)
        checkForAllSelected(days)
    }, [days])

    
    const changeCurrentState = (day, index) => {
        
        const d = JSON.parse(JSON.stringify(value))
        d[day] = !d[day]
        setValue(d) 
        checkForAllSelected(d)
        getDays(d)

    }

    const selectAll = (day) => {

        setAllValue(!allValue)
        
        const d = JSON.parse(JSON.stringify(value))
        Object.keys(d).map((x)=>{
            d[x] = !allValue
        })

        setValue(d)
        getDays(d)

    }





    const renderOptionSmall = (day, index) => (
        
        <TouchableOpacity key={index} onPress={() => {changeCurrentState(day, index)}}>
            <View   style={[value[day] ? styles.circle_shape_view_selected : styles.circle_shape_view]}>
                <Text style={[value[day]? styles.selected_text_style : styles.text_style]}>
                    {day[0].toUpperCase()}
                </Text>
            </View>
        </TouchableOpacity>
      );


    const renderOptionLarge = (day, index) => (
        
        <TouchableOpacity key={index} onPress={() => {changeCurrentState(day, index)}}>
            <View   style={[value[day] ? styles.all_view_selected : styles.all_view]}>
                <Text style={[value[day]? styles.selected_text_style : styles.text_style]}>
                    {day[0].toUpperCase()}{day.slice(1)}
                </Text>
            </View>
        </TouchableOpacity>
    );


    const checkForAllSelected = (days) => {
        var isAllTrue = false
        const daysArray = Object.keys(days)
        for(var i=0; i < daysArray.length; i++){
            if(days[daysArray[i]] == true){
                isAllTrue = true
            } else{
                isAllTrue = false
                break
            }
        }
        if(isAllTrue){
            setAllValue(true)
        } else {
            setAllValue(false)
        }
    }

    

    return (
                

        <View style={styles.root}>

            {/* {days} */}
            { type == 'small' ?

                <View>
                    <View style={styles.title_container}>
                        <Text style={{...titleStyle, ...mainStyles.label_lg}}>{title}</Text>
                        <TouchableOpacity style={[allValue ? styles.all_view_selected : styles.all_view]} onPress={selectAll}>
                            <Text style={[allValue ? styles.selected_text_style : styles.text_style]}>All</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.container}>
                        { 
                            Object.keys(days).map((day, index)=> renderOptionSmall(day, index))
                        }
                    </View>
                </View> :

                <View style={styles.large_root}>
                    <View style={styles.title_container}>
                        <TouchableOpacity style={[allValue ? styles.all_view_selected : styles.all_view]} onPress={selectAll}>
                            <Text style={[allValue ? styles.selected_text_style : styles.text_style]}>All Days</Text>
                        </TouchableOpacity>
                    </View>
                    
                    <Text style={{color: color.dim, marginBottom: 30}}>or</Text>

                    <View style={styles.container_large}>
                        { 
                            Object.keys(days).map((day, index)=> renderOptionLarge(day, index))
                        }
                    </View>
                </View> 

            }

        </View>

    )
}



const styles = StyleSheet.create({

    root: {
        flexDirection: 'column',
        padding: spacing[2]
    },

    large_root: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    title_container:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 5
    },

    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },


    container_large: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
      },
  
    circle_shape_view: {
      width: 35,
      height: 35,
      borderRadius: 35/2,
      backgroundColor: color.background,
      justifyContent: 'center',
      alignItems: 'center',
    },

    circle_shape_view_selected: {
        width: 35,
        height: 35,
        borderRadius: 35/2,
        backgroundColor: color.primary,
        color: color.white,
        justifyContent: 'center',
        alignItems: 'center',
    },


    selected_text_style: {
          color: color.white
    },

    text_style: {
        color: color.black
    },


    all_view:{
        width: 100,
        height: 35,
        borderRadius: 20,
        backgroundColor: color.background,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15
    },

    all_view_selected:{
        width: 100,
        height: 35,
        borderRadius: 20,
        backgroundColor: color.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15
    },
  
  
  });







