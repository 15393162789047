import React, { FunctionComponent as Component, useEffect, useState } from "react"
import { View, ViewStyle, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Icon } from "../../components"
import { color, styles } from "../../theme"
import { useStores } from "../../models/root-store/root-store-context";
import { ApiSignup, ApiSignin } from "../../services/api"


export const RegisterScreen: Component = observer(function RegisterScreen(props) {

  const { userStore } = useStores()
  const [errorMsgFname, setErrorMsgFname] = useState()
  const [errorMsgLname, setErrorMsgLname] = useState()
  const [errorMsgEmail, setErrorMsgEmail] = useState()
  const [errorMsgPhone, setErrorMsgPhone] = useState()

  // navigations
  const navigation = useNavigation()

  const [isEmailDisabled, disableEmail] = useState(true)

  useEffect(()=>{
    if(userStore.email && userStore.email.length){
      disableEmail(true)
    } else if(userStore.phoneNumber && userStore.phoneNumber.length){
      disableEmail(false)
    }
  }, [])
  
  const verifyInputs = () => {
    if(userStore.firstName){
      setErrorMsgFname('')
      if(userStore.lastName){
        setErrorMsgLname('')
        if(userStore.email && userStore.phoneNumber){
          isEmailDisabled ? phoneNumberStatusApi() : emailStatusAPi() 
        }
        else if(userStore.email){ setErrorMsgPhone('Invalid Phone Number') }
        else if(userStore.phoneNumber){ setErrorMsgEmail('Invalid Email Id') }
      } else{ setErrorMsgLname('Enter Last Name') }
    } else{ setErrorMsgFname('Enter First Name') }
  }


  const validateEmailAddress = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email.trim()).toLowerCase());
  }

  const validatePhoneNumber = (mobileNumber) => {
    const re = /^[1-9]{1}[0-9]{3,16}$/
    return re.test(String(mobileNumber.trim()).toLowerCase());
  }



  const responseWork = (res, type) => {
    if(res['kind'] == "ok"){
      let data = res['data']
      if(data["isUserExists"] && type == "email"){
        setErrorMsgEmail('Email Id already exist')
      } else if(data["isUserExists"] && type == "phone"){
        setErrorMsgPhone('Phone number already exist')
      } else {
        generateOtpApi()
      }
    } else {
      setErrorMsgEmail('Invalid Email Id')
    }
  }


  const emailStatusAPi = () => {
    if(validateEmailAddress(userStore.email)){
      const api = new ApiSignup ()
      api.emailStatus(userStore.email).then(res => { 
        responseWork(res, 'email')
      })
    } else {
      setErrorMsgEmail("Invalid Email")
    }
  }

  const phoneNumberStatusApi = () => {
    if(validatePhoneNumber(userStore.phoneNumber)){
      const api = new ApiSignin ()
      api.phoneNumberStatus(userStore.phoneNumber).then(res => { 
        responseWork(res, 'phone')
      })
    } else {
      setErrorMsgPhone('Invalid Phone Number')
    }
  }

  const generateOtpApi = () => {
    const api = new ApiSignup ()
    api.createOtp(userStore.phoneNumber, userStore.email, isEmailDisabled).then(res => { 
      if(res['kind'] == "ok"){
        let data = res['data']
        if(data["otpReferenceId"]){
          userStore.updateOtpReferenceId(data["otpReferenceId"])
          userStore.updateNewUser(true)
          navigation.navigate("logged_out_path", {screen: "verify_otp"})
        }
      }
    })
  }


  return (
    <Screen preset="scroll" headerTitle="SignUp" showheaderTitle={true} showBackButton={true}>
      <View style={CONTAINER}>

          <View style={{ alignItems: 'center', marginBottom: 40}}>

            <View style={{alignItems:'center', marginVertical: 10, backgroundColor: color.black, borderRadius: 20, padding: 15, width: 80}}>
              <Icon icon={'ellam_icon'} style={{width: 50, height: 50}}></Icon>
            </View>

            <Text style={styles.title_primary}> Ellam </Text>

          </View>
          
          <View style={{marginBottom: 40}}>

              <TextField errorMessage={errorMsgFname} placeholder='First Name' icon='user' 
                        defaultValue={userStore.firstName} getText={text => userStore.updateFirstName(text)} 
                        rootStyle={{marginBottom: Platform.OS == "web" ?  10: null}}></TextField>
              <TextField errorMessage={errorMsgLname} placeholder='Last Name' icon='user' 
                        defaultValue={userStore.lastName} getText={text => userStore.updateLastName(text)} 
                        rootStyle={{marginBottom: Platform.OS == "web" ?  10: null}}></TextField>
              <TextField errorMessage={errorMsgEmail} placeholder='Email Address' icon='mail' 
                        defaultValue={userStore.email} disabled={isEmailDisabled ? true : false} 
                        getText={text => userStore.updateEmail(text)}
                        rootStyle={{marginBottom: Platform.OS == "web" ?  10: null}}></TextField>
              <TextField errorMessage={errorMsgPhone} placeholder='Phone Number' keyboardType='numeric' icon='phone' 
                        defaultValue={userStore.phoneNumber} disabled={isEmailDisabled ? false : true} 
                        getText={text => userStore.updatePhoneNumber(text)}
                        rootStyle={{marginBottom: Platform.OS == "web" ?  10: null}}></TextField>
          </View>


            <Text style={{marginBottom: 40, ...styles.title_secondary}}>
              We will send you One Time Passsword (OTP) to your Phone Number/ Email Address
            </Text>

              <View style={{marginBottom: 40, alignSelf: "center"}}>
                <Button
                  style={{minWidth: "100%"}}
                  text="CONTINUE"
                  onPress={verifyInputs}
                />
              </View>
              
          </View>
    </Screen>

  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: color.white,
  padding: 20,
  marginTop: 0
}


