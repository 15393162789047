import { types } from "mobx-state-tree"

var businessNames = {
    "2": "Ellam Stores",
    "4": "Temple",
    "6": "Parking"
}
  
var inventoryNames = {
    "2": ["Orders", "Order", "Inventory", "Item", "Items", "Order"],
    "4": ["Offerings", "Offering", "Offerings", "Offering", "Offerings", "Pooja"],
    "6": ["Orders", "Order", "Inventory", "Item", "Items", "Order"]
}  

var domainNames = {
    "2": ["open-kitchens", "open-kitchen"],
    "4": ["worships", "worship"],
    "6": ["parking", "parking"]
}

var domainId = {
    "open-kitchen": 2,
    "worship": 4,
    "parking": 6
}

var imagePrefix = {
    "2": "open_kitchen",
    "4": "prarthana",
    "6": "parking"
}
  

export const DomainModel = types
.model()
.props({
    businessTypeId: types.maybeNull(types.optional(types.number, 0)),
    businessName: types.maybeNull(types.optional(types.string, "")),
    inventoryName: types.maybeNull(types.optional(types.array(types.string), [])),
    domainName: types.maybeNull(types.optional(types.array(types.string), [])),
    imagePrefix: types.maybeNull(types.optional(types.string, "")),
    cartInventoryName: types.maybeNull(types.optional(types.array(types.string), [])),
    cartBusinessName: types.maybeNull(types.optional(types.string, "")),
})
.actions(self=>({
    create(businessTypeId){
        self.businessTypeId = businessTypeId
        self.businessName = businessNames[businessTypeId]
        self.inventoryName = inventoryNames[businessTypeId]
        self.domainName = domainNames[businessTypeId]
        self.imagePrefix = imagePrefix[businessTypeId]
    },
    createCartInventory(businessTypeId){
        self.cartInventoryName = inventoryNames[businessTypeId]
        self.cartBusinessName = businessNames[businessTypeId]
    },
    createFromDomainName(name){
        var businessTypeId = domainId[name]
        self.businessTypeId = businessTypeId
        self.businessName = businessNames[businessTypeId]
        self.inventoryName = inventoryNames[businessTypeId]
        self.domainName = domainNames[businessTypeId]
        self.imagePrefix = imagePrefix[businessTypeId]
    },
    updateBusinessName(businessName){
        self.businessName = businessName
    },
    updateInventoryName(inventoryName){
        self.inventoryName = inventoryName
    },
    getDomainName(id){
        return domainNames[id][1]
    }
}))

